
//=============================================================================
// Pantalla Listado de Procesos
//=============================================================================

// Genericos
import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// API
import APICALL from './APICALL';


export default function Pprocess() {

  const navigate = useNavigate();
  const [FetchedData, setFetchedData] = useState(''); 
  const [FilterQuery, setFilterQuery] = useState(''); 
  const [SelectedReply, setSelectedReply] = useState('');
  const [Replies, setReplies] = useState([]);
  const [FilteredReplies, setFilteredReplies] = useState([]); 
  const [Arch, setArch] = useState(false);
  const [Check, setCheck] = useState(false);
  const [Warning, setWarning] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [ShowArchived, setShowArchived] = useState(false);


  // Recuperacion y listado de procesos
  async function FetchData () {
    const data = await APICALL({
      apicall: 'PD_fetch',
      code: sessionStorage.process,
      U_emp: sessionStorage.Grupo
    });
    if (data.response===''){
      Correccion();
    }
    sessionStorage.setItem('perfil', data.GPTreply);
    setFetchedData(data);
  };
  async function List(state) {
    setLoading(true);
    const process = await APICALL({
      apicall: 'PD_list',
      R_name: sessionStorage.token,
      U_emp: sessionStorage.Grupo
    });
  
    if (process && Array.isArray(process)) {
      let filteredProcesses = process.filter(item => 
        item[1].toUpperCase() === sessionStorage.Grupo.toUpperCase() &&
        (state ? item.includes('archived: true') : !item.includes('archived: true'))
      );
  
      const transformedReplies = filteredProcesses.map(item => ({
        reply: item[2].replace(/_/g, ' | '),
        value: item[0]
      })).reverse();
      
      setReplies(transformedReplies);
      setFilteredReplies(transformedReplies);
      setLoading(false);
    } else {
      setReplies([]); 
      setLoading(false);
    }
  };
  async function Handlecheck (value, reply) {
    setSelectedReply(value);
    let transformedback = value.replace(/ \| /g, '_');
    sessionStorage.removeItem('process');
    sessionStorage.removeItem('name');
    sessionStorage.setItem('process', transformedback);
    sessionStorage.setItem('name', reply);
    await setFetchedData(FetchData());
    setCheck(true);
  };
  async function Correccion() {
    setLoading(true);
    await APICALL({
      apicall: 'FX_00',
      code: sessionStorage.process,
      U_emp: sessionStorage.Grupo
    });
    await setFetchedData(FetchData());
    FetchData()
    setTimeout(() => {
      setLoading(false);
    }, 2 * 1000);
  };
  

  // Notas de version
  const VersionNotesLoader = () => {
    const notesFile = require('./docs/Notas.json'); // Carga el archivo JSON
    const notesText = notesFile.content; // Asegúrate de que el JSON tiene la clave "content"
    
    const parseNotes = (notesText) => {
      const lines = notesText.split('\n').filter(line => line.trim() !== '');
      const parsedNotes = [];
      let currentNote = null;
  
      lines.forEach(line => {
        if (line.startsWith('* ')) {
          if (currentNote) {
            parsedNotes.push(currentNote);
          }
          currentNote = {
            title: line.slice(2),
            subNotes: []
          };
        } else if (line.startsWith('\t* ') || line.startsWith('  * ')) {
          if (currentNote) {
            currentNote.subNotes.push(line.trim().slice(2));
          }
        }
      });
  
      if (currentNote) {
        parsedNotes.push(currentNote);
      }
  
      return parsedNotes;
    };
  
    const parsedNotes = parseNotes(notesText);
  
    return (
      <div style={{ overflow: 'scroll', height: '70vh' }} className='popup-content'>
        <ul>
          {parsedNotes.map((note, index) => (
            <li key={index}>
              {note.title}
              {note.subNotes.length > 0 && (
                <ul>
                  {note.subNotes.map((subNote, subIndex) => (
                    <li key={subIndex}>{subNote}</li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };
  

  // Archivar y eliminar procesos
  async function Archive (value) {
    setLoading(true);
    await APICALL({
      apicall: 'PD_arch',
      code: sessionStorage.process,
      R_name: sessionStorage.token, 
      U_emp: sessionStorage.Grupo,
      archived: value
    });
    List(!value);
    setCheck(false);
    setLoading(false);
    setArch(false);
  };
  async function DropProcess () {
    setLoading(true);
    await APICALL({
      apicall: 'PD_drop',
      code: sessionStorage.process,
      R_name: FetchedData.R_name, 
      U_emp: sessionStorage.Grupo
    });
    List();
    setCheck(false);
    setLoading(false);
    setWarning(false);
  };


  // Filtrado de procesos
  const HandleFilterChange = (e) => {
    const query = e.target.value.toLowerCase();
    setFilterQuery(query);
    
    const filtered = Replies.filter(reply => 
      reply.reply.toLowerCase().includes(query)
    );
  
    setFilteredReplies(filtered);
  };
  const ToggleArchived = () => {
    setShowArchived(!ShowArchived);
    List(!ShowArchived); 
  };


  // Navegacion
  const Goto02 = () => { navigate('/CrearProceso') };
  const Goto05 = () => { navigate('/P_Notas') };
  const Goto07 = () => { navigate('/P_Candidatos') };


  useEffect(() => {
    const fetchData = async () => {
      sessionStorage.removeItem('process');
      setLoading(true);
      
      try {
        const response = await APICALL({
          apicall: "PD_listEMP"
        });
        
        if (response && response.empresas) {
          sessionStorage.setItem('gruposlista', JSON.stringify(response.empresas));
        }
      } catch (error) {
        console.error("Error al obtener la lista de empresas:", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        },500);
      }
      List();
    };
    fetchData();
  }, []); 
  const DataItems = [
    { label: 'NOMBRE DEL CARGO', value: FetchedData.Charge_title ? FetchedData.Charge_title.toUpperCase() : ' - - - ' }, 
    { label: 'EMPRESA', value: FetchedData.E_name ? FetchedData.E_name.toUpperCase() : ' - - - ' }, 
    { label: 'HEAD HUNTER', value: FetchedData.R_name ? FetchedData.R_name.toUpperCase() : ' - - - ' }, 
    { label: 'INTERLOCUTOR', value: FetchedData.I_name ? FetchedData.I_name.toUpperCase() : ' - - - ' },
    { label: 'FECHA LIMITE', value: FetchedData.Timewith ? FetchedData.Timewith.toUpperCase() + ' DIAS' : ' - - - ' }
  ];
  
 if (sessionStorage.rol.toLowerCase() === 'admin'){
    DataItems.push({ label: 'ID', value: Array.isArray(FetchedData.JobId) ? FetchedData.JobId.join(' || ') : (FetchedData.JobId || ' - - - ') });
 }

  //############################################################################################################
  //############################################################################################################  

  return (

    <div className='BackGround'>	

      {/* Pop-up Eliminar Proceso */}
      <div className={Warning ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setWarning(false)} >
        </div>
        <div className={Warning ? 'warningbox' : 'hidden'}>
          <h1>¿Estás Seguro que deseas Eliminar este Proceso?</h1>
          <div style={{display:'flex', flexDirection:'column', gap:'0.5vh', fontSize:'1.2rem'}}>
            {DataItems.map((item, index) => (
              <div key={index}>
                <strong>{item.label}:</strong> {item.value}
              </div>
            ))}
          </div>
          <div style={{marginLeft:'2vh', marginRight:'2vh', fontSize:'1.2rem'}}>
            Esta acción es PERMANENTE y NO SE PUEDE DESHACER. 
            Al eliminar este proceso, se perderán todos los datos e información asociada, 
            y no habrá forma de recuperarlos.
            Asegúrate de haber realizado copias de cualquier documento o informacion relevante 
            si estos son necesarios, ya que el proceso es IRREVERSIBLE
          </div>
          <div style={{display:'flex', padding:'1vh', gap:'1vh', width:'-webkit-fill-available'}}>
            <button className='redb' onClick={DropProcess}>si, quiero Eliminar este proceso</button>
            <button onClick={() => setWarning(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Pop-up Archivar Proceso */}
      <div className={Arch ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setArch(false)} >
        </div>
        <div className={Arch ? 'warningbox' : 'hidden'}>
          <h1> ¿Estás Seguro que deseas {ShowArchived ? 'Desarchivar' : 'Archivar'} este Proceso?</h1>
          <div style={{display:'flex', flexDirection:'column', gap:'0.5vh', fontSize:'1.2rem'}}>
            {DataItems.map((item, index) => (
              <div key={index}>
                <strong>{item.label}:</strong> {item.value}
              </div>
            ))}
          </div>
          <div style={{marginLeft:'2vh', marginRight:'2vh', fontSize:'1.2rem'}}>
          De proceder, este proceso se enviara a la lista de procesos {ShowArchived ? 'activos' : 'archivados'}
          </div>
          <div style={{display:'flex', padding:'1vh', gap:'1vh', width:'-webkit-fill-available'}}>
            <button className='redb' onClick={() => Archive(!ShowArchived)}>si, quiero {ShowArchived ? 'DESARCHIVAR' : 'ARCHIVAR'} este proceso</button>
            <button onClick={() => setArch(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* type header  */}
      <div className='typing-effect-container'>
        <h2 className='typing-effect'>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          Hola, soy Reneé 👋... Indícame si quieres que te ayude en un proceso existente o si quieres crear uno nuevo.
        </h2>
      </div>		

      <div className='MainBody'>

        {/* Lista de procesos */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='ColumnCenter' style={{marginRight:'1vh'}}>
        <h3 className='form-title'>{ShowArchived ? 'Tus Procesos Archivados' : 'Tus Procesos Activos'}</h3>
          <div style={{width:'100%'}}>
            {/* Barra botones */}
            <div className={Loading ? 'hidden' : 'buttonbox3'}>
              <input 
                type='text' 
                placeholder='Buscar' 
                value={FilterQuery}
                style={{margin:'0vh 0vh 0vh 1vh', border:'0.3vh solid var(--main)', borderRadius:'1vh', paddingLeft:'1vh', width:'150%'}}
                onChange={HandleFilterChange}/>
              <button onClick={()=>List(ShowArchived)}>
                Actualizar la lista 
              </button>
              <button onClick={ToggleArchived}
                      style={{margin:'0vh 2.5vh 0vh 0vh'}}
>
                {!ShowArchived ? ' Ver Procesos Archivados' : 'Ver procesos Activos'}
              </button>
            </div>
            {/* Lista de procesos */}
            { Loading === true ? (
              <div>
                <div>
                  <img
                    src={'/favicon.png'}
                    alt='Profile'
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                      position:'stick'
                    }}/>
                  <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                  <div className='loader-container'>
                    <div  className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                  </div>
                </div>
              </div>
  
            ) : (
              <div className='mainselect' style={{padding:'0vh 0vh 5vh 0vh'}}>
                <div style={{ position:'sticky', 
                              backgroundColor:'var(--light)',
                              top: 0, 
                              zIndex: 4, 
                              padding:'1vh'}} >
                  <button 
                          onClick={Goto02}>
                    Crear nuevo proceso
                  </button>
                </div>
                {FilteredReplies.length > 0 ? 
                  FilteredReplies
                  .map((item, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'baseline', paddingLeft:'1vh', paddingRight:'1vh' }}>
                      <button 
                        onClick={() => Handlecheck(item.value, item.reply)} 
                        style={{  marginBottom: '0.5vh', 
                                  marginRight:'0.5vh', 
                                  padding: '1vh' }}
                        className={!ShowArchived ? (item.value === SelectedReply ? 'highlighted2' : 'Item' ): 'SubButoff'}
                      >
                        {item.reply.toUpperCase()}
                      </button>
                      <button 
                        onClick={() => {Handlecheck(item.value, item.reply); setArch(true)}} 
                        className='box-button redb'
                      >
                        <i className='fas fa-archive'></i>
                      </button>
                    </div>
                  )) : 
                  <button className='SubButoff'>
                    Sin procesos Activos
                  </button>
                }
              </div>
            )}
          </div>
        </div>

        {/* Detalles de Proceso */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='ColumnCenter' style={{marginLeft:'1vh'}}>
        <h3 className='form-title MobHide'> {Check ? 'Datos de tu proceso' : '¡Bienvenidos a ' + document.title + ' !'}</h3>
          {Check ? (
            <div style={{width:'100%'}}>
              { Loading === true ? (
                <div>
                  
                </div>
              ) : (
                <div>
                  <div className='buttonbox3'>
                    <button onClick={Goto05}>Continuar Proceso</button>
                    <button className={FetchedData.JobId? '' : 'hidden'} onClick={Goto07}>Continuar a Candidatos</button>
                    <button className='redb' onClick={() => setWarning(true)}>Eliminar Proceso</button>
                  </div>
                  <div className='mainselect' style={{overflow:'hidden', padding:'1vh 1vh 4vh 1vh'}}>
                    <div className={Check ? 'Process-boxes' : ' hidden' }>
                      {DataItems.map((item, index) => (
                        <div key={index} className='data-box'>
                          <strong>{item.label}:</strong> {item.value}
                        </div>
                      ))}
                      <button className={sessionStorage.mail === 'admin'? '' : 'hidden'} onClick={Correccion}>Corregir datos del proceso</button>
                    </div>
                    <div className={Loading ? 'loading' : 'hidden'}>
                    </div>
                  </div> 
                </div>
              )}
            </div>
          ) : (
            <div className ='MobHide' style={{display:'flex', flexDirection: 'column', fontSize:'1.2rem', alignItems:'center', border:'none'}}>
                {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                
                <div className='MobHide'>
                  🍂🍂🍂 Reneé te desea un Feliz Abril 2025 !!! 🍂🍂🍂  <br/>
                  Estos son los nuevos cambios que podrás observar apartir de esta versión:
                </div>
                <div style={{overflow:'hidden', height:'70vh'}} className='popup-content'>
                  <div style={{textAlign:'start'}}>

                    {VersionNotesLoader()}

                    
                  </div>
                </div>
            </div>
          )}
          </div>
      </div>
      
    </div>
  );
}
