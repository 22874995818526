
//=============================================================================
// Pantalla Login
//=============================================================================

// Generico
import './css/App.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// API
import LOGCALL from './LOGCALL';

export default function Login({ setToken }) {
  
  const navigate = useNavigate();

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');

	const [U_name , setU_name]    = useState('');
  const [U_last , setU_last]    = useState('');
  const [U_city , setU_city]    = useState('');
  const [U_mail , setU_mail]    = useState('');
  const [U_phone, setU_phone]   = useState('');
  const [U_emp  , setU_emp]     = useState('');
  const [U_text , setU_text]    = useState('');

  const [Popup1, setPopup1]     = useState(false);
  const [Popup2, setPopup2]     = useState(false);
  const [error, setError]       = useState(''); 
  let count                     = 0;
  

  // Login de usuario
  async function HandleLoggin (e) {
    e.preventDefault();
    setError('');

    const result = await LOGCALL({
      apicall: 'US_LOG',
      username,
      password
    });

    if (result.token) {
      console.log(result);
      sessionStorage.setItem('Grupo', result.empresa);
      sessionStorage.setItem('token', result.token);
      sessionStorage.setItem('mail', username);
      sessionStorage.setItem('rol', result.rol);
      sessionStorage.setItem('environment', 'Production');
      sessionStorage.setItem('test', 'Production');
      window.location.reload();
    } else {
      setError(result.error || ' '); 
    }
  };


  // Funciones de Pop-ups
  async function Handleout (e) {
    e.preventDefault();
    setError('');
    
    setPopup1(false);
    setPopup2(false);
  };
  async function MailContacto (e) {
    e.preventDefault();
    setError('');

    await LOGCALL({
      apicall: 'US_CON',
      U_name,
      U_last,
      U_city,
      U_emp,
      U_mail,
      U_phone,
      U_text
    });
    alert('Gracias por contactarnos, pronto le responderemos !');
  };
  async function MailRecuperar (e) {
    e.preventDefault();

    const result = await LOGCALL({
      apicall: 'US_PASS',
      U_mail : U_mail
    });
    if (result.error){
      alert('Mail no registrado');
    } else {
      alert('Pronto recibiras una clave temporal a tu dirección de correo');
    }
  };


  // Navegación
  const GotoMS = () => {
    if (count === 5){
      navigate('/MS'); 
    } else {
      count = count+1;
    }
	};


  //############################################################################################################
  //############################################################################################################

  return(

    <main className='loggin'>

      {/* Pop-up Mail contacto */}
      <div className={Popup1 ? 'popupBG' : 'hidden'}>
        <div className='outside'  onClick={Handleout} >
        </div>
        <div className='popup'> 
          <button className='out redb' onClick={Handleout}><i className="fa-solid fa-x"></i></button>
          <h2>Nos pondremos en contacto contigo para que te puedas unir a Reneé </h2>
          <br/>
          <div>
            <form onSubmit={MailContacto} className='popform'>
            <label><strong>Nombre</strong>
            <input  type='text'
                    required
                    placeholder= {'Nombre'}
                    style={{padding:'1vh'}}
                    onChange={e => setU_name(e.target.value)}/>
            </label>
            <label><strong>Apelido</strong>
            <input  type='text'
                    required
                    placeholder= {'Apellido'}
                    style={{padding:'1vh'}}
                    onChange={e => setU_last(e.target.value)}/>
            </label>
            <label><strong>Ciudad</strong>
            <input  type='text'
                    required
                    placeholder= {'Ciudad'}
                    style={{padding:'1vh'}}
                    onChange={e => setU_city(e.target.value)}/>
            </label>


            <label><strong>Empresa</strong>
            <input  type='text'
                    required
                    placeholder= {'Empresa'}
                    style={{padding:'1vh'}}
                    onChange={e => setU_emp(e.target.value)}/>
            </label>
            <label><strong>Correo</strong>
            <input  type='email'
                    required
                    placeholder= {'Correo'}
                    style={{padding:'1vh'}}
                    onChange={e => setU_mail(e.target.value)}/>
            </label>
            <label><strong>Teléfono</strong>
            <input  type='number'
                    required
                    placeholder= {'Telefono'}
                    style={{padding:'1vh'}}
                    onChange={e => setU_phone(e.target.value)}/>
            </label>


            <div style={{gridColumnStart:'1', gridColumnEnd:'4'}}>
              <label><strong>Cuéntanos un poco  más de tu empresa</strong>
              <textarea className='notas'
                        required
                        placeholder= {'Cuentanos sobre tu empresa'}
                        style={{padding:'1vh', width:'60vh', height:'10vh'}}
                        onChange={e => setU_text(e.target.value)}/>
              </label>
            </div>
            

            <div style={{gridColumnStart:'1', gridColumnEnd:'4'}}>
              <button style={{width:'100%',marginBottom:'0px', padding:'2vh'}} type='submit'> enviar tus datos </button>
            </div>
            
            </form>
          </div>
        </div>
      </div>

      {/* Pop-up Recuperacion contraseña */}
      <div className={Popup2 ? 'popupBG' : 'hidden'}>
        <div className='outside'  onClick={Handleout} >
        </div>
        <div className='popup'>
          <button 
            className='out redb' 
            onClick={Handleout}>
            <i className="fa-solid fa-x"></i>
          </button> 
          <h2> Has olvidado tu clave?  </h2>
          <h3> Ingresa el correo en el cual quieres recibir tu clave temporal </h3>
          <div>
            <form  onSubmit={MailRecuperar} style={{alignItems:'center'}}>

            <label><strong>Correo  </strong></label>
            <input  type='email'
                    required
                    placeholder= {'Correo'}
                    style={{padding:'1vh', width:'50%'}}
                    onChange={e => setU_mail(e.target.value)}/>

            <button type='submit' style={{width:'40%',marginBottom:'0px'}}> enviar tus datos </button>
            
            </form>
          </div>
        </div>
      </div>


      {/* Cuerpo Login */}
      {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
      {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}

      <img 
        src='/favicon.png' 
        alt='favicon' 
        style={{ width:'20vh', height:'20vh' }}
        className='logo' 
        onClick={GotoMS}/>

      <div  className='MainLog'>
        <div className='boxlog'>
          <div style={{fontSize:'3vh', marginBottom:'1vh'}}>
            <h2>¡Bienvenido a Renee!</h2>
            Por favor ingresa tus datos
          </div>
          <form onSubmit={HandleLoggin}>
        
            <input type='text' placeholder='Correo Electronico' style={{height:'5vh', fontSize:'2vh', textAlign:'center'}}
            onChange={e => setUserName(e.target.value.trim())} />
        
            <input type='password' placeholder='Clave' style={{height:'5vh', fontSize:'2vh', textAlign:'center'}}
            onChange={e => setPassword(e.target.value)} />
        
            <button type='submit' style={{height:'6vh', width:'60%', marginLeft:'20%'}}>
              INICIAR SESION
            </button>
              
            {error && <div className='error'>{error}</div>}
          </form>
          <div style={{display:'grid', gridTemplateColumns:'1fr 1fr 1fr', marginTop:'20vh'}}>
            <button style={{height:'6vh'}} 
                  onClick={ () => setPopup1(true) }> Quiero Unirme a Reneé </button>
            <div></div>
            <button onClick={ () => setPopup2(true) }> Olvide mi clave </button>
          </div>
        </div>
      </div>
      
    </main>
  );
}

