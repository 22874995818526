
//=============================================================================
// Pantalla Recuperación de Perfiles
//=============================================================================

// Generico
import './css/App.css';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// API
import APICALL from './APICALL';
import LOGCALL from './LOGCALL';
import AICALL from './AICALL';
// Especifico
import StarRating from '../components/star';
import jsPDF from 'jspdf';

export default function Recuperar() {

  const navigate = useNavigate();
  const [FetchedData, setFetchedData] = useState(''); 
  const [Score, setScore] = useState('0');
  const [SortType, setSortType] = useState('chrono');
  const [ScreenType, setScreentype] = useState('perfil');
  const [IDlist,setIDlist] = useState([]);
  const [CVlist, setCVlist] = useState([]);
  const [SelectList, setSelectList] = useState([]);
  const [SelectList2, setSelectList2] = useState([]);
  const [SelectList3, setSelectList3] = useState([]);
  const [SelectQuest, setSelectQuest] = useState([]);
  const [SelectedCandidates, setSelectedCandidates] = useState([]);
  const [Wait, setWait] = useState(false);
  const [State, setState] = useState(false);
  const [ShowPopup, setShowPopup3] = useState(false);
  const FileInputRef = useRef(null);
  const [CV, setCV] = useState(null);
  const cvContainerRef = useRef(null);
  const [SelectedCVName, setSelectedCVName] = useState(null);
  const ProcessedIds = new Set();

  const [SelectedMail, setSelectedMail] = useState('');
  const [SelectedNumber, setSelectedNumber] = useState('');


  // Exportado CV a PDF
  async function ExportToPDFCV(ciego) {
    setWait(true);
    const doc = new jsPDF({
      format: 'a4',
      compress: true,
    });
  
    // Paleta de colores de la página
    const colors = {
      light: "#F5F6FF",
      midLight: "#DDE1FE",
      midMain: "#b0b8f3",
      main: "#8993f8",
      darkMain: "#7A84D4",
      action: "#6A7AE2",
      darkAction: "#4F5CA8",
      dark: "#50577A"
    };
    const hexToRgb = (hex) => {
      const r = parseInt(hex.substring(1, 3), 16) / 255;
      const g = parseInt(hex.substring(3, 5), 16) / 255;
      const b = parseInt(hex.substring(5, 7), 16) / 255;
      return { r, g, b };
    };
  
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10; // Aumentado ligeramente para un aspecto más limpio
  
    const logoRenee = new Image();
    logoRenee.src = '/favicon.png';
    const logoWidth = 25;
    const logoHeight = 25;
  
    // Ajuste de proporciones para un aspecto más moderno
    const leftColumnWidth = (pageWidth - 3 * margin) * 0.33; // Ligeramente más estrecha
    const rightColumnWidth = (pageWidth - 3 * margin) * 0.67;
    const fontsize = 10;
    const titleFontSize = 14; // Título más grande para mejor jerarquía visual
    const subtitleFontSize = 12; // Para subtítulos
    const contentMarginTop = 15; // Más espacio arriba
    let yLeft = contentMarginTop;
    let yRight = contentMarginTop;
    
    // Track current page for each column
    let leftColumnPage = 1;
    let rightColumnPage = 1;
    let currentPage = 1;
  
    // Estilos para elementos visuales
    const iconSize = 3;
    const sectionSpacing = 5; // Espaciado entre secciones
  
    // Función para dibujar el fondo de la columna izquierda con un diseño más moderno
    const drawLeftColumnBackground = () => {
      const bgColor = hexToRgb(colors.midLight);
      doc.setFillColor(bgColor.r * 255, bgColor.g * 255, bgColor.b * 255);
      doc.rect(0, 0, leftColumnWidth + 1.5 * margin, pageHeight, 'F');
      
      // Añadir un elemento decorativo superior
      const headerColor = hexToRgb(colors.main);
      doc.setFillColor(headerColor.r * 255, headerColor.g * 255, headerColor.b * 255);
      doc.rect(0, 0, leftColumnWidth + 1.5 * margin, 12, 'F');
      
      // Añadir línea separadora sutil entre columnas
      const separatorColor = hexToRgb(colors.midMain);
      doc.setDrawColor(separatorColor.r * 255, separatorColor.g * 255, separatorColor.b * 255);
      doc.setLineWidth(0.5);
      doc.line(leftColumnWidth + 1.5 * margin, 0, leftColumnWidth + 1.5 * margin, pageHeight);
    };
    drawLeftColumnBackground();
  
    // Función para dibujar iconos
    const drawIcon = (x, y, type) => {
      // Make sure we're on the correct page before drawing the icon
      if (doc.internal.getCurrentPageInfo().pageNumber !== leftColumnPage) {
        doc.setPage(leftColumnPage);
      }
      
      const iconColor = hexToRgb(colors.darkAction);
      doc.setFillColor(iconColor.r * 255, iconColor.g * 255, iconColor.b * 255);
      
      switch(type) {
        case 'punto':
          doc.circle(x, y-1, iconSize / 2, 'F');
          break;
        case 'phone':
          doc.rect(x - iconSize / 2, y - iconSize / 2, iconSize, iconSize, 'F');
          break;
        case 'location':
          // Triángulo simple para ubicación
          doc.triangle(
            x, y - iconSize / 2,
            x - iconSize / 2, y + iconSize / 2,
            x + iconSize / 2, y + iconSize / 2,
            'F'
          );
          break;
        case 'linkedin':
          doc.circle(x, y, iconSize / 2, 'F');
          break;
        case 'facebook':
          doc.rect(x - iconSize / 2, y - iconSize / 2, iconSize, iconSize, 'F');
          break;
        default:
          doc.circle(x, y, iconSize / 2, 'F');
      }
    };
  
    // Function to switch to a specific page
    const switchToPage = (pageNum) => {
      if (doc.internal.getCurrentPageInfo().pageNumber !== pageNum) {
        doc.setPage(pageNum);
      }
      currentPage = pageNum;
    };
  
    // Function to add a new page and set up backgrounds
    const addNewPage = () => {
      doc.addPage();
      currentPage++;
      drawLeftColumnBackground();
      return currentPage;
    };
    
  
    // Cargar la imagen del perfil con un marco moderno
    var img = new Image();
    img.src = CV.personalInfo.picture ? CV.personalInfo.picture + '?not-from-cache-please' : '/default.png';
    const profileSize = 45;
    const profileX = (leftColumnWidth - profileSize) / 2 + 5;
    const profileY = contentMarginTop;
    doc.addImage(img, 'JPEG', profileX, profileY, profileSize, profileSize);
    yLeft = profileY + profileSize + 10; // Más espacio debajo de la foto
  
    // Modified printText function to handle column overflow correctly
    const printText = (text, x, y, style = 'normal', columnWidth, addMargin = true, isLeftColumn = false) => {
      // First switch to the appropriate page
      if (isLeftColumn) {
        switchToPage(leftColumnPage);
      } else {
        switchToPage(rightColumnPage);
      }
      
      let fontSize = fontsize;
      let fontStyle = 'normal';
      let textColor = { r: 0, g: 0, b: 0 }; // Negro por defecto
      
      // Configurar estilos basados en el tipo de texto
      switch(style) {
        case 'title':
          fontSize = titleFontSize;
          fontStyle = 'bold';
          const titleColor = hexToRgb(colors.dark);
          textColor = titleColor;
          break;
        case 'subtitle':
          fontSize = subtitleFontSize;
          fontStyle = 'bold';
          const subtitleColor = hexToRgb(colors.darkAction);
          textColor = subtitleColor;
          break;
        case 'company':
          fontSize = fontsize + 1;
          fontStyle = 'bold';
          const companyColor = hexToRgb(colors.darkAction);
          textColor = companyColor;
          break;
        case 'date':
          fontSize = fontsize - 1;
          fontStyle = 'italic';
          const dateColor = hexToRgb(colors.dark);
          textColor = dateColor;
          break;
        case 'highlight':
          fontStyle = 'bold';
          const highlightColor = hexToRgb(colors.action);
          textColor = highlightColor;
          break;
        default:
          const defaultColor = hexToRgb(colors.dark);
          textColor = defaultColor;
      }
  
      doc.setFont('helvetica', fontStyle);
      doc.setFontSize(fontSize);
      doc.setTextColor(textColor.r * 255, textColor.g * 255, textColor.b * 255);
  
      const lineHeight = fontSize * 0.5;
      const textLines = doc.splitTextToSize(text, columnWidth);
      
      // Estimación de altura para verificar si excederá la página
      const estimatedHeight = textLines.length * lineHeight + (addMargin ? 4 : 0);
      
      // If text will exceed the page height
      if (y + estimatedHeight > pageHeight - margin - 20 && style !== 'title') {
        if (isLeftColumn) {
          // For left column, if right column has content on current page, start from that page
          // otherwise create a new page
          if (rightColumnPage === leftColumnPage && yRight <= y) {
            // Right column has content on this page and hasn't reached where left is
            // We'll allow the right column to continue on this page
            leftColumnPage = addNewPage();
            y = contentMarginTop;
            yLeft = y;
          } else if (rightColumnPage > leftColumnPage) {
            // Right column is already on a later page, move left to that page
            switchToPage(rightColumnPage);
            leftColumnPage = rightColumnPage;
            y = contentMarginTop;
            yLeft = y;
          } else {
            // Add a new page for both columns
            leftColumnPage = addNewPage();
            rightColumnPage = leftColumnPage;
            yLeft = contentMarginTop;
            yRight = contentMarginTop;
            y = contentMarginTop;
          }
        } else {
          // For right column
          if (leftColumnPage === rightColumnPage && yLeft <= y) {
            // Left column has content on this page but hasn't reached where right is
            rightColumnPage = addNewPage();
            y = contentMarginTop;
            yRight = y;
          } else if (leftColumnPage > rightColumnPage) {
            // Left column is already on a later page, move right to that page
            switchToPage(leftColumnPage);
            rightColumnPage = leftColumnPage;
            y = contentMarginTop;
            yRight = y;
          } else {
            // Add a new page for both columns
            rightColumnPage = addNewPage();
            leftColumnPage = rightColumnPage;
            yLeft = contentMarginTop;
            yRight = contentMarginTop;
            y = contentMarginTop;
          }
        }
      }
      
      // Imprimir cada línea
      textLines.forEach((subLine) => {
        doc.text(x, y, subLine);
        y += lineHeight;
      });
      
      // Añadir línea decorativa después de títulos
      if (style === 'title') {
        const lineColor = hexToRgb(colors.main);
        doc.setDrawColor(lineColor.r * 255, lineColor.g * 255, lineColor.b * 255);
        doc.setLineWidth(1);
        doc.line(x, y, x + columnWidth / 2, y);
        y += 4;
      }
      
      if (addMargin) y += 4;
      
      // Actualizar las posiciones globales
      if (isLeftColumn) {
        yLeft = y;
      } else {
        yRight = y;
      }
      
      return y;
    };
  
    // Calcular la edad
    let age;
    let ageText = "";
    const [day, month, year] = CV.personalInfo.birthDate.split('/').map(Number);
    if (!isNaN(day) && !isNaN(month) && !isNaN(year) && year > 1900) {
      const birthdate = new Date(year, month - 1, day);
      const today = new Date();
  
      age = today.getFullYear() - birthdate.getFullYear();
      const monthDiff = today.getMonth() - birthdate.getMonth();
  
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
        age--;
      }
      
      if (!isNaN(age) && age > 0 && age < 120) {
        ageText = `${age} Años`;
      }
    }
  
    // Ensure we're on the first page for the left column content
    switchToPage(leftColumnPage);
    
    // Imprimir nombre y edad con estilo destacado, ajustando si es necesario
    const nameText = `${CV.personalInfo.firstName} ${CV.personalInfo.lastName}`;
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(titleFontSize);
    const nameColor = hexToRgb(colors.dark);
    doc.setTextColor(nameColor.r * 255, nameColor.g * 255, nameColor.b * 255);
    const maxNameWidth = leftColumnWidth - margin;
    const nameWidth = doc.getStringUnitWidth(nameText) * titleFontSize / doc.internal.scaleFactor;
    if (nameWidth > maxNameWidth) {
      // Si el nombre es demasiado largo, dividirlo en líneas
      const nameLines = doc.splitTextToSize(nameText, maxNameWidth);
      
      // Centrar cada línea
      nameLines.forEach((line, index) => {
        const lineWidth = doc.getStringUnitWidth(line) * titleFontSize / doc.internal.scaleFactor;
        const lineX = ((leftColumnWidth + margin) - lineWidth) / 2;
        doc.text(line, lineX, yLeft + index * titleFontSize * 0.6);
      });
      
      // Actualizar la posición Y para la edad
      yLeft += nameLines.length * titleFontSize * 0.6;
    } else {
      // Si el nombre cabe en una línea, centrarlo como antes
      const nameX = ((leftColumnWidth + margin) - nameWidth) / 2;
      doc.text(nameText, nameX, yLeft);
      yLeft += titleFontSize * 0.5;
    }
    
    // Edad centrada
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(fontsize);
    if (ageText !== ''){
      const ageWidth = doc.getStringUnitWidth(ageText) * fontsize / doc.internal.scaleFactor;
      const ageX = ((leftColumnWidth + margin) - ageWidth) / 2;
      doc.text(ageText, ageX, yLeft);
    }
  
    // Sección de presentación
    yLeft = printText('', margin, yLeft - 2 , 'title', leftColumnWidth, true, true);
    if (CV.presentation){
      yLeft = printText(CV.presentation, margin, yLeft, 'normal', leftColumnWidth, true, true);
      yLeft += sectionSpacing;
    }
  
    // Start the right column now - if it's independent from left column
    switchToPage(rightColumnPage);
    // Columna derecha - Experiencia laboral
    yRight = printText('EXPERIENCIA LABORAL', leftColumnWidth + 2 * margin, yRight, 'title', rightColumnWidth, true, false);
  
    // Back to left column to continue
    switchToPage(leftColumnPage);
    // Información de contacto con iconos
    if (!ciego) {
      yLeft = printText('CONTACTO', margin, yLeft, 'subtitle', leftColumnWidth, true, true);
    
      // Email con icono
      drawIcon(margin + iconSize, yLeft, 'punto');
      yLeft = printText(CV.personalInfo.emails.primaryEmail, margin + iconSize * 2 + 2, yLeft, 'normal', leftColumnWidth - iconSize * 2 - 2, true, true);
      
      // Teléfono con icono
      drawIcon(margin + iconSize, yLeft, 'punto');
      yLeft = printText(CV.personalInfo.phoneNumbers[0].number, margin + iconSize * 2 + 2, yLeft, 'normal', leftColumnWidth - iconSize * 2 - 2, true, true);
      
      // Dirección con icono
      drawIcon(margin + iconSize, yLeft, 'punto');
      const addressText = ` ${CV.personalInfo.address ? CV.personalInfo.address + '\n' : ''}${CV.personalInfo.communeName + '\n' || ''}${CV.personalInfo.regionName !== ' - - -'? 'Región de ' + CV.personalInfo.regionName :  ''} `;
      yLeft = printText(addressText, margin + iconSize * 2 + 2, yLeft, 'normal', leftColumnWidth - iconSize * 2 - 2, true, true);
      
      yLeft += sectionSpacing/2;
      
      // Redes sociales
      yLeft = printText('REDES SOCIALES', margin, yLeft, 'subtitle', leftColumnWidth, true, true);
      
      // LinkedIn con icono
      if (CV.socialNetworks.linkedinLink && CV.socialNetworks.linkedinLink !== ' - - - ') {
        drawIcon(margin + iconSize, yLeft, 'punto');
        yLeft = printText(CV.socialNetworks.linkedinLink, margin + iconSize * 2 + 2, yLeft, 'normal', leftColumnWidth - iconSize * 2 - 2, true, true);
      }
      
      // Facebook con icono
      if (CV.socialNetworks.facebookLink && CV.socialNetworks.facebookLink !== ' - - - ') {
        drawIcon(margin + iconSize, yLeft, 'punto');
        yLeft = printText(CV.socialNetworks.facebookLink, margin + iconSize * 2 + 2, yLeft, 'normal', leftColumnWidth - iconSize * 2 - 2, true, true);
      }
    }
    
    // Switch back to right column to continue with experience
    switchToPage(rightColumnPage);
    
    // Procesar experiencia laboral con mejor formato
    if (CV.workExperience && CV.workExperience !== ' - - -') {
      // En la sección donde procesas la experiencia laboral, antes de imprimir cada trabajo
      CV.workExperience
        .slice()
        .sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate))
        .forEach(job => {
          // Calcular la altura aproximada que ocupará todo el bloque
          const jobTitleHeight = titleFontSize * 0.5 + 4;
          const dateHeight = fontsize * 0.5 + 4;
          
          // Calcular cuántas líneas ocuparán los logros
          let achievementsHeight = 0;
          const achievementLines = job.achievements.split('\n')
            .filter(achievement => achievement.trim() !== '');
          
          achievementLines.forEach(achievement => {
            const lines = doc.splitTextToSize(achievement, rightColumnWidth - 6).length;
            achievementsHeight += lines * fontsize * 0.5 + 4;
          });
          
          const totalBlockHeight = jobTitleHeight + dateHeight + achievementsHeight + 4;
          
          // Verificar si el bloque completo cabe en la página actual
          if (yRight + totalBlockHeight > pageHeight - margin - 20) {
            // If we're on the same page as the left column
            if (rightColumnPage === leftColumnPage) {
              // Check if left column still has space
              if (yLeft + 50 < pageHeight - margin - 20) {
                // Continue with left column content first
                switchToPage(leftColumnPage);
                // Right column will continue in next loop iteration
                return;
              }
            }
            
            // Need a new page for right column
            rightColumnPage = addNewPage();
            yRight = contentMarginTop;
            
            // If left column is behind, bring it to the same page
            if (leftColumnPage < rightColumnPage) {
              leftColumnPage = rightColumnPage;
              yLeft = contentMarginTop;
            }
          }
          
          // Ahora imprimir el bloque completo sabiendo que cabe en la página
          switchToPage(rightColumnPage);
          yRight = printText(`${job.companyName} | ${job.jobPosition}`, leftColumnWidth + 2 * margin, yRight, 'company', rightColumnWidth, false, false);
          
          const dateText = `${job.fromDate} - ${job.toDate || 'PRESENTE'}`;
          yRight = printText(dateText, leftColumnWidth + 2 * margin, yRight, 'date', rightColumnWidth, true, false);
          
          achievementLines.forEach(achievement => {
            switchToPage(rightColumnPage);
            const bulletColor = hexToRgb(colors.action);
            doc.setFillColor(bulletColor.r * 255, bulletColor.g * 255, bulletColor.b * 255);
            doc.circle(leftColumnWidth + 2 * margin + 2, yRight - 1, 1.5, 'F');
            yRight = printText(achievement, leftColumnWidth + 2 * margin + 6, yRight, 'normal', rightColumnWidth - 6, true, false);
          });
          
          yRight += 4;
        });
    } else {
      yRight = printText('No hay experiencia laboral disponible.', leftColumnWidth + 2 * margin, yRight, 'normal', rightColumnWidth, true, false);
    }
  
    // Espacio entre secciones de la columna derecha
    yRight += sectionSpacing;
    
    // Inteligently determine where to put education section
    // If left column has more space than right, continue with left first
    if (pageHeight - yLeft > pageHeight - yRight + 50 && leftColumnPage === rightColumnPage) {
      // There's more space in the left column, let's fill that before continuing with education
      // Additional left column content would go here if needed
      // For now we'll continue with education
    }
    
    // Verificar si necesitamos una nueva página para la sección de educación
    if (yRight + 60 > pageHeight - margin - 20) {
      // Check if we can fit education section after the left column on current page
      if (leftColumnPage === rightColumnPage && yLeft + 100 < pageHeight - margin - 20) {
        // Left column still has space, let's use that page
        // Right column will automatically continue on next page
      } else {
        // Need a new page for both columns
        rightColumnPage = addNewPage();
        if (leftColumnPage < rightColumnPage) {
          leftColumnPage = rightColumnPage;
        }
        yLeft = contentMarginTop;
        yRight = contentMarginTop;
      }
    }
  
    // Educación
    switchToPage(rightColumnPage);
    yRight = printText('EDUCACIÓN', leftColumnWidth + 2 * margin, yRight, 'title', rightColumnWidth, true, false);
    
    // Procesar educación con mejor formato
    if (CV.studies.higherEducation && CV.studies.higherEducation !== ' - - -') {
      CV.studies.higherEducation
        .slice()
        .sort((a, b) => new Date(b.entryYear) - new Date(a.entryYear))
        .forEach(edu => {
          // Verificar si necesitamos una nueva página para este ítem de educación
          if (yRight + 30 > pageHeight - margin - 20) {
            // If left column still has space on current page
            if (leftColumnPage === rightColumnPage && yLeft + 50 < pageHeight - margin - 20) {
              // Let left column content continue here
              // Right column will move to next page
              rightColumnPage = addNewPage();
              yRight = contentMarginTop;
            } else {
              // Add a new page for both columns
              rightColumnPage = addNewPage();
              if (leftColumnPage < rightColumnPage) {
                leftColumnPage = rightColumnPage;
                yLeft = contentMarginTop;
              }
              yRight = contentMarginTop;
            }
          }
          
          switchToPage(rightColumnPage);
          // Carrera con estilo destacado
          const careerText = edu.otherCareer || edu.minor || '';
          if (careerText) {
            yRight = printText(careerText, leftColumnWidth + 2 * margin, yRight, 'company', rightColumnWidth, false, false);
          }
  
          // Institución
          const institutionText = edu.institution?.name || edu.otherInstitution || '';
          if (institutionText) {
            yRight = printText(institutionText, leftColumnWidth + 2 * margin, yRight, 'normal', rightColumnWidth, false, false);
          }
  
          // Fecha con estilo sutil
          const eduDateText = `${edu.entryYear} - ${edu.graduationyear || 'PRESENTE'}`;
          yRight = printText(eduDateText, leftColumnWidth + 2 * margin, yRight, 'date', rightColumnWidth, true, false);
  
          // Espacio entre educaciones
          yRight += 4;
        });
    } else {
      yRight = printText('No hay información educativa disponible.', leftColumnWidth + 2 * margin, yRight, 'normal', rightColumnWidth, true, false);
    }
  
    // Añadir pie de página y números de página con estilo moderno
    const addFooterAndPageCount = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
  
        // Fondo de color para el pie de página
        const footerColor = hexToRgb(colors.midLight);
        doc.setFillColor(footerColor.r * 255, footerColor.g * 255, footerColor.b * 255);
        doc.rect(0, pageHeight - 15, pageWidth, 15, 'F');
  
        // Línea decorativa
        const footerLineColor = hexToRgb(colors.main);
        doc.setDrawColor(footerLineColor.r * 255, footerLineColor.g * 255, footerLineColor.b * 255);
        doc.setLineWidth(0.75);
        doc.line(0, pageHeight - 15, pageWidth, pageHeight - 15);
  
        // Número de página con estilo
        const pageText = `Página ${i} de ${pageCount}`;
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        const pageNumColor = hexToRgb(colors.dark);
        doc.setTextColor(pageNumColor.r * 255, pageNumColor.g * 255, pageNumColor.b * 255);
        doc.text(margin, pageHeight - 6, pageText, { align: 'left' });
  
        // Posición para el logo
        const logoX = pageWidth - logoWidth;
        const logoY = pageHeight - logoHeight;
        
        // Añadir un borde redondeado con el color principal
        const borderColor = hexToRgb(colors.main);
        doc.setFillColor(255, 255, 255); // Fondo blanco para el logo
        doc.setDrawColor(borderColor.r * 255, borderColor.g * 255, borderColor.b * 255);
        doc.setLineWidth(0.7);
        
        // Dibujar un rectángulo redondeado con borde de color y fondo blanco
        // El radio de las esquinas es 3 para que sea ligeramente redondeado
        const borderPadding = 1.5; // Padding más pequeño para mantener el tamaño original
        doc.roundedRect(
          logoX - borderPadding, 
          logoY - borderPadding, 
          logoWidth * 0.8 + borderPadding * 2, 
          logoHeight * 0.8 + borderPadding * 2, 
          3, 3, 'FD' // 'FD' significa Fill and Draw (rellenar y dibujar borde)
        );
        
        // Agregar logo sobre el fondo
        doc.addImage(logoRenee, 'PNG', logoX, logoY, logoWidth * 0.8, logoHeight * 0.8);
      }
    };
    addFooterAndPageCount(doc);
  
    // Guardar el PDF con nombre apropiado
    if (!ciego) {
      doc.save(`CV_${CV.personalInfo.firstName}_${CV.personalInfo.lastName}.pdf`);
    } else {
      doc.save(`CV_${CV.personalInfo.firstName}_${CV.personalInfo.lastName}_Ciego.pdf`);
    }
  
    setTimeout(() => {
      setWait(false);
    }, 500);
  }

  // Envio de correos
  async function HandleMailAll( ) {
  
    const MailList = []
    for (const reply of CVlist) {
      if (!SelectedCandidates.includes(reply.name) && !reply.Rejected) {
        try {
          MailList.push(await HandleMail(reply.mail, reply.name));   
          // MailList.push(reply.name)
        } catch (error) {
          console.error(`Error al enviar mensaje a ${reply.name}`);
        }
      }
    }

    if (MailList.length > 0) {
        const formattedList = MailList.map(email => `- ${email}`).join('\n');
        MailNotiff(MailList, 'Rechazo');
        window.alert(`Notificacion enviada exitosamente a los siguientes candidatos:\n\n${formattedList}`);
    } else {
        window.alert('No Se logro enviar una notificacion a ningun candidato, por favor intente nuevamente');
    }

    window.location.reload();
    setShowPopup3(false);
    
  };
  async function HandleMail(mail, name) {

    try {
      await LOGCALL({
        apicall: 'FM_REJ1',
        Name : name,
        Mail : mail,
        Title : FetchedData.Charge_title,
        P_name: sessionStorage.token,
        P_mail: sessionStorage.mail
      });
      SaveCVReject(name);
      return(name)
    } catch (error) {
      console.error('Error during fetch:', error);
      return('')
    }
  };
  async function MailNotiff(list , theme) {

    try {
      await LOGCALL({
        apicall: 'FM_NOT',
        code: sessionStorage.process,
        Mail : sessionStorage.mail,
        Title : FetchedData.Charge_title,
        P_name: sessionStorage.token,
        List: list,
        Theme: theme
      });
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };

  // Puntajes de candidatos
  const ToggleCandidateSelection = (name) => {
    setSelectedCandidates(prev =>
      prev.includes(name) ? prev.filter(item => item !== name) : [...prev, name]
    );
  };
  async function HandleRatingChange ( newRating ) {
    setScore(newRating);
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === SelectedCVName
          ? { ...cv, stars: newRating} 
          : cv
      )
    );
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo,
        CVData: { name: SelectedCVName, stars: newRating}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }

  };
  async function HandleReneeopinion( CV , name ) {

    const instruction = 'EL Perfil: ' + sessionStorage.currentprofile 
                 + '. \n El candidato: ';

    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name
          ? { ...cv, Nota: null } 
          : cv
      )
    );

    try {
      const consulta = await AICALL({
        apicall: '05',
        data: instruction 
              + JSON.stringify(CV.personalInfo) 
              + JSON.stringify(CV.workExperience) 
              + JSON.stringify(CV.studies)
      });

      const parsedconsulta = JSON.parse(consulta.reply)
      
      setCVlist(prevCVlist =>
        prevCVlist.map(cv =>
          cv.name === name
            ? { ...cv, Nota: parsedconsulta.Evaluacion } 
            : cv
        )
      );
      return parsedconsulta.Evaluacion
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    
  };

  // Recoleccion de candidatos
  async function HandleIDFetch () {
    let input = ''
    if (IDlist && Array.isArray(IDlist)){
      input = prompt(
        'IDs actuales:\n' +
        IDlist.map(id => '> ' + id).join('\n') + 
        '\n Ingrese el ID del proceso:'
      );
    } else if (IDlist) {
      input = prompt(
        'IDs actuales:\n' +
        IDlist + 
        '\n Ingrese el ID del proceso:'
      );
    } else {
      input = prompt(
        '\n Ingrese el ID del proceso:'
      );
    }
    if (input !== null && !IDlist.includes(input)) {
      try {
        IDlist.push(input);
        await APICALL({
          apicall: 'PD_update',
          code: sessionStorage.process,
          U_emp: sessionStorage.Grupo,
          JobId: IDlist
        });
      } catch (error) {
        console.error('Error during fetch:', error);
        return { token: null };
      }
      setCV(null); 
      HandleCandidatos(input); ;
    }
  };
  async function HandleCandidatos( data ) {

    setWait(true);

    sessionStorage.setItem('currentsave' , sessionStorage.process)
    sessionStorage.setItem('currentprofile' , sessionStorage.perfil)

    // caso YAPO
    if (String(data).includes('YP_')) {
      try {
        const reply = await APICALL({
          apicall: 'YP_list',
          code: sessionStorage.currentsave,
          U_emp: sessionStorage.Grupo,
          jobID: data.replace('YP_', '')
        });
  
  
        let list = [];
        if (reply) {
          list = reply || [];
    
          const cvPromises = list.map(async (applicant) => {
            if (!ProcessedIds.has(applicant.id)) {
              ProcessedIds.add(applicant.id);
              await HandleCVYP(applicant, data);
            } 
          });
    
          await Promise.all(cvPromises);
        }
      } catch (error) {
        console.error('Error during fetch:', error);
      }
    // caso TRABAJANDO
    } else {
      try {
        const reply = await APICALL({
          apicall: 'TB_list',
          code: sessionStorage.currentsave,
          U_emp: sessionStorage.Grupo,
          jobID: data
        });
  
  
        let list = [];
        if (reply) {
          list = reply.data.sharedPostulations || [];
    
          const cvPromises = list.map(async (applicant) => {
            if (!ProcessedIds.has(applicant.applicantId)) {
              ProcessedIds.add(applicant.applicantId);
              await HandleCVTB(applicant.applicantId, data, applicant.questions);
            } 
          });
    
          await Promise.all(cvPromises);
        }
      } catch (error) {
        console.error('Error during fetch:', error);
      }
    }


    setTimeout(() => {
      setWait(false);
    }, 500);

  };
  async function HandleCVTB( data , jid , questions ) {
  
    try {
      const reply = await APICALL({
        apicall: 'TB_fetch',
        code: sessionStorage.currentsave,
        U_emp: sessionStorage.Grupo,
        id_code: data
      });
  
      const { personalInfo, socialNetworks, workExperience, presentation, studies } = reply.data;
      const name = `${personalInfo.firstName} ${personalInfo.lastName}`;
      const rut  = `${personalInfo.idNumberFormat}`;
      const mail = `${personalInfo.emails.primaryEmail}`;
      const phone = `${personalInfo.phoneNumbers[0].number}`;
      const cv = { personalInfo, socialNetworks, workExperience, presentation, studies };
  
      setCVlist(prevCVlist => {
        const existingCV = prevCVlist.find(cv => cv.id === data);
  
        if (reply && !existingCV) {
          const updatedList = [{ name: name, id: data, rut: rut, new: true, phone: phone },...prevCVlist];
          HandleReneeopinion(cv, name).then(nota => {
            SaveCV( sessionStorage.currentsave, name, mail, data, jid, rut, nota, questions, null, phone );
            setCVlist(prevCVlist =>
              prevCVlist.map(cv =>
                cv.name === name
                  ? { ...cv, questions: questions} 
                  : cv
              )
            );
          });
          
          return updatedList;
        } else {
          return prevCVlist;
        }
      });
  
    } catch (error) {
      console.error('Error during fetch:', error);
    } 
  };
  async function HandleCVYP( applicant , jid ) {

    function isEmptyCV(cv) {
      if (!cv || Object.keys(cv).length === 0) return true;
    
      return Object.values(cv).every(value => {
        if (typeof value === "string") return value.trim() === ""; 
        if (Array.isArray(value)) return value.length === 0; 
        if (typeof value === "object") return Object.keys(value).length === 0; 
        return value === null;
      });
    }

    const name = applicant.contact.name;
    const rut  = applicant.additionaldata.rut || '';
    const mail = applicant.contact.email;
    const phone = applicant.contact.phone || '';

    setCVlist(prevCVlist => {
      const existingCV = prevCVlist.find(cv => cv.mail === mail);

      if (applicant.curriculum && !existingCV) {

        (async () => {
          try {
            const reply = await APICALL({
              apicall: 'YP_fetch',
              code: sessionStorage.process,
              U_emp: sessionStorage.Grupo,
              id_code: applicant.curriculum
            });

            if (isEmptyCV(reply)) {
              SaveCV( sessionStorage.currentsave, name, mail, 'hidden', jid, rut, null, null, null, null, phone );
              const updatedList = [...prevCVlist,{ name: name, mail: mail , id: 'hidden' , rut: rut, new: true, phone: phone }];
              return updatedList;
            }

            try {
              const CVreply = await AICALL({
                apicall: '09',
                data: 'si no encuentras un nombre, usa este : ' + name + ', data: ' + reply.text
              });
              const formatedreply = CVreply.reply
                .replace(/```json\n/, '')
                .replace(/\n```/, '');
              const parsedreply = JSON.parse(formatedreply);
        
              try {
                await APICALL({
                  apicall: 'CV_create',
                  code: sessionStorage.process,
                  U_emp: sessionStorage.Grupo,
                  Name: name,
                  CVData: parsedreply,
                });

                HandleReneeopinion(parsedreply, name).then(nota => {  
                SaveCV( sessionStorage.currentsave, name, mail, 'CVE_' + name, jid, rut, nota );
                });

              } catch (error) {
                console.error('Error during fetch:', error);
                return { token: null };
              }

            } catch (error) {
              console.error('Error during fetch:', error);
            }
          } catch (error) {
            console.error('Error during fetch:', error);
          }
        })();

        const updatedList = [...prevCVlist, { name: name, mail: mail , id: 'CVE_' + name, rut: rut, new: true }];
        return updatedList;
        
      } else {
        return prevCVlist;
      }
    });
  
  };
  async function HandleCVpropio() {
    const curriculum = CV.multipleDocuments.dataDocument.find(
      doc => doc.documentTypeName === 'Currículum adicional'
    );

    if (curriculum) {
      window.open(curriculum.documentPath);
    } else {
      console.error('Document not found.');
    }
  };
  async function SaveCVReject( Namedata ) {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.currentsave,
        U_emp: sessionStorage.Grupo,
        CVData: { name: Namedata, Rejected: true}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };
  async function SaveCV( Pcode, Namedata , mail , id , jid , rut, score , questions , stars, phone ) {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: Pcode,
        U_emp: sessionStorage.Grupo,
        CVData: { name: Namedata, 
                  mail: mail, 
                  id: id, 
                  JID: jid, 
                  RUT: rut, 
                  Nota: score, 
                  questions: questions, 
                  stars: stars, 
                  phone: phone}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };
  

  // Revision de candidatos
  async function HandleSelect( name ) {
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name
          ? { ...cv, select: !cv.select} 
          : cv
      )
    );
  
    const updatedSelectList = SelectList.includes(name) ? SelectList.filter(id => id !== name)
                                                        : [...SelectList, name];
    setSelectList(updatedSelectList);
    
    const updatedSelectList2 = SelectList2.includes(name) ? SelectList2.filter(id => id !== name)
                                                          : [...SelectList2];
    setSelectList2(updatedSelectList2);

    const updatedSelectList3 = SelectList3.includes(name) ? SelectList3.filter(id => id !== name)
                                                          : [...SelectList3];
    setSelectList3(updatedSelectList3);
    
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo,
        SelectList: updatedSelectList,
        SelectList2: updatedSelectList2,
        SelectList3: updatedSelectList3
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };
  async function LookCV( data ) {

    setWait(true);
    const apiCallType = data.includes('CVE_') ? 'CV_fetch' : 
                        data.includes('yapo') ? 'YP_fetch' : 'TB_fetch';


    try {
      const reply = await APICALL({
        apicall: apiCallType,
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo,
        id_code: data
      });

      function replaceNullStrings(obj) {
        for (const key in obj) {
          if (obj[key] === 'Null' || obj[key] === null) {
            obj[key] = ' - - -';
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            replaceNullStrings(obj[key]);
          }
        }
        return obj;
      }
      
      let profile 

      if (apiCallType === 'CV_fetch'){
        profile = replaceNullStrings(reply.data)
      } else {
        profile = reply.data
      }

      const { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments } = profile;
      const cv = { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments };

      setCV(cv);
      return(cv)
  
    } catch (error) {
      console.error('Error during fetch:', error);
      setCV(null);
      return([])
    }
  };
  const SortCVlist = () => {
    const sortedList = [...CVlist].sort((a, b) => {
      if (SortType === 'score') {
        const notaA = a.Nota ? JSON.parse(a.Nota) : 0;
        const notaB = b.Nota ? JSON.parse(b.Nota) : 0;
        return notaB - notaA; 
      } else if (SortType === 'stars') {
        const starsA = a.stars ? a.stars : 0;
        const starsB = b.stars ? b.stars : 0;
        return starsB - starsA; 
      } else {
        return a.name.localeCompare(b.name); 
      }
    });

    if (SortType === 'chrono') {
      return CVlist;
    } else {
      return sortedList;
    }
  };


  // Subida de CV manual
  const HandleButtonClick = () => {
    FileInputRef.current.click();
  };
  async function HandleFileChange(event) {
    setWait(true);
  
    const files = event.target.files; 
    const promises = Array.from(files).map(async (file) => {
      const data = new FormData();
      data.append('file', file);
      data.append('apicall', '06');
  
      try {
        const reply = await AICALL(data);
        const formatedreply = reply.reply
          .replace(/```json\n/, '')
          .replace(/\n```/, '');
        const parsedreply = JSON.parse(formatedreply);
        const Namedata = `${parsedreply.personalInfo.firstName} ${parsedreply.personalInfo.lastName}`;
        const RUT = parsedreply.personalInfo.idNumberFormat;
        const phone = parsedreply.personalInfo.phoneNumbers[0].number;
        const mail = parsedreply.personalInfo.emails.primaryEmail;
  
        try {
          await APICALL({
            apicall: 'CV_create',
            code: sessionStorage.process,
            U_emp: sessionStorage.Grupo,
            Name: Namedata,
            CVData: parsedreply
          });
        } catch (error) {
          console.error('Error during fetch:', error);
          return { token: null };
        }

        setCVlist((prevCVlist) => [
          { name: Namedata, id: 'CVE_' + Namedata, rut: RUT, new: true, phone: phone, mail: mail, Rejected: false, stars: 0, select: false, questions: null, Nota: null },
          ...prevCVlist,
        ]);

        HandleReneeopinion(parsedreply, Namedata).then(nota => {  
          SaveCV( sessionStorage.currentsave, Namedata, mail, 'CVE_' + Namedata, IDlist.at(-1), RUT, nota, null, null, phone );
        });

      } catch (error) {
        console.error('Error processing file:', file.name, error);
      }
    });
  
    await Promise.all(promises);
  
    setTimeout(() => {
      setWait(false);
    }, 500);
  };


  // Funciones Pop-up
  const OpenRejectionPopup = () => {
    const rejectedCandidates = CVlist.filter(candidate => candidate.Rejected).map(candidate => candidate.name);
    setSelectedCandidates([...new Set([...SelectList, ...rejectedCandidates])]);
    setShowPopup3(true);
  };
  

  // Formateado de datos
  async function UpdateData () {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.currentsave,
        U_emp: sessionStorage.Grupo,
        CVData: { name: SelectedCVName, mail: SelectedMail, phone: SelectedNumber }
      });
      window.alert('Datos actualizados correctamente');
      window.location.reload();
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };
  const FormatJobData = (jobData) => {
    const title = `<h2 style='color: var(--light); background: var(--main); padding: 1vh'>EXPERIENCIA LABORAL</h2>\n\n`;
  
    if (!jobData || !Array.isArray(jobData) || jobData.length === 0) {
      return `${title}<h2 style='padding-left: 2vh'>No hay experiencia laboral disponible</h2><br/>`;
    }
  
    const parseDate = (dateStr) => {
      if (!dateStr) return new Date(0);
  
      const parts = dateStr.split('/').map(Number);
  
      if (parts.length === 3) {
        const [day, month, year] = parts;
        return new Date(year, month - 1, day);
      } else if (parts.length === 2) {
        const [month, year] = parts;
        return new Date(year, month - 1, 1);
      } else if (parts.length === 1 && parts[0] >= 1000) {
        const year = parts[0];
        return new Date(year, 0, 1);
      }
  
      return new Date(0); 
    };
  
    const sortedJobs = jobData.slice().sort((a, b) => {
      return parseDate(b.fromDate) - parseDate(a.fromDate);
    });
  
    const formattedJobs = sortedJobs.map(job => {
      const achievements = job.achievements
        ? (job.achievements.includes('\n?') 
          ? job.achievements.split('\n?').filter(line => line.trim() !== '') 
          : job.achievements.split('\n').filter(line => line.trim() !== ''))
        : [];
  
      const cleanedAchievements = achievements.map(achievement => 
        achievement.replace(/^[*•-]\s*/, '').trim()
      );
  
      const formattedAchievements = cleanedAchievements.length > 0 
        ? cleanedAchievements.map(achievement => `● ${achievement}`).join('</br>')
        : 'Sin logros especificados';
  
      return `
          <div>
            <strong style='color: #333;'>${job.jobPosition ? job.jobPosition.toUpperCase() : 'Sin puesto especificado'}</strong> </br>
            <span style='color: #666;'>${job.companyName || 'Empresa desconocida'} || ${job.companyActivity ? job.companyActivity.description : 'Actividad no especificada'}</span></br>
            <span style='color: #666;'>${job.fromDate || 'Fecha desconocida'} - ${job.toDate || 'Presente'}</span></br>
            <p style='color: #666;'>${formattedAchievements}</p>
            <hr style='border: 0.2vh solid var(--mid-main);'/>
          </div>
      `.trim();
    }).join('</br>');
    
    return `${title}${formattedJobs}`;
  };
  const FormatStudyData = (studyData) => {
    const title = `<h2 style='color: var(--light); background: var(--main); padding: 1vh'>FORMACIÓN ACADÉMICA</h2>\n\n`;
  
    if (
      !studyData || 
      !studyData.higherEducation || // Verifica si higherEducation existe
      !Array.isArray(studyData.higherEducation) // Verifica si es un arreglo
    ) {
      return `${title}<h2 style='padding-left: 2vh'>No hay formación académica disponible</h2><br/>`;
    }
    
  
    const sortedStudies = studyData.higherEducation.slice().sort((a, b) => new Date(b.entryYear) - new Date(a.entryYear));
  
    const formattedStudies = sortedStudies.map(study => {
      return `
          <div>
            <strong style='color: #333;'>${study.otherCareer ? study.otherCareer.toUpperCase() : ''}</strong>
            ${study.minor ? `<span style='color: #666;'> - ${study.minor.toUpperCase()}</span>` : ''}</br>
            <span style='color: #666;'>${study.otherInstitution || ''}</span></br>
            <span style='color: #666;'>${study.statusName || ''}</span></br>
            <span style='color: #666;'>${study.entryYear} ~ ${study.graduationyear || 'Presente'}</span></br>
            <hr style='border: 0.2vh solid var(--mid-main);'/>
          </div>
      `.trim();
    }).join('</br>');
  
    return `${title}${formattedStudies}`;
  };
  const FormatPersonalData = ( PD ) => {

    const isValidURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch (_) {
        return false;
      }
    };

    const [day, month, year] = PD.personalInfo.birthDate  ? PD.personalInfo.birthDate.split('/').map(Number) 
                                                          : [null, null, null];

    const birthdate = new Date(year, month - 1, day);
    const today = new Date();
  
    let age = PD.personalInfo.birthDate   ? today.getFullYear() - birthdate.getFullYear()
                                          : null;
    const monthDiff = PD.personalInfo.birthDate ? today.getMonth() - birthdate.getMonth()
                                                : null;
  
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }
  
    return `
      <hr style='border: 0.2vh solid var(--mid-main);'/>
      <strong>${PD.personalInfo.firstName} ${PD.personalInfo.lastName} ${age ? ` |  ${age} años` : '' }</strong> 
      <hr style='border: 0.2vh solid var(--mid-main);'/>
      ${PD.presentation || 'Sin Descripción'}
      <hr style='border: 0.2vh solid var(--mid-main);'/>
      Facebook: 
      ${PD.socialNetworks.facebookLink || ' - - -'} 
      Twitter: 
      ${PD.socialNetworks.twitterLink || ' - - -'} 
      LinkedIn: 
      ${isValidURL(PD.socialNetworks.linkedinLink)  
        ? `<a href='${PD.socialNetworks.linkedinLink.trim()}' target='_blank' rel='noopener noreferrer'>${PD.personalInfo.firstName} ${PD.personalInfo.lastName}</a>` 
        : ' - - -'}
      <hr style='border: 0.2vh solid var(--mid-main);'/>
      🏠 
      ${PD.personalInfo.communeName} 
      ${PD.personalInfo.regionName 
        ? (PD.personalInfo.regionName.includes('Región') 
            ? PD.personalInfo.regionName 
            : 'Region ' + PD.personalInfo.regionName) 
        : ''} 
      ${PD.personalInfo.residenceCountry ? `${PD.personalInfo.residenceCountry.description}` : ''}
    
      ☎️ 
      <input
        type='text'
        style='border: 0.3vh solid var(--main); padding: 0.5vh; border-radius: 0.5vh; width: 80%;'
        placeholder='ingrese un telefono'
        value='${SelectedNumber || ''}'
        oninput='handlePhoneUpdate(event)'
      >

      ✉️ 
      <input
        type='text'
        style='border: 0.3vh solid var(--main); padding: 0.5vh; border-radius: 0.5vh; width: 80%;'
        placeholder='ingrese un E-mail'
        value='${SelectedMail || ''}'
        oninput='handleEmailUpdate(event)'
      >
      </input>
      <hr style='border: 0.2vh solid var(--mid-main);'/>
    `.trim();
  };
  const FormatQuestions = ( questions ) => {
    let formatted = '';

    if (questions) {
      Object.keys(questions).forEach(key => {
        if (key.includes('question')) {
          const answerKey = key.replace('question', 'answer');
          const cleanQuestion = questions[key].replace(/&#34;/g, '"').trim(); 
          const answer = questions[answerKey] ? questions[answerKey] : '';
          formatted += `<div style='margin-top: 20px;'>`; 
          formatted += `<strong style='color: #333;'>${cleanQuestion}</strong>`; 
          formatted += `<p style='color: #666; margin-top: 0.5vh'>${answer}</p>`; 
          formatted += `<hr style='border: 1px solid #ccc;'/>`; 
          formatted += `</div>`;
          }
      });
      
      return formatted;
    } else {
      return '<h2> No hay respuestas disponibles </h2>';
    }
  };


  // Filtros
  const [FilterQuery, setFilterQuery] = useState('');
  const HandleFilterChange = (e) => {
    const query = e.target.value.toLowerCase();
    setFilterQuery(query);
  };
  
  // Navegacion
  const goto01 = () => { navigate('/') };
  const goto08 = () => { navigate('/P_Formulario') };


  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo
      });
      localStorage.removeItem('fetched');
      setFetchedData(data)
      if (data.CVData){
        setCVlist(data.CVData.reverse());
        setSelectList(Array.isArray(data.SelectList)  ? data.SelectList        
                                                      : data.SelectList ? [data.SelectList]        
                                                                        : []);
        setSelectList2(Array.isArray(data.SelectList2)  ? data.SelectList2       
                                                        : data.SelectList2  ? [data.SelectList]        
                                                                            : []);
        setSelectList3(Array.isArray(data.SelectList3)  ? data.SelectList3        
                                                        : data.SelectList3  ? [data.SelectList3]        
                                                                            : []);                                                                        
      }
  
      if (data.JobId && Array.isArray(data.JobId)){
        setIDlist(data.JobId);
        data.JobId.forEach(id => {
          HandleCandidatos(id);
        });
      } else if (data.JobId && !Array.isArray(data.JobId)) {
        setIDlist([data.JobId])
        HandleCandidatos(data.JobId);
      }
    };
  
    fetchData();

    window.handleEmailUpdate = (event) => {
      setSelectedMail(event.target.value);
    };

    window.handlePhoneUpdate = (event) => {
      setSelectedNumber(event.target.value);
    };

    // eslint-disable-next-line
  }, []); 
  

  //############################################################################################################
  //############################################################################################################

  return (
    <div className='BackGround'>

      {/* Popup Envio Mails Rechazo Masivo */}
      <div className={ShowPopup? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup3(false)} >
        </div>
        <div className={ShowPopup? 'warningbox' : 'hidden'}>
          <h2>Confirme a quienes se les enviara el Mensaje de rechazo</h2>
          <div style={{border:'none', margin:'1vh'}} className='notas'>
            {CVlist
            .filter(reply => !SelectList.includes(reply.name))
                   .filter(reply => !reply.Rejected)
                   .map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', width: '-webkit-fill-available', alignItems: 'center', marginBottom: '0.5vh' }}>
                <button
                  className={!SelectedCandidates.includes(reply.name) ? 'start-button red' : 
                                                                        'start-button'}
                  onClick={() => {
                    ToggleCandidateSelection(reply.name);
                  }}
                  style={{height:'5vh'}}
                >
                  {reply.name}
                </button>
                <input  type='checkbox' 
                        className='select-checkbox'
                        style={{height:'5vh'}}
                        checked={!SelectedCandidates.includes(reply.name) && !reply.Rejected}
                        onChange={() => {
                          ToggleCandidateSelection(reply.name);
                        }}></input>
              </div>
            ))}
          </div>

          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>           
            <button onClick={() => {
                const allCandidates = CVlist
                  .filter(reply => SelectList.includes(reply.name))
                  .filter(reply => !reply.Rejected)
                  .map(reply => reply.name);
                
                setSelectedCandidates(allCandidates);
                console.log(SelectedCandidates)
              }}> 
              Marcar Todos 
            </button> 
            <button onClick={() => {
                const allCandidates = CVlist
                  .filter(reply => !SelectList.includes(reply.name))
                  .filter(reply => !reply.Rejected)
                  .map(reply => reply.name);
                
                  const uniqueCandidates = [...new Set([...SelectedCandidates, ...allCandidates])];
    
                  setSelectedCandidates(uniqueCandidates);
                console.log(SelectedCandidates)
              }}> 
              Desmarcar Todos 
            </button>
          </div>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => HandleMailAll( )}> <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Enviar Notificaciones </button>
            <button onClick={() => setShowPopup3(false)}> Cancelar</button>
          </div>

        </div>
      </div>  

      {/* Type Header */}
      <div className='typing-effect-container'>
        <h2 className='typing-effect'>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        👍 Hey, revisemos los candidatos que he reclutado para tu proceso y mi recomendación! 👍 
        </h2> 
      </div>

      <div className='MainBody'>

        {/* Lista de candidatos */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{ maxWidth: '20vw', marginRight:'1vh' }}>
          
          {/* Botones superiores */}
          <div style={{display:'flex'}}>

            {/* Contador Candidatos */}
            <h3 className='boxtitle'>Candidatos : { State ? CVlist.filter(cv => cv.id !== 'hidden').length 
                                                          : CVlist.filter(cv => cv.id !== 'hidden').filter(cv => !cv.Rejected).length }</h3>

            {/* Sort */}
            <button
              className='sortbutton On'
              onClick={() => {
                if (SortType === 'score') {
                  setSortType('stars'); 
                } else if (SortType === 'stars') {
                  setSortType('name');  
                } else if (SortType === 'name') {
                  setSortType('chrono'); 
                } else {
                  setSortType('score');
                }
              }}
              style={{ borderRadius: '1vh 3vh 0vh 0vh' }}>
              {SortType === 'score' && (
                <>
                  <i className="fa-solid fa-percent"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'stars' && (
                <>
                  <i className="fa-regular fa-star"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'name' && (
                <>
                  <i className='fa-solid fa-arrow-down-a-z'></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'chrono' && (
                <>
                  <i className="fa-regular fa-clock"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
            </button>

          </div>

          {/* Menu interno */}
          <div style={{ position:'sticky', 
                        border:'0.3vh solid var(--main)',
                        borderRadius:'0vh 1vh 0vh 0vh',
                        backgroundColor:'var(--light)',
                        top: 0, zIndex: 4, 
                        padding:'1vh'}} >
              <div style={{display:'flex', flexDirection:'row', gap:'1vh'}}>
                <button onClick={HandleIDFetch}>
                  <i className="fa-solid fa-plus"></i> &nbsp; Agrega un ID
                </button>
                <button onClick={HandleButtonClick}> 
                  <i className='fa-solid fa-file-import'></i> &nbsp; Importa un CV 
                </button>
              </div>
              <button style={{marginTop:'1vh'}}
                      className='redb'
                      onClick={OpenRejectionPopup}>
                <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp;  Notifica a los no seleccionados 
              </button>
          </div>

          {/* Filtro de candidatos */}
          <input
            style={{  position:'sticky', 
                      width:'-webkit-fill-available',
                      height:'2vh',
                      border:'0.3vh solid var(--main)',
                      borderTop:'none',
                      borderRadius:'0vh',
                      backgroundColor:'var(--light)',
                      top: 0, 
                      zIndex: 4, 
                      padding:'1vh'}}
            type='text'
            placeholder='Filtrar por nombre'
            className='filterinput'
            value={FilterQuery}
            onChange={HandleFilterChange}></input>

          {/* Lista de candidatos */}
          <div className='notas' style={{borderTop:'none', borderRadius:'0vh 0vh 1vh 1vh', marginBottom:'19.5vh'}}>
            <input 
              type='file' 
              className='hidden'
              ref={FileInputRef} 
              onChange={HandleFileChange} 
              multiple
            />
            {CVlist && CVlist.length > 0 ? (
              SortCVlist().filter(reply => reply.id !== 'hidden')
                          .filter(reply => reply.Rejected === State || !reply.Rejected)
                          .filter(reply => reply.name.toLowerCase().includes(FilterQuery))
                          .map((reply, index) => (
                <div key={reply.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '1vh', marginLeft:'-1vh' }}>
                  <button
                    style={{  borderColor: reply.Rejected ? 'gray' : '', 
                              backgroundColor: reply.Rejected ? 'var(--gray)' : '', 
                              marginLeft:'1vh' }}
                    className={ reply.name === SelectedCVName  ?  'start-button highlighted' : 
                                                reply.new?      'start-button green' : 
                                                                'start-button'}
                    onClick={() => {
                      setCV('')
                      LookCV(reply.id);
                      setSelectedCVName(reply.name);
                      setSelectedMail(reply.mail);
                      setSelectedNumber(reply.phone);
                      setSelectQuest(reply.questions);
                      setScore(reply.stars);
                      setTimeout(() => {
                        setWait(false);
                      }, 500);
                    }}
                  >
                    {reply.name}
                  </button>
                  <button className={ !reply.Nota ? 'middle-button' : 
                                                    JSON.parse(reply.Nota) < 50 ? 'middle-button red' : 
                                                                                  JSON.parse(reply.Nota) < 70 ? 'middle-button yellow' : 
                                                                                                                'middle-button green'}
                          style={{borderColor: reply.Rejected ? 'gray' : '',
                                  borderRight:'none',
                                  backgroundColor: reply.Rejected ? 'var(--gray)' : '', 
                                  maxWidth:'3vw'}}
                          onClick={() => {
                            setWait(true);
                            setSelectedCVName(reply.name);
                            setSelectQuest(reply.questions);
                            setScore(reply.stars);
                            LookCV(reply.id).then(CV => {
                              HandleReneeopinion(CV, reply.name).then(nota => {
                                if (reply.RUT){
                                  SaveCV( sessionStorage.currentsave, reply.name, reply.mail, reply.id, reply.JID, reply.RUT, nota);
                                  setTimeout(() => {
                                    setWait(false);
                                  }, 500);
                                } else {
                                  SaveCV( sessionStorage.currentsave, reply.name, reply.mail, reply.id, reply.JID, '', nota);
                                  setTimeout(() => {
                                    setWait(false);
                                  }, 500);
                                }
                              });
                            })
                          }}>
                    {reply.Nota ? JSON.parse(reply.Nota) : ' - '}{'%'}
                  </button>
                  <button className={ !reply.stars ? 'middle-button' : 
                                                    JSON.parse(reply.stars) < 3 ? 'middle-button red' : 
                                                                                  JSON.parse(reply.stars) < 5 ? 'middle-button yellow' : 
                                                                                                                'middle-button green'}
                          style={{borderColor: reply.Rejected ? 'gray' : '',
                                  borderRight:'none',
                                  backgroundColor: reply.Rejected ? 'var(--gray)' : '', 
                                  maxWidth:'3vw'}}>
                    {reply.stars ? JSON.parse(reply.stars) : ' - '}{'☆'}
                  </button>
                  <input  type='checkbox' 
                          className='select-checkbox'
                          style={{borderColor: reply.Rejected ? 'gray' : '',
                                  backgroundColor: reply.Rejected ? 'var(--gray)' : '', 
                          }}
                          checked={SelectList ? SelectList.includes(reply.name) : false}
                          onChange={() => {
                            HandleSelect(reply.name);
                          }}></input>
                </div>
              ))
            ) : (
              <button className='PublicButoff'>No hay candidatos</button>
            )}
          </div>

        </div>

        {/* Perfil del Candidato */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{ maxWidth: '20vw', marginBottom:'-0.4vh', marginRight:'1vh' }}>
          <h3 className='boxtitle'>Perfil personal</h3>
          <div className='notas' ref={cvContainerRef}>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
              { Wait ? (
                <div>
                  <img
                  src='/default.png'
                  alt='Profile'
                  style={{
                    width: '150px',
                    height: '150px',
                    objectFit: 'cover',
                    borderRadius: '50%',
                    position:'stick'
                  }}/>
                  <h3 style={{color:'var(--main)'}}> Espera un momento </h3>
                </div>
              ) : CV ? (
                <div>
                  <img
                    className={CV ? '' : 'hidden'}
                    src={CV && CV.personalInfo.picture ? CV.personalInfo.picture : '/default.png'}
                    alt='Profile'
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                      borderRadius: '50%',
                      position:'stick'
                    }}/>
                  <div className={CV ? '' : 'hidden'} style={{marginTop:'1vh', marginBottom:'1vh'}}>
                    <StarRating initialRating={Score} onRatingChange={HandleRatingChange} />
                  </div>
                  {/* <button className={CV ? sessionStorage.rol === 'Admin' ? '' : 'hidden' : 'hidden'} style={{marginBottom:'0.5vh'}}
                          onClick={() => {const Name = CV.personalInfo.firstName + ' ' + CV.personalInfo.lastName;
                                          SaveCV( sessionStorage.currentsave, Name, CV.personalInfo.emails.primaryEmail);
                                          window.alert('Mail Reimportado:  \n' + CV.personalInfo.emails.primaryEmail)
                                          }}>
                            Reimportar mail</button> */}
                  <details style={{width:'-webkit-fill-available', marginBottom:'1vh'}}>
                    <summary className={CV ? '' : 'hidden'} style={{marginBottom:'0.5vh'}}> <i className='fa-solid fa-file-export'></i> &nbsp; Exportar CV</summary>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                      <button style={{marginBottom:'0.5vh', width:'80%'}} className={SelectedCVName ? '' : 'hidden'} onClick={() => ExportToPDFCV( false )}>
                        CV Completo Reneé</button>
                      <button style={{marginBottom:'0.5vh', width:'80%'}} className={SelectedCVName ? '' : 'hidden'} onClick={() => ExportToPDFCV( true )}>
                        CV Ciego Reneé</button>
                      {CV?.multipleDocuments?.dataDocument?.some(doc => doc.documentTypeName === 'Currículum adicional') && (
                      <button style={{marginBottom:'0.5vh', width:'80%'}} onClick={HandleCVpropio}>
                        CV propio</button>)}
                    </div>
                  </details>

                  <div
                    dangerouslySetInnerHTML={{ __html: CV ? FormatPersonalData(CV) : '' }}
                    style={{
                      whiteSpace: 'pre-wrap',
                      textAlign:'start'
                    }}/>
                  <button onClick={UpdateData}> Guardar Cambios </button>
                </div>
              ) : (
                <div>
                  <h2
                  className={CV ? 'hidden' : ''}> 
                  Inicia seleccionando un candidato 
                  {/* eslint-disable-next-line */}
                  <p style={{fontSize:'4rem'}}>👈</p>
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Detalles del Perfil */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{marginBottom:'-0.4vh' }}>
          <div style={{display:'flex'}}>
            <h3 className='boxtitle'>{ScreenType === 'perfil' ? 'Perfil del candidato' : 'Preguntas Introductorias'}</h3>
            <button className='sortbutton On'
                    onClick={() => setScreentype(ScreenType === 'perfil' ? 'preguntas' : 'perfil')}
                    style = {{ textAlign:'left' , padding:'1vh', borderRadius:'1vh 3vh 0vh 0vh' }}
                    >
              {ScreenType === 'perfil' ? ' ir a Preguntas Introductorias' : ' ir a Perfil del candidato'}
            </button>
          </div>
          { Wait === true ? (
              <div className='notas'>
                <div className='box'>
                  <img
                    src={'/favicon.png'}
                    alt='Profile'
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                      position:'stick'
                    }}/>
                  <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                  <div className='loader-container'>
                    <div  className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                  </div>
                </div>
              </div>

            ) : ScreenType === 'perfil' ? (
              <div
              className= 'notas'
              dangerouslySetInnerHTML={{ __html: CV ? FormatJobData(CV.workExperience) 
                                                    + '\n\n\n\n'
                                                    + FormatStudyData(CV.studies) : '' }}
              style={{
                overflowY: 'auto',
                textAlign: 'left'
              }}
              />
            ) : (
              <div
              className= 'notas'
              dangerouslySetInnerHTML={{ __html: CV ? FormatQuestions(SelectQuest) : '' }}
              style={{
                whiteSpace: 'pre-wrap',
                overflowY: 'auto',
                textAlign: 'left'
              }}
              />
            )}

        </div>

      </div>

      <div className={ Wait? 'hidden' : 'bottom'}>
        <button onClick={goto01}>volver</button>
        <button onClick={()=>setState(!State)}>ver candidatos rechazados</button>
        <button className={SelectList.length > 0 ? 'last-button' : 'hidden'} onClick={goto08}>Continuar</button>
      </div>
      
    </div>
  );
} 
