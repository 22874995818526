

//=============================================================================

import React from 'react';
import useToken from './functions/00_usetoken';
import Login from './functions/00_login';

//=============================================================================

function App() {

  const { token, setToken } = useToken();
  console.log(token);
  return <Login setToken={setToken} />

}
export default App;