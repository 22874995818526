
//=============================================================================
// Pantalla Evaluacion Psicolaboral
//=============================================================================

// Generico
import './css/App.css';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// API
import APICALL from './APICALL';
import AICALL from './AICALL';
import LOGCALL from './LOGCALL';
// Especificos
import StarRating from '../components/star';

export default function PsicoEv() {
  const navigate = useNavigate();

  const [State, setState] = useState(false);
  const [Score, setScore] = useState('0');
  const [CompName, setCompName] = useState('');
  const [SortType, setSortType] = useState('chrono');
  const [FetchedData, setFetchedData] = useState(''); 
  const [SelectedName, setSelectedName] = useState('');

  const [EvaluacionPSL, setEvaluacionPSL] = useState('');

  const [ScreenType, setScreentype] = useState('preguntas');
  const [CV, setCV] = useState(null);
  const [CVlist, setCVlist] = useState([]);
  const [SelectList, setselectlist] = useState([]);
  const [SelectList2, setselectlist2] = useState([]);
  const [SelectList3, setselectlist3] = useState([]);
  const [SelectedCandidates, setSelectedCandidates] = useState([]);
  const [Wait, setWait] = useState(false);
  const [ShowPopup2, setShowPopup2] = useState(false);
  const [SelectedCVId, setSelectedCVId] = useState(null);

  const FileInputRef                      = useRef(null);
  const [FileName, setFileName]           = useState(''); 
  const [inputKey, setInputKey]           = useState(Date.now());
  const [FileData, setFileData]           = useState('');

  const [Form, setForm] = useState([]); 
  const [FormID, setFormID] = useState('');


  // Recuperacion y exportado de formularios + Paso a 3a persona
  async function handleEPLcheck( name ) {
  
    try {
      const resp = await APICALL({
        apicall: 'PS_fetch',
        code: sessionStorage.process,
        Name: name,
        FormID: hashStringToInt(sessionStorage.process)
      });

      if (!resp.error){
        setEvaluacionPSL(resp);
      } else {
        setEvaluacionPSL('');
      }
      
    } catch (error) {
      console.error('Error during fetch:', error);
      return [];
    }

  };


  // Envio de Correos
  const ToggleCandidateRejected = (name) => {
    setSelectedCandidates(prev =>
      prev.includes(name) ? prev.filter(item => item !== name) : [...prev, name]
    );
  };
  async function HandleMailAll(state) {
  
      if (state === true) {
        const MailList = []
        for (const reply of CVlist) {
          if (SelectedCandidates.includes(reply.name) && !reply.Rejected) {
            try {
              MailList.push(await HandleMail(reply.mail, reply.name, state));  
            } catch (error) {
              console.error(`Error al enviar mensaje a ${reply.name}`);
            }
          }
        }

        MailNotiff(MailList, 'Formulario pre-screening');
        if (MailList.length > 0) {
          const formattedList = MailList.map(email => `- ${email}`).join('\n');
          MailNotiff(MailList, 'Rechazo');
          window.alert(`Formularios enviados exitosamente a los siguientes candidatos:\n\n${formattedList}`);
        } else {
            window.alert('No Se logro enviar el formulario a ningun candidato, por favor intente nuevamente');
        }

      } else {
        const MailList = []
        for (const reply of CVlist) {
          if (SelectList.includes(reply.name)){
            if (!SelectedCandidates.includes(reply.name) && !reply.Rejected) {
              try {
                MailList.push(await HandleMail(reply.mail, reply.name, state));   
                // MailList.push(reply.name)
              } catch (error) {
                console.error(`Error al enviar mensaje a ${reply.name}`);
              }
            }
          }
        }

        MailNotiff(MailList, 'Rechazo');
        if (MailList.length > 0) {
          const formattedList = MailList.map(email => `- ${email}`).join('\n');
          MailNotiff(MailList, 'Rechazo');
          window.alert(`Notificacion enviada exitosamente a los siguientes candidatos:\n\n${formattedList}`);
        } else {
            window.alert('No Se logro enviar la notificacion a ningun candidato, por favor intente nuevamente');
        }
      }

    window.location.reload();
    setShowPopup2(false);
  };
  async function HandleMail(mail, name, state) {
    let call

    if (state === true) {
      call = 'FM_SEND'
    } else {
      call = 'FM_REJ1'
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.currentsave,
        U_emp: sessionStorage.Grupo,
        CVData: { name: name, Rejected: true}
      });
    }
    try {
      await LOGCALL({
        apicall: call,
        code: sessionStorage.process,
        Name : name,
        Mail : mail,
        Title : FetchedData.Charge_title,
        E_name: CompName,
        P_name: sessionStorage.token,
        P_mail: sessionStorage.mail,
        Text : ReplaceSpecialChars(FetchedData.GPToferta)
                .replace(/\*\*(.*?)\*\*/g, '<br/><br/><strong>$1</strong><br/><br/>')
                // eslint-disable-next-line
                .replace(/\~(.*?)\~/g, '<li>$1<br/>'),
      });
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.currentsave,
        U_emp: sessionStorage.Grupo,
        CVData: { name: name, FormSent: true}
      });
      saveCV(name);
      return name;
    } catch (error) {
      console.error('Error during fetch:', error);
      return '';
    }
  };
  async function MailNotiff(list , theme) {

    try {
      await LOGCALL({
        apicall: 'FM_NOT',
        code: sessionStorage.process,
        Mail : sessionStorage.mail,
        Title : FetchedData.Charge_title,
        P_name: sessionStorage.token,
        List: list,
        Theme: theme
      });
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };
  // const copyToClipboard = (text) => {
  //   navigator.clipboard.writeText(text)
  //     .then(() => {
  //       alert('URL copiada al portapapeles!');
  //     })
  //     .catch(err => {
  //       console.error('Error al copiar al portapapeles: ', err);
  //     });
  // };
  const ReplaceSpecialChars = (text) => {
    const replacements = {
      'á': '&aacute;',
      'é': '&eacute;',
      'í': '&iacute;',
      'ó': '&oacute;',
      'ú': '&uacute;',
      'Á': '&Aacute;',
      'É': '&Eacute;',
      'Í': '&Iacute;',
      'Ó': '&Oacute;',
      'Ú': '&Uacute;',
      'ñ': '&ntilde;',
      'Ñ': '&Ntilde;',
      'ü': '&uuml;',
      'Ü': '&Uuml;',
    };
    return text.replace(/[áéíóúÁÉÍÓÚñÑüÜ]/g, match => replacements[match]);

  };


  // Evaluacion de candidatos
  const hashStringToInt = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash = hash & hash; 
    }
    // console.log(Math.abs(hash))
    return String(Math.abs(hash)); 
  };
  async function handleRatingChange (newRating) {
    setScore(newRating);
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === SelectedCVId
          ? { ...cv, stars3: newRating} 
          : cv
      )
    );
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo,
        CVData: { name: SelectedCVId, stars3: newRating}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }

  };
  async function saveCV( Namedata , score ) {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.currentsave,
        U_emp: sessionStorage.Grupo,
        CVData: { name: Namedata, Nota2: score}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };
  async function EvaluatePSL() {
    
    setWait(true);

    const instruction = 'EL Perfil: ' + sessionStorage.currentprofile + '. \n El Formulario: ';
  
    try {
      const consulta = await AICALL({
        apicall: '11',
        data: instruction + FileData
      });
  
  
      try {
        
        await APICALL({
          apicall: 'PS_submmit',
          FormID: hashStringToInt(sessionStorage.process),
          Name: SelectedName,
          content: consulta.reply
        });

      } catch (parseError) {
        console.error('Error al parsear JSON:', parseError);
        console.log('Contenido que causó el error:', consulta.reply);
        throw parseError;
      }
    } catch (error) {
      console.error('Error durante la operación:', error);
      throw error; 
    }

    handleEPLcheck(SelectedName);
    setTimeout(() => {
      setWait(false);
    }, 500);
  };


  // Seleccion de candidatos
  const SortCVlist = () => {
    const sortedList = [...CVlist].sort((a, b) => {
      if (SortType === 'score') {
        const notaA = a.Nota2 ? JSON.parse(a.Nota2) : 0;
        const notaB = b.Nota2 ? JSON.parse(b.Nota2) : 0;
        return notaB - notaA; 
      } else if (SortType === 'stars') {
        const starsA = a.stars3 ? a.stars3 : 0;
        const starsB = b.stars3 ? b.stars3 : 0;
        return starsB - starsA; 
      } else {
        return a.name.localeCompare(b.name); 
      }
    });

    if (SortType === 'chrono') {
      return CVlist;
    } else {
      return sortedList;
    }
  };
  async function Handleselect2(name) {
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name  ? { ...cv, select2: !cv.select2} 
                          : cv
      )
    );
  
    const updatedSelectList2 = SelectList2.includes(name) ? SelectList2.filter(id => id !== name)
                                                          : [...SelectList2, name];
    setselectlist2(updatedSelectList2);

    const updatedSelectList3 = SelectList3.includes(name) ? SelectList3.filter(id => id !== name)
                                                          : [...SelectList3];
    setselectlist3(updatedSelectList3); 
  
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo,
        SelectList2: updatedSelectList2,
        SelectList3: updatedSelectList3
      });

    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };


  // Datos del cadidato
  async function LookCV ( data ) {

    const apiCallType = data.includes('CVE_') ? 'CV_fetch' : 'TB_fetch';


    try {
      const reply = await APICALL({
        apicall: apiCallType,
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo,
        id_code: data
      });

      function replaceNullStrings(obj) {
        for (const key in obj) {
          if (obj[key] === 'Null' || obj[key] === null) {
            obj[key] = ' - - -';
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            replaceNullStrings(obj[key]);
          }
        }
        return obj;
      }
      
      let profile 

      if (apiCallType === 'CV_fetch'){
        profile = replaceNullStrings(reply.data)
      } else {
        profile = reply.data
      }

      const { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments } = profile;
      const cv = { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments };

      setCV(cv);
  
    } catch (error) {
      console.error('Error during fetch:', error);
      setCV(null)
    }

  };


  // Funciones de archivos
  const HandleFile = () => {
    FileInputRef.current.click();
  };
  async function HandleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name); 
    } else {
      return
    }
  
    const fileMimeType = file.type; 
    console.log('File received:', file);
  
    if (fileMimeType === 'application/pdf' || fileMimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ) {
      const data = new FormData();
      data.append('file', file);
      data.append('apicall', 'EX');
  
      try {
        const reply = await AICALL(data);
        setFileData(reply.reply)
      } catch (error) {
        console.error('Error during PDF processing:', error);
      }
  
    } else {
      DropContent()
      window.alert('Formato no soportado. Actualmente solo se aceptan los formatos:\n * .Pdf\n *.Docx\n *.Txt');
    }
  };
  async function DropContent() {
    setInputKey(Date.now());
    setFileName('');
  };
  

  // Formato de datos
  const FormatEvaluationData = (evaluationData) => {
    const title = `<h2>Evaluación de Candidato: ${SelectedName}</h2>\n\n`;
  
    if (!evaluationData || typeof evaluationData !== 'object') {
      return '<h2>No hay datos de evaluación disponibles</h2>';
    }
  
    const disclaimer = ` Este documento es para evaluación interna `;
  
    const formatTable = (fortalezas, debilidades) => {
      return `
        <table border="1" cellspacing="0" cellpadding="5" style="width: 100%; border-collapse: collapse; text-align: left;">
          <tr>
            <th style='padding: 1.5vh'>Fortalezas</th>
            <th style='padding: 1.5vh'>Debilidades</th>
          </tr>
          <tr>
            <td style="vertical-align: top; padding: 1vh;">${fortalezas.map(item => `● ${item}`).join('<br/>')}</td>
            <td style="vertical-align: top; padding: 1vh;">${debilidades.map(item => `● ${item}`).join('<br/>')}</td>
          </tr>
        </table>
        <br/>
      `;
    };
  
    const methodologySection = evaluationData.Metodologia_de_evaluación ? `
      <h2>Metodología de Evaluación</h2>
      <ul>
        ${Object.entries(evaluationData.Metodologia_de_evaluación).map(([key, value]) => `<li><strong>${key}:</strong> ${value}</li>`).join('')}
      </ul>
      <hr style='border: 1px solid var(--main); width: -webkit-fill-available '/>
    ` : '';
  
    const generalSummary = evaluationData.Resumen_General ? `
      <h2>Resumen General</h2>
      ${formatTable(evaluationData.Resumen_General.Fortalezas || [], evaluationData.Resumen_General.Debilidades || [])}
      <hr style='border: 1px solid var(--main); width: -webkit-fill-available '/>
    ` : '';
  
    const personalityEvaluation = evaluationData.Evaluacion_de_Personalidad ? `
      <h2>Evaluación de Aspectos de Personalidad</h2>
      <p>${evaluationData.Evaluacion_de_Personalidad.Descripcion}</p>
      <hr style='border: 1px solid var(--main); width: -webkit-fill-available '/>
    ` : '';
  
    const competencies = evaluationData.Evaluacion_por_Competencias ? Object.entries(evaluationData.Evaluacion_por_Competencias).map(([key, value]) => {
      return `
        <strong>${key.replace(/_/g, ' ')}:</strong> ${value.Nivel || 'No especificado'}<br/>
        <em style='margin-left:1vh'>${value.Comentario || 'Sin comentarios'}</em><br/>
      `;
    }).join('<br/>') : '';
  
    const competenciesSection = competencies ? `
      <h2>Evaluación por Competencias y Habilidades Específicas</h2>
      ${competencies}
      <hr style='border: 1px solid var(--main); width: -webkit-fill-available '/>
    ` : '';
  
    const fitSection = evaluationData.Fit_con_el_Cargo ? `
      <h2>Fit con el Cargo</h2>
      <strong>Ajuste al perfil:</strong>
      <p style='margin-left:1vh'>${evaluationData.Fit_con_el_Cargo.Comentario || 'Sin comentario'}</p>
    ` : '';
  
    const considerationsSection = evaluationData.Aspectos_a_Considerar ? `
      <h3>Aspectos a Considerar</h3>
      ${evaluationData.Aspectos_a_Considerar.map(item => `● ${item}`).join('<br/>')}
    ` : '';
  
    const onboardingSection = evaluationData.Onboarding_Recomendado ? `
      <h3>Onboarding Recomendado</h3>
      ${evaluationData.Onboarding_Recomendado.map(item => `● ${item}`).join('<br/>')}
      <br/>
      <br/>
      <hr style='border: 1px solid var(--main); width: -webkit-fill-available '/>
    ` : '';
  
    const conclusion = evaluationData.Conclusion ? `
      <br/>
      <div style="border: 0.3vh solid var(--main); padding: 0vh 2vh 2vh 2vh; border-radius: 8px; background-color: #f9f9f9;">
        <h2>Recomendación:</h2>
        <strong>${evaluationData.Conclusion.Recomendacion || 'No especificado'}</strong><br/><br/>
        <em style='margin-left:1vh'>${evaluationData.Conclusion.Justificacion || 'Sin justificación'}</em>
      </div>
      <br/>
      <hr style='border: 1px solid var(--main); width: -webkit-fill-available '/>
    ` : '';
  
    return { __html: `${title}${disclaimer}${methodologySection}${generalSummary}${personalityEvaluation}${competenciesSection}${fitSection}${considerationsSection}${onboardingSection}${conclusion}`.trim() };
  };
  const FormatJobData = (jobData) => {
    const title = `<h2 style='color: var(--light); background: var(--main); padding: 1vh'>EXPERIENCIA LABORAL</h2>\n\n`;
  
    if (!jobData || !Array.isArray(jobData) || jobData.length === 0) {
      return `${title}<h2 style='padding-left: 2vh'>No hay experiencia laboral disponible</h2><br/>`;
    }
  
    const parseDate = (dateStr) => {
      if (!dateStr) return new Date(0);
  
      const parts = dateStr.split('/').map(Number);
  
      if (parts.length === 3) {
        const [day, month, year] = parts;
        return new Date(year, month - 1, day);
      } else if (parts.length === 2) {
        const [month, year] = parts;
        return new Date(year, month - 1, 1);
      } else if (parts.length === 1 && parts[0] >= 1000) {
        const year = parts[0];
        return new Date(year, 0, 1);
      }
  
      return new Date(0); 
    };
  
    const sortedJobs = jobData.slice().sort((a, b) => {
      return parseDate(b.fromDate) - parseDate(a.fromDate);
    });
  
    const formattedJobs = sortedJobs.map(job => {
      const achievements = job.achievements
        ? (job.achievements.includes('\n?') 
          ? job.achievements.split('\n?').filter(line => line.trim() !== '') 
          : job.achievements.split('\n').filter(line => line.trim() !== ''))
        : [];
  
      const cleanedAchievements = achievements.map(achievement => 
        achievement.replace(/^[*•-]\s*/, '').trim()
      );
  
      const formattedAchievements = cleanedAchievements.length > 0 
        ? cleanedAchievements.map(achievement => `● ${achievement}`).join('</br>')
        : 'Sin logros especificados';
  
      return `
          <div>
            <strong style='color: #333;'>${job.jobPosition ? job.jobPosition.toUpperCase() : 'Sin puesto especificado'}</strong> </br>
            <span style='color: #666;'>${job.companyName || 'Empresa desconocida'} || ${job.companyActivity ? job.companyActivity.description : 'Actividad no especificada'}</span></br>
            <span style='color: #666;'>${job.fromDate || 'Fecha desconocida'} - ${job.toDate || 'Presente'}</span></br>
            <p style='color: #666;'>${formattedAchievements}</p>
            <hr style='border: 0.2vh solid var(--mid-main);'/>
          </div>
      `.trim();
    }).join('</br>');
    
    return `${title}${formattedJobs}`;
  };
  const FormatStudyData = (studyData) => {
    const title = `<h2 style='color: var(--light); background: var(--main); padding: 1vh'>FORMACIÓN ACADÉMICA</h2>\n\n`;
  
    if (
      !studyData || 
      !studyData.higherEducation || // Verifica si higherEducation existe
      !Array.isArray(studyData.higherEducation) // Verifica si es un arreglo
    ) {
      return `${title}<h2 style='padding-left: 2vh'>No hay formación académica disponible</h2><br/>`;
    }
    
  
    const sortedStudies = studyData.higherEducation.slice().sort((a, b) => new Date(b.entryYear) - new Date(a.entryYear));
  
    const formattedStudies = sortedStudies.map(study => {
      return `
          <div>
            <strong style='color: #333;'>${study.otherCareer ? study.otherCareer.toUpperCase() : ''}</strong>
            ${study.minor ? `<span style='color: #666;'> - ${study.minor.toUpperCase()}</span>` : ''}</br>
            <span style='color: #666;'>${study.otherInstitution || ''}</span></br>
            <span style='color: #666;'>${study.statusName || ''}</span></br>
            <span style='color: #666;'>${study.entryYear} ~ ${study.graduationyear || 'Presente'}</span></br>
            <hr style='border: 0.2vh solid var(--mid-main);'/>
          </div>
      `.trim();
    }).join('</br>');
  
    return `${title}${formattedStudies}`;
  };
  const FormatPersonalData = ( PD ) => {

    const isValidURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch (_) {
        return false;
      }
    };

    const [day, month, year] = PD.personalInfo.birthDate  ? PD.personalInfo.birthDate.split('/').map(Number) 
                                                          : [null, null, null];

    const birthdate = new Date(year, month - 1, day);
    const today = new Date();
  
    let age = PD.personalInfo.birthDate   ? today.getFullYear() - birthdate.getFullYear()
                                          : null;
    const monthDiff = PD.personalInfo.birthDate ? today.getMonth() - birthdate.getMonth()
                                                : null;
  
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }
  
    return `
      <hr style='border: 0.2vh solid var(--mid-main);'/>
      <strong>${PD.personalInfo.firstName} ${PD.personalInfo.lastName} ${age ? ` |  ${age} años` : '' }</strong> 
      <hr style='border: 0.2vh solid var(--mid-main);'/>
      ${PD.presentation || 'Sin Descripción'}
      <hr style='border: 0.2vh solid var(--mid-main);'/>
      Facebook: 
      ${PD.socialNetworks.facebookLink || ' - - -'} 
      Twitter: 
      ${PD.socialNetworks.twitterLink || ' - - -'} 
      LinkedIn: 
      ${isValidURL(PD.socialNetworks.linkedinLink)  
        ? `<a href='${PD.socialNetworks.linkedinLink.trim()}' target='_blank' rel='noopener noreferrer'>${PD.personalInfo.firstName} ${PD.personalInfo.lastName}</a>` 
        : ' - - -'}
      <hr style='border: 0.2vh solid var(--mid-main);'/>
      🏠 
      ${PD.personalInfo.communeName} 
      ${PD.personalInfo.regionName 
        ? (PD.personalInfo.regionName.includes('Región') 
            ? PD.personalInfo.regionName 
            : 'Region ' + PD.personalInfo.regionName) 
        : ''} 
      ${PD.personalInfo.residenceCountry ? `${PD.personalInfo.residenceCountry.description}` : ''}
    
      ☎️ 
      ${PD.personalInfo.phoneNumbers[0].number || 'N/A'}

      ✉️ 
      ${PD.personalInfo.emails.primaryEmail || 'N/A'}
      <hr style='border: 0.2vh solid var(--mid-main);'/>
    `.trim();
  };

  // Funciones de pop-up
  const OpenRejectionPopup = () => {
    setSelectedCandidates(SelectList2);
    setShowPopup2(true);
  };


  // Filtros
  const [FilterQuery, setFilterQuery] = useState('');
  const HandleFilterChange = (e) => {
    const query = e.target.value.toLowerCase();
    setFilterQuery(query);
  };


  // Check formulario pre-screening
  async function handleformcheck(name, check) {
  
    try {
      const resp = await APICALL({
        apicall: 'FM_fetch',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo,
        FormID: FormID+'_'+name
      });
      if (resp.length > 0) {
        // if (check !== true) {
        //   console.log('consulta a IA')
        //   try {
        //     const resp2 = await AICALL({
        //       apicall: '08',
        //       name: name,
        //       data: JSON.stringify(resp)
        //     });
        //     const CleanReply = resp2.reply.replace(/^```json|```$/g, "").trim();
        //     setForm(JSON.parse(CleanReply));
        //     saveUpdate(name);
        //     SaveForm(JSON.parse(CleanReply) , name );
        //   } catch (error) {
        //     console.error('Error during fetch:', error);
        //     return [];
        //   }
        // } else {
        //   console.log('consulta a DB')
        //   setForm(resp);
        // }
        setForm(resp);
        return resp;
      } else {
        setForm(resp);
        return [];
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      return [];
    }

  };


  // Navegacion
  const goto08 = () => { navigate('/P_Respuestas') };  
  const goto10 = () => { navigate('/P_Referencias') };  


  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo
      });

      setFetchedData(data)
      setCompName(data.E_name)
      setFormID(data.FormID)
      localStorage.removeItem('fetched');
      localStorage['fetched'] = data;

      if (data.CVData){
        setCVlist(data.CVData.reverse())
        setselectlist(Array.isArray(data.SelectList)  ? data.SelectList               
                                                      : data.SelectList ? [data.SelectList]         
                                                                        : []);
        setselectlist2(Array.isArray(data.SelectList2)  ? data.SelectList2               
                                                        : data.SelectList2  ? [data.SelectList2]         
                                                                            : []);
        setselectlist3(Array.isArray(data.SelectList3)  ? data.SelectList3               
                                                        : data.SelectList3  ? [data.SelectList3]         
                                                                            : []);                                                                          
      }
    };
  
    fetchData();
    // eslint-disable-next-line
  }, []);

  
  //############################################################################################################
  //############################################################################################################

  return (

    <div className='BackGround'>

      {/* Popup Envio Mails Rechazo Masivo */}
      <div className={ShowPopup2? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup2(false)} >
        </div>
        <div className={ShowPopup2? 'warningbox' : 'hidden'}>
          <h2>Confirma a quienes se les enviara el Mensaje de rechazo</h2>
          <div style={{border:'none', margin:'1vh'}} className='notas'>
            {CVlist.filter(reply => SelectList.includes(reply.name))
                   .filter(reply => !SelectList2.includes(reply.name))
                   .filter(reply => !reply.Rejected)
                   .map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', width: '-webkit-fill-available', alignItems: 'center', marginBottom: '10px' }}>
                <button
                  className={!SelectedCandidates.includes(reply.name) ? 'start-button red' : 
                                                                        'start-button'}
                  style={{height:'5vh'}}
                  onClick={() => {
                    ToggleCandidateRejected(reply.name);
                  }}
                >
                  {reply.name}
                </button>
                <input  type='checkbox' 
                        className='select-checkbox'
                        style={{height:'5vh'}}
                        checked={!SelectedCandidates.includes(reply.name) && !reply.Rejected}
                        onChange={() => {
                          ToggleCandidateRejected(reply.name);
                        }}></input>
              </div>
            ))}
          </div>
          
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>           
            <button onClick={() => {
                const allCandidates = CVlist
                  .filter(reply => SelectList.includes(reply.name))
                  .filter(reply => SelectList2.includes(reply.name))
                  .filter(reply => !reply.Rejected)
                  .map(reply => reply.name);
                
                setSelectedCandidates(allCandidates);
                console.log('Seleccionados: ' + SelectedCandidates)
              }}> 
              Marcar Todos 
            </button> 
            <button onClick={() => {
                const allCandidates = CVlist
                  .filter(reply => SelectList.includes(reply.name))
                  .filter(reply => !SelectList2.includes(reply.name))
                  .filter(reply => !reply.Rejected)
                  .map(reply => reply.name);
                
                  const uniqueCandidates = [...new Set([...SelectedCandidates, ...allCandidates])];
    
                  setSelectedCandidates(uniqueCandidates);
                  console.log('Seleccionados: ' + SelectedCandidates)
              }}> 
              Desmarcar Todos 
            </button>
          </div>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => HandleMailAll(false)}> <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Enviar Notificacion </button>
            <button onClick={() => setShowPopup2(false)}> Cancelar</button>
          </div>

        </div>
      </div>  

      {/* Main */}
      <div className='typing-effect-container'>
        <h2 className='typing-effect'>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          💡 Evaluemos ahora las capacidades Psicolaborales de tus candidatos! 💡
        </h2>
      </div>

      <div className='MainBody'>

        {/* Lista de candidatos */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{ maxWidth: '20vw', marginRight:'1vh'}}>

          {/* bottones superiores */}
          <div style={{display:'flex'}}>
          <h3 className='boxtitle'>Candidatos : { State ? CVlist.filter(cv => cv.id !== 'hidden')
                                                                .filter(cv => SelectList2.includes(cv.name)).length 
                                                        : CVlist.filter(cv => cv.id !== 'hidden')
                                                                .filter(cv => !cv.Rejected)
                                                                .filter(cv => SelectList2.includes(cv.name)).length }</h3>

            <button
              className='sortbutton On'
              onClick={() => {
                if (SortType === 'score') {
                  setSortType('stars'); 
                } else if (SortType === 'stars') {
                  setSortType('name');  
                } else if (SortType === 'name') {
                  setSortType('chrono'); 
                } else {
                  setSortType('score');
                }
              }}
              style={{ borderRadius: '1vh 3vh 0vh 0vh' }}>
              {SortType === 'score' && (
                <>
                  <i className="fa-solid fa-percent"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'stars' && (
                <>
                  <i className="fa-regular fa-star"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'name' && (
                <>
                  <i className='fa-solid fa-arrow-down-a-z'></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'chrono' && (
                <>
                  <i className="fa-regular fa-clock"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
            </button>
          </div>

          {/* Menu botones */}
          <div style={{ position:'sticky', 
                        border:'0.3vh solid var(--main)',
                        borderRadius:'0vh 1vh 0vh 0vh',
                        backgroundColor:'var(--light)',
                        top: 0, zIndex: 4, 
                        padding:'1vh'}} >
            {/* <button 
                    onClick={() => exportToExcel(CVlist)}>
              <i className='fa-solid fa-file-export'></i> &nbsp; Exporta las respuestas como XLSX
            </button> */}
            <button className='redb'
                    onClick={OpenRejectionPopup}>
              <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Notifica a los no seleccionados 
            </button>
          </div>

          {/* Filtro de candidatos */}
          <input
            style={{  position:'sticky', 
                      width:'-webkit-fill-available',
                      height:'2vh',
                      border:'0.3vh solid var(--main)',
                      borderTop:'none',
                      borderRadius:'0vh',
                      backgroundColor:'var(--light)',
                      top: 0, 
                      zIndex: 4, 
                      padding:'1vh'}}
            type='text'
            placeholder='Filtrar por nombre'
            className='filterinput'
            value={FilterQuery}
            onChange={HandleFilterChange}></input>

          {/* lista de candidatos */}
          <div className='notas' style={{borderTop:'none', borderRadius:'0vh 0vh 1vh 1vh', marginBottom:'14.5vh'}}>
            {CVlist && CVlist.length > 0 && Wait === false ? (
              SortCVlist()
                .filter(reply => SelectList2.includes(reply.name))
                .filter(reply => reply.Rejected === State || !reply.Rejected)
                .filter(reply => reply.name.toLowerCase().includes(FilterQuery))
                .map((reply, index) => (
                <div key={reply.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <button
                    className={ reply.name ===  SelectedCVId  ? 'start-button highlighted' 
                                                              : SelectList2.includes(reply.name)  ? 'start-button green' 
                                                                                                  : 'start-button '}
                    style={{  borderColor: reply.Rejected === true ? 'gray' : '',
                              backgroundColor: reply.Rejected ? 'var(--gray)' : ''
                     }}
                    onClick={() => {
                      setScore(reply.stars3);
                      LookCV(reply.id);
                      setSelectedCVId(reply.name);
                      setSelectedName(reply.name);
                      setScreentype('Evaluacion');
                      DropContent();
                      handleEPLcheck(reply.name);
                      handleformcheck(reply.name, reply.FormUpdated).then( form =>{
                        setTimeout(() => {
                        setWait(false);
                      }, 500);
                      })
                      }}>
                    {reply.name}
                  </button>
                  {/* <button className={ !reply.Nota2 ? 'middle-button' : 
                                                    JSON.parse(reply.Nota2) < 50 ? 'middle-button red' : 
                                                                                  JSON.parse(reply.Nota2) < 70 ? 'middle-button yellow' : 
                                                                                                                'middle-button green'}
                          style={{  borderColor: reply.Rejected === true ? 'gray' : '', 
                                    borderRight:'none',
                                    backgroundColor: reply.Rejected ? 'var(--gray)' : '',
                                    maxWidth:'3vw' }}
                          onClick={() => {
                            setWait(true);
                            handleformcheck(reply.name, reply.FormUpdated).then(form => {
                            handleReneeopinion(form, reply.name).then(nota2 => {
                              saveCV(reply.name, nota2);
                              setTimeout(() => {
                                setWait(false);
                              }, 500);
                            })});
                          }}>
                    {reply.Nota2 ? JSON.parse(reply.Nota2) : ' - '}{'%'}
                  </button> */}
                  <button className={ !reply.stars3 ? 'middle-button' : 
                                                    JSON.parse(reply.stars3) < 3 ? 'middle-button red' : 
                                                                                  JSON.parse(reply.stars3) < 5 ? 'middle-button yellow' : 
                                                                                                                'middle-button green'}
                          style={{  borderColor: reply.Rejected === true ? 'gray' : '', 
                                    borderRight:'none',
                                    backgroundColor: reply.Rejected ? 'var(--gray)' : '',
                                    maxWidth:'3vw' }}>
                    {reply.stars3 ? JSON.parse(reply.stars3) : ' - '}{'☆'}
                  </button>
                  <input  type='checkbox' 
                          className='select-checkbox'
                          checked={SelectList2 ? SelectList2.includes(reply.name) : false}
                          style={{  borderColor: reply.Rejected === true ? 'gray' : '',
                                    backgroundColor: reply.Rejected ? 'var(--gray)' : '',
                           }}
                          onChange={() => {
                            Handleselect2(reply.name);
                          }}></input>
                </div>
              ))
            ) : Wait === true ? (
              <button> Espere un momento . . . </button>
            ) : (
              <button>No hay candidatos</button>
            )}
          </div>

        </div>

        {/* Respuestas Formularios */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{marginBottom:'-0.2vh'}}>

          {/* Nombre, Evaluacion y clase */}
          <div style={{display:'flex'}}>
            <h3 style={{display:'flex', alignItems: 'center'}} 
                className='boxtitle'>{SelectedCVId ? SelectedCVId+' | ' : ''} 
              <div  className={SelectedCVId? '' : 'hidden'}
                    style={{marginTop:'0'}}>
                <StarRating initialRating={Score} onRatingChange={handleRatingChange} />
              </div>
            </h3>   
            <button className='sortbutton On'
                    onClick={() => setScreentype(ScreenType === 'perfil' || ScreenType === 'preguntas' ? 'Evaluacion' : 'perfil')}
                    style = {{ textAlign:'left' , width:'20%', padding:'1vh', borderRadius:'1vh 3vh 0vh 0vh' }}
                    >
              {ScreenType === 'perfil' || ScreenType === 'preguntas' ? ' ir a Evaluacion Psicolaboral ' : ' ir a Perfil del candidato'}
            </button>
            <button className='sortbutton On'
                    onClick={() => setScreentype(ScreenType === 'perfil' || ScreenType === 'Evaluacion'  ? 'preguntas' : 'perfil')}
                    style = {{ textAlign:'left' , width:'20%', padding:'1vh', borderRadius:'1vh 3vh 0vh 0vh' }}
                    >
              {ScreenType === 'perfil' || ScreenType === 'Evaluacion' ? ' ir a Respuestas Pre-Screening ' : ' ir a Perfil del candidato'}
            </button>
          </div>

          {/* Contenido */}
          <div className='notas' style={{ overflow: 'scroll', 
                                          overflowX:'hidden', 
                                          borderRadius:'0vh 0vh 1vh 1vh',
                                          marginBottom: SelectedName && ScreenType === 'preguntas' ? '4vh' : '4vh'}}>
            <div>
            {Wait === true ? (
              <div className='box'>
                <img src={'/favicon.png'} alt='Profile' style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                <h2 style={{ color: 'var(--main)' }}>Espera un momento mientras Renee trabaja</h2>
                {/* <p> Se estan procesando {remainingCandidates} candidatos, por favor espere un momento...</p> */}
                <div className='loader-container'>
                  <div className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>

            ) : ScreenType === 'perfil' ? (
              <div style={{display:'flex', gap:'1vh'}}>
                <div className='boxfit' style={{ maxWidth: '20vw' }}>
                  <div className='notas'>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                      <img
                        src={CV && CV.personalInfo.picture ? CV.personalInfo.picture : '/default.png'}
                        alt='Profile'
                        style={{
                          width: '150px',
                          height: '150px',
                          objectFit: 'cover',
                          borderRadius: '50%',
                          position:'stick'
                        }}
                      />
                      <div
                        dangerouslySetInnerHTML={{ __html: CV ? FormatPersonalData(CV) : '' }}
                        style={{
                          whiteSpace: 'pre-wrap',
                          textAlign:'start'
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className= { ScreenType === 'perfil' ? 'notas' : 'hidden'}
                  dangerouslySetInnerHTML={{ __html: CV ? FormatJobData(CV.workExperience) 
                                                        + '\n\n\n\n'
                                                        + FormatStudyData(CV.studies) : '' }}
                  style={{
                    overflowY: 'auto',
                    textAlign: 'left'
                  }}
                />
              </div>
            ) : ScreenType === 'preguntas' ? (  
              <div>
                { Form.length > 0 ?(
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                    {Form.map((block, index) => (
                        <div
                          key={index}
                          className='notas-content'
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: '1vh',
                            marginRight: '1vh',
                            gap: '1vh',
                            overflow:'hidden',
                            borderColor: block.type === 'TextoNotas' ? 'var(--red)' : ''
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <div style={{ display: 'flex', alignItems: 'baseline', justifyContent:'space-between' }}>
                              <span
                                className='notas-title'
                                style={{ margin:' 1vh 1vh 0vh 1vh', fontWeight: 'bold', height:'8vh' }}>
                                {block.title}
                              </span>
                            </div>
                  
                            {block.type === 'Texto' ? (
                              <textarea
                                value={block.content}
                                className='notas'
                                readOnly
                                placeholder='. . .'
                                rows='5'
                                style={{ height: '10vh', marginBottom: '1vh' }}
                              />
                            ) : block.type === 'TextoNotas' ? (
                              <textarea
                                value={block.content}
                                className='notas'
                                readOnly
                                placeholder='Ingrese sus notas'
                                rows='5'
                                style={{ height: '10vh', marginBottom: '1vh' }}
                              />
                            ) : block.type === 'Alt Unica' ? (
                              <div className='alternatives-container'>
                                {block.alternatives && block.alternatives.map((alternative, optIndex) => (
                                  <label  key={optIndex} 
                                          className={ alternative.correct ? 'alternative-label green' : 'alternative-label' }>
                                    <input
                                      type='radio'
                                      className={'alternative-checkbox'}
                                      disabled 
                                      checked={alternative.selected || false}
                                    />
                                    {alternative.label}
                                  </label>
                                ))}
                              </div>
                            ) : block.type === 'Alt Multiple' ? (
                              <div className='alternatives-container'>
                                {block.alternatives && block.alternatives.map((alternative, optIndex) => (
                                  <label  key={optIndex} 
                                          className={ alternative.correct ? 'alternative-label green' : 'alternative-label' }>
                                    <input
                                      type='checkbox'
                                      className='alternative-checkbox'
                                      disabled 
                                      checked={alternative.selected || false}
                                    />
                                    {alternative.label}
                                  </label>
                                ))}
                              </div>
                            ) : null}
                            
                          </div>      
                        </div>
                    ))}
                  </div>
                ) : (
                  <div><h2>No hay Respuestas disponibles</h2></div>
                )}
              </div>
            ) : SelectedCVId && EvaluacionPSL ? (
              <div style={{display:'flex', gap:'1vh'}}>
                <div
                  className= 'notas'
                  dangerouslySetInnerHTML={FormatEvaluationData(EvaluacionPSL)}
                  style={{
                    overflowY: 'auto',
                    textAlign: 'left',
                    paddingLeft: '3vh',
                    paddingRight: '3vh'
                  }}
                />
              </div>
              
            ) : SelectedCVId && !EvaluacionPSL ? ( 
              <div style={{display:'flex', flexDirection:'column',alignItems:'center', fontSize: '1.2rem'}}>
                <h2>Sin Evaluacion disponible</h2>
                <p> Inicia el proceso subiendo la evaluacion psicolaboral del candidato</p>
                <p> Esta puede encontrarse en formato .PDF o .Docx</p>
                <input  key = {inputKey}
                        id = 'fileContent'
                        type='file'  
                        onChange={HandleFileChange}
                        ref={FileInputRef} 
                        className='hidden'/>
                <div style={{display:'flex', gap:'1vh'}}>
                  <button style={{width:'60vh', height:'5vh'}}
                          onClick={HandleFile}>
                    <i className="fa-solid fa-paperclip"></i>  &nbsp; {FileName || 'Cargar Archivo'}
                  </button>
                  <button  style={{width:'5vh', height:'5vh'}} className='redb box-button' onClick={DropContent}>
                    <i className="fa-solid fa-x"></i>
                  </button>                  
                </div>
                <p>
                  <button onClick={EvaluatePSL}>
                    Confirmar
                  </button>
                </p>
              </div>
            ) : (
              <div style={{display:'flex', flexDirection:'column',alignItems:'center', fontSize: '1.2rem'}}> 
                <h1>Evaluacion Psicolaboral</h1>
                <h3>Inicia el proceso seleccionando a uno de tus candidat@s disponibles</h3>
                <div style={{fontSize:'4rem'}}>👈</div>
              </div>
            )}
            </div>
          </div>
          
        </div>

      </div>
      
      <div className={ Wait? 'hidden' : 'bottom'}>
        <button onClick={goto08}>volver</button>
        <button onClick={()=>setState(!State)}>ver candidatos rechazados</button>
        <button className={ SelectList2.length > 0 ? 'Save last-button' : 'hidden'} onClick={goto10}>Guardar y Continuar</button>
      </div>
      
    </div>
  );
}
