
//=============================================================================
// Pantalla Notas del proceso
//=============================================================================

// Genericos
import './css/App.css';
import React, { useState, useEffect, useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
// API
import APICALL from './APICALL';
import AICALL from './AICALL';
// Especificos 
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function TextEditor() {

  const navigate = useNavigate();
  const [FetchedData, setFetchedData]     = useState(''); 
  const [FileName, setFileName]           = useState(''); 
  const processname                       = sessionStorage.process;
  const [memos, setmemos]                 = useState([]); 
  const [hiddenButtons, setHiddenButtons] = useState([]);
  const FileInputRef                      = useRef(null);
  const [inputKey, setInputKey]           = useState(Date.now());
  const [EditorCargo, setEditorCargo] = useState(EditorState.createEmpty());
  const [EditorEmpresa, setEditorEmpresa] = useState(EditorState.createEmpty());


  // Editores de texto
  const parseEditorContent = (content) => {
    try {
      // Intentar parsear como JSON
      const parsedContent = typeof content === "string" ? JSON.parse(content) : content;
  
      // Verificar si tiene estructura válida de `convertFromRaw`
      if (parsedContent && parsedContent.blocks && parsedContent.entityMap !== undefined) {
        return EditorState.createWithContent(convertFromRaw(parsedContent));
      }
    } catch (error) {
      console.warn("Error al parsear contenido:", error);
    }
  
    // Si no es un JSON válido o no tiene la estructura de DraftJS, cargar como texto plano
    return EditorState.createWithContent(ContentState.createFromText(content || ""));
  };
  const onEditorCargoChange = (newState) => {
    setEditorCargo(newState);
  };
  const onEditorEmpresaChange = (newState) => {
    setEditorEmpresa(newState);
  };
  async function SaveData() {
    if(EditorCargo){
      try {
        await APICALL({
          apicall: 'PD_update',
          code: processname, 
          U_emp: sessionStorage.Grupo,
          notas: convertToRaw(EditorCargo.getCurrentContent()),
          notasEmp: convertToRaw(EditorEmpresa.getCurrentContent()),
        });
      } catch (error) {
        console.error('Error during fetch:', error);
        return { token: null };
      }
    }
  };


  // Subida de archivo
  const HandleFile = () => {
    FileInputRef.current.click();
  };
  async function HandleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name); 
    } else {
      return
    }
  
    const fileMimeType = file.type; 
    console.log('File received:', file);
  
    if (fileMimeType === 'application/pdf' || fileMimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ) {
      const data = new FormData();
      data.append('file', file);
      data.append('apicall', 'EX');
  
      try {
        const reply = await AICALL(data);
        localStorage['archivo'] = reply;
      } catch (error) {
        console.error('Error during PDF processing:', error);
      }
      
  
    } else if (fileMimeType === 'text/plain') {
      const reader = new FileReader();
      reader.onload = function(e) {
        const content = e.target.result;
        document.getElementById('fileContent').textContent = content;
        localStorage['archivo'] = content;
      };
      reader.readAsText(file);
      
  
    } else {
      DropContent()
      window.alert('Formato no soportado. Actualmente solo se aceptan los formatos:\n * .Pdf\n *.Docx\n *.Txt');
    }
  };
  async function DropContent() {
    setInputKey(Date.now());
    setFileName('');
    localStorage['archivo'] = ''
  };


  // Memos laterales
  const HandleButtonClick = (item) => {
    setHiddenButtons([...hiddenButtons, item]);
  };


  // Navegacion
  const goto01 = () => {
    SaveData();
    navigate('/');
  };
  const goto04 = () => {
    localStorage['notas']   = EditorCargo.getCurrentContent().getPlainText();
    localStorage['notasemp'] = EditorEmpresa.getCurrentContent().getPlainText();
    SaveData();
    navigate('/P_Datos');
  };


  useEffect(() => {
    let isMounted = true; 
  
    localStorage['reply'] = '';
    localStorage['notas'] = '';
    localStorage['notasemp'] = '';
  
    const fetchData = async () => {
      setmemos(require('./docs/Preguntas.json'));
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo
      });
  
      if (isMounted) { 
        setFetchedData(data);
        setEditorCargo(parseEditorContent(data.notas));
        setEditorEmpresa(parseEditorContent(data.notasEmp));
        localStorage['archivo'] = '';
      }
    };
    fetchData();
  
    return () => {
      isMounted = false; 
    };
  }, []);
  
  const dataItems = [
    { label: 'NOMBRE DEL CARGO', value: FetchedData.Charge_name ? FetchedData.Charge_name.toUpperCase() : ' - - - ' }, 
    { label: 'EMPRESA',                 value: FetchedData.E_name }, 
		{ label: 'PARTNER ENCARGADO',       value: FetchedData.R_name },
    { label: 'INTERLOCUTOR',            value: FetchedData.I_name }
  ];
  
  
  //############################################################################################################
  //############################################################################################################

  return (
    <div className='BackGround'>

      {/* Type header  */}
      <div className='typing-effect-container'>
        <h2 className='typing-effect' >
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          👇 Levantaremos perfil con tu cliente (interno o externo), registra aquí las notas que tomes de esa conversación 👇
        </h2>
			</div>
      
      <div className='MainBody'>

        {/* Bloque de Notas */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div style={{display:'flex', flexDirection:'column', width:'100%', gap:'1vh'}}>
                  <div className='boxfit' style={{marginRight: '1vh', overflow: 'hidden', marginBottom: '-0.4vh', height:'60%'}}>
                    <h3 className = 'boxtitle'>Tus notas De la Empresa</h3>
                    <Editor
                      editorState={EditorEmpresa}
                      onEditorStateChange={onEditorEmpresaChange}
                      wrapperClassName='notas'
                      toolbarClassName='MobHide'
                      toolbar={{ options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'] }}
                    />
                  </div>
                  <div className='boxfit' style={{marginRight: '1vh', overflow: 'hidden', marginBottom: '-0.4vh'}}>
                    <h3 className = 'boxtitle'>Tus notas del Cargo</h3>
                    <Editor
                      editorState={EditorCargo}
                      onEditorStateChange={onEditorCargoChange}
                      wrapperClassName='notas'
                      toolbarClassName='MobHide'
                      toolbar={{ options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'] }}
                    />
                  </div>
        </div>

        {/* Informacion Adicional */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div style={{display:'flex', flexDirection: 'column', marginBottom: '-0.4vh'}}>
          <div className='MobHide data-boxes'>
            {dataItems.map((item, index) => (
              <div  key={index} 
                    className='data-box'>
          
                <strong>{item.label}:</strong> {item.value}
              </div>
            ))}
            <div style={{display: 'flex' , gap : '0.5vh', background:'var(--mid-light)', border:' 2px solid var(--main)', borderRadius: '1vh', padding: '0.5vh'}}>
              <button onClick={HandleFile}>
              <i className="fa-solid fa-paperclip"></i>  &nbsp; {FileName || 'Adjuntar Archivo'}
              </button>
              <input  key = {inputKey}
                      id = 'fileContent'
                      type='file'  
                      onChange={HandleFileChange}
                      ref={FileInputRef} 
                      className='hidden'/>
              
              <button  className='redb box-button' onClick={DropContent}>
              <i className="fa-solid fa-x"></i></button>
            </div>
          </div>

          <div className='MobHide memos-title'>
            <h3>
              {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
              Acá te dejo algunas recomendaciones de preguntas para el levantamiento de perfil 👇
            </h3>
          </div>
          <div className='MobHide memos-box'>
              {memos.map((item) => (
                <button
                  key={item}
                  className={`memos ${hiddenButtons.includes(item) ? 'hidden' : ''}`}
                  onClick={() => HandleButtonClick(item)}
                >
                  <strong>{item} </strong> 
                </button>
              ))}
          </div>
        </div>
        
      </div>

        <div className='bottom'>
          <button onClick={goto01}>volver</button>
          <button className='Save last-button' onClick={goto04}>Guardar y Continuar</button>
        </div>
    </div>
  );
}
