
//=============================================================================
// Pantalla Creación de Proceso
//=============================================================================

// Genericos
import './css/App.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// API
import APICALL from './APICALL';

export default function Nopciones() {

  const navigate = useNavigate();
  
  const [Elista, setELista] = useState([]);
  const [Ilista, setILista] = useState([]);

  const [Charge_name, setCharge_name] = useState('');
  const [Category, setCategory]       = useState('');

  const [E_rut, setE_rut]           = useState('');
  const [E_social, setE_social]     = useState('');
  const [E_name, setE_name]         = useState('');
  const [E_giro, setE_giro]         = useState('');
  const [E_contacto, setE_contacto] = useState('');
  const [E_correo, setE_correo]     = useState('');

  const [I_name, setIname] = useState('');
  const [I_mail, setImail] = useState('');

  const [wait, setwait]   = useState(true);
  const [wait2, setwait2] = useState(false);
  const [wait3, setwait3] = useState(false);
  const [wait4, setwait4] = useState(false);

  const [Nempresa, setNempresa]   = useState(false);
  const [Eempresa, setEempresa]   = useState(false);
  const [Ninter, setNinter]       = useState(false);

  const code = Date.now() +'_TB01_YP01_' + E_name + '_' + I_name + '_' + Charge_name;
  const P_name = E_name + '_' + Charge_name;

  // Funciones de empresa
  async function NewEmp (e) {

    function dgv(T) {
      var M = 0;
      var S = 0;
      var multipliers = [2, 3, 4, 5, 6, 7];
      
      while (T > 0) {
        var digit = T % 10;
        S += digit * multipliers[M % 6];
        M++;
        T = Math.floor(T / 10);
      }
      
      var remainder = 11 - (S % 11);
      
      if (remainder === 11) return 0;
      if (remainder === 10) return 'K';
      return remainder;
    }
    // eslint-disable-next-line
    const cleanRut = E_rut.replace(/[\.\-]/g, '');
    let verif = (cleanRut.slice(-1));
    if (verif === 'k'){ verif = 'K'};

    if (String(dgv(cleanRut.slice(0,-1))) === verif){
      setwait2(true);
      e.preventDefault();
      await APICALL({
        apicall: 'E_create',
        U_emp: sessionStorage.Grupo,
        E_rut,
        E_social,
        E_name,
        E_giro,
        E_contacto,
        E_correo
      });
      await APICALL({
        apicall: 'I_create',
        U_emp: sessionStorage.Grupo,
        E_name,
        I_name: E_contacto,
        I_mail: E_correo
      });
      FetchDataE();
      SelectEmp(E_name);
      setwait2(false);
    } else {
      alert('rut invalido');
    }
  };
  async function EditEmp(){

    setNempresa(false);
    const data = await APICALL({
      apicall: 'E_fetch',
      E_name: E_name
    });

    setE_rut(data.E_rut)
    setE_social(data.E_social)
    setE_name(data.E_name)
    setE_giro(data.E_giro)
    setEempresa(!Eempresa)
  };
  async function SelectEmp (reply) {

    setwait(true)
    sessionStorage.removeItem('Empresa');
    sessionStorage.setItem('Empresa', reply);
    const interlocutors = FetchDataI(reply);
    setILista(interlocutors);
    setE_name(reply);
    setwait(false);
  };
  async function FetchDataE() {
    const data = await APICALL({
      apicall: 'E_list',
      U_emp: sessionStorage.Grupo
    });
    setELista(data.filter(x => x));
    return data.filter(x => x)
  };

  // Funciones Interlocutor
  async function NewInt (e) {
    setwait3(true);
    e.preventDefault();
    await APICALL({
      apicall: 'I_create',
      E_name,
      I_name, // nombre de la empresa
      I_mail // mail del interlocutor
    });
    FetchDataI(E_name);
    setwait3(false);
    setIname('');
    setImail('');
  };
  async function FetchDataI(name) {
    const data = await APICALL({
      apicall: 'I_list',
      U_emp: sessionStorage.Grupo,
      E_name: name
    });
    setILista(data.filter(x => x));
    return data.filter(x => x)
  };


  // Navegacion
  const Goto01 = () => { navigate('/') };
  const Goto03 = async () => {
    try {

      const now = new Date();
      const formattedTimestamp = `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;

      const data = await APICALL({
        apicall: 'PD_create',
        code,
        P_name,
        E_name,
        I_name,
        R_name : sessionStorage.token,
        U_emp : sessionStorage.Grupo,
        Charge_name,
        Category,
        JobId: Category === 'Evaluacion' ? 'renee_PSP' : '',
        FirstDate: formattedTimestamp
  
      });
      if (data){
        sessionStorage.removeItem('process');
        sessionStorage.setItem('process', code);
        sessionStorage.setItem('name', Charge_name);
      } 
      navigate('/P_Notas');
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    
  };


  useEffect(() => {
    async function Updatelista() {
      const lista = await FetchDataE();
      setELista(lista);
    };
    Updatelista();
  }, []);

  
  //############################################################################################################
  //############################################################################################################

  return (

    <div className='BackGround'>

      {/* Type header */}
      <div className='typing-effect-container'>
				<h2 className='typing-effect' >
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          👇 Ahora proporcioname información para asistirte en el proceso que liderarás!
        </h2>
			</div>

      <div className='MainBody'>

        {/* Lista de Empresas */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='ColumnLeft'>
          <div>

          <h2 className='form-title'> Paso 1 : Selecciona La Empresa </h2>

          <div className='column' style={{width:'45%'}}>
            <div className='select-container'>
              <button 
                className='MobHide'
                onClick={() => {setEempresa(false); setNempresa(!Nempresa)}} 
                style={{ marginBottom: '0.5vh' }}>
                Nueva Empresa
              </button>
              <button 
                style={{marginBottom:'0.5vh'}} 
                className={!wait && sessionStorage.rol === 'Admin' ? 'MobHide':'hidden'} 
                onClick={EditEmp}>
                Editar datos 
              </button>
              {Elista.length > 0 ? (
                <select 
                  className='bigselect' 
                  size={Math.min(30, Math.floor(window.innerHeight / 70))}
                  onChange={(e) => { SelectEmp(e.target.value); setwait4(false); setEempresa(false);  }}
                >
                  {Elista.sort().map((reply, index) => (
                    <option key={index} value={reply}>
                      {reply.toUpperCase()}
                    </option>
                  ))}
                </select>
              ) : (
                <button className='PublicButoff'>Lista de Empresas Vacia</button>
              )}
            </div>
          </div>
          {Nempresa === true || Eempresa === true ? (
            <div className='MobHide column'>
              <button 
                style={{ marginBottom: '0.5vh' }}>
                Ingrese los Datos
              </button>
              <div className={Nempresa === false ? 'hidden' : ''}>
                <form onSubmit={NewEmp}>

                  <input type='text' placeholder='Rut de la empresa'
                  onChange={e => setE_rut(e.target.value)} required={true} />

                  <input type='text' placeholder='Razón Social'
                    onChange={e => setE_social(e.target.value)} required={true} />

                  <input type='text' placeholder='Alias de la empresa'
                    onChange={e => setE_name(e.target.value)} required={true} />

                  <input type='text' placeholder='Giro de la empresa'
                    onChange={e => setE_giro(e.target.value)} required={true} />
                    
                  <input type='text' placeholder='Contacto de la empresa'
                    onChange={e => setE_contacto(e.target.value)} required={true} />

                  <input type='email' placeholder='Correo'
                    onChange={e => setE_correo(e.target.value)} required={true} />

                  <button type='submit' >Guardar los datos</button>

                </form>
                {wait2 === false ? (
                  <div>
                    <br />
                  </div>
                ) 
                : (
                  <div className='loader-container'>
                    <div className='pulsing-dot'>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
              <div className={Eempresa === false ? 'hidden' : ''}>
                <form onSubmit={NewEmp}>

                  <input type='text' value={E_rut || ''} placeholder='Rut de la empresa'
                  onChange={e => setE_rut(e.target.value)} required={true} />

                  <input type='text' value={E_social || ''} placeholder='Razón Social'
                    onChange={e => setE_social(e.target.value)} required={true} />

                  <input type='text' value={E_name || ''} placeholder='Alias de la empresa'
                    onChange={e => setE_name(e.target.value)} required={true} />

                  <input type='text' value={E_giro || ''} placeholder='Giro de la empresa'
                    onChange={e => setE_giro(e.target.value)} required={true} />

                  <button type='submit' >Guardar los cambios</button>

                </form>
                {wait2 === false ? (
                  <div>
                    <br />
                  </div>
                ) 
                : (
                  <div className='loader-container'>
                    <div className='pulsing-dot'>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className='MobHide notas'
                  style={{fontSize:'1.2rem'}}>
              <p>Elije aqui la Empresa para la cual crearas el proceso a trabajar. </p>
              <p>O si esta no se encuentra en la lista, puedes crear una nueva con el boton de 'Nueva Empresa'</p>
            </div>
          )}

          </div>
        </div>

        {/* Lista de Interlocutores */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='ColumnLeft'>
          {wait ? (
            <h1> </h1>
          ) 
          : (
            <div>
            <h2 className='form-title' > Paso 2 : Selecciona el Interlocutor </h2>

              <div className='column' style={{width:'45%'}}>
                <div className='select-container'>
                  <button 
                    className='MobHide'
                    onClick={() =>setNinter(!Ninter)} 
                    style={{ marginBottom: '0.5vh' }}>
                    Nuevo/a Interlocutor/a
                  </button>
                  {Ilista.length > 0 ? (
                    <select size={Math.min(30, Math.floor(window.innerHeight / 70))}
                            className='bigselect' 
                            onChange={(e) => {setIname(e.target.value); setwait4(true)}}>
                    {Ilista.sort().map((reply, index) => (
                      <option key={index} value={reply}>
                        {reply.toUpperCase()}
                      </option>
                    ))}
                  </select>
                  ) : (
                    <button className='PublicButoff'>No existen interlocutores</button>
                  )}
                </div>
              </div>
              { Ninter ? (
                <div className='MobHide column'>
                  <button 
                    style={{ marginBottom: '0.5vh' }}>
                    Ingrese los Datos
                  </button>
                  <div className={Ninter ? '' : 'hidden'}>
                    
                    <form onSubmit={NewInt}>
                      <input type='text' placeholder='Nombre del interlocutor'
                      onChange={e => setIname(e.target.value)} required={true} />
  
                      <input type='email' placeholder='Correo'
                      onChange={e => setImail(e.target.value)} required={true} />
  
                      <button  type='submit'>Guardar los datos</button>
                    </form>
                    
                    {wait3 === false ? (
                      <div>
                        <br />
                      </div>
                    ) 
                    : (
                      <div className='loader-container'>
                        <div className='pulsing-dot'>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className='MobHide notas'
                      style={{fontSize:'1.2rem'}}>
                  <p>Ahora elige el interlocutor/a con el cual trabajaras este proceso. </p>
                  <p>O si este/a no se encuentra en la lista, puedes agregarlo/a con el boton de 'Nuevo/a interlocutor/a'</p>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Nombre del proceso */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='ColumnLeft'>
         {!wait4 ? (
            <h1> </h1>
          ) 
          : (
            <div>
              <h2 className='form-title' > Paso 3 : Asigna un Nombre al Proceso </h2>
              <div className='column'>
                <div>
                <label><strong>NOMBRE DEL CARGO :</strong>
                  <input  type='text' 
                          placeholder= 'Ingrese el Nombre del cargo'
                          style={{  marginTop: '1vh',
                                    marginBottom: '1vh',
                                    padding: '1vh',
                                    width: '-webkit-fill-available',
                                    height: '3vh',
                                    border: '0.3vh solid var(--main)',
                                    borderRadius: '1vh'}}
                          onChange={e => setCharge_name(e.target.value)} 
                          required={true} />
                </label>
                <label><strong>TIPO DE PROCESO :</strong>
                  <select  type='select' 
                          placeholder= 'Ingrese el Nombre del cargo'
                          style={{  marginTop: '1vh',
                                    padding: '1vh',
                                    width: '-webkit-fill-available',
                                    height: '5vh',
                                    border: '0.3vh solid var(--main)',
                                    borderRadius: '1vh'}}
                          onChange={e => setCategory(e.target.value)} 
                          required={true}>
                    <option value='Publicacion'    > Proceso Completo           </option>
                    <option value='Evaluacion'     > Evaluacion de perfil  </option>
                  </select>
                </label>
                <button className={Charge_name === '' || I_name === '' ? 'hidden' : 'last-button'} 
                        style={{marginTop:'1vh'}}
                        onClick={Goto03}>
                        Continuar
                </button>
                </div>
              </div>
              <div className='column'>
                <div className='notas'
                      style={{fontSize:'1.2rem'}}>
                  <p>Ahora asigna un nombre a tu proceso, este sera el nombre que se utilizara para este de manera interna. </p>
                  <p>Finalmente determinar si este sera un proceso publicado o un proceso de evaluacion de candidatos. </p>
                </div>
              </div>
            </div>
          )}
        </div>

      </div>

      <div className='bottom'>
        <button onClick={Goto01}>volver</button>
      </div>

    </div>
  );
}
