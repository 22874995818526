
//=============================================================================
// Pantalla Recuperación de Respuestas Pre-Screening
//=============================================================================

// Genericos
import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// API
import APICALL from './APICALL';
import LOGCALL from './LOGCALL';

// Especificos
import StarRating from '../components/star';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';

export default function Referencias() {
  const navigate = useNavigate();

  const [State, setState] = useState(false)
  const [Score, setScore] = useState('0');
  const [WHOreply, setWHOreply] = useState('');
  const [SortType, setSortType] = useState('chrono');
  const [FetchedData, setFetchedData] = useState(''); 
  const [SelectedRUT, setselectedRUT] = useState('');
  const [SelectedName, setSelectedName] = useState('');
  const [selectedMail, setSelectedMail] = useState('');
  const [referenceName, setReferenceName] = useState('');
  const [SelectedNameR, setSelectedNameR] = useState('');
  const [SelectedMailR, setSelectedMailR] = useState('');
  const [ScreenType, setScreentype] = useState('perfil');
  const [referenceEmail, setReferenceEmail] = useState('');
  const [referenceNumber, setReferenceNumber] = useState('');
  const [SelectedCategories, setSelectedCategories] = useState('');
  const [Form, setForm] = useState([]); 
  const [CVlist, setCVlist] = useState([]);
  const [SelectList2, setselectlist2] = useState([]);
  const [SelectList3, setselectlist3] = useState([]);
  const [SelectedCandidates, setSelectedCandidates] = useState([]);
  const [Wait, setWait] = useState(false);
  const [ShowPopup, setShowPopup] = useState(false);
  const [ShowPopup2, setShowPopup2] = useState(false);
  const [ShowPopup3, setShowPopup3] = useState(false);
  const [ShowPopup4, setShowPopup4] = useState(false);
  const [ShowPopup5, setShowPopup5] = useState(false);
  const [CV, setCV] = useState(null);
  const [SelectedCVId, setSelectedCVId] = useState(null);
  const [countryCode, setCountryCode] = React.useState("+56");


  

  // Edicion de referentes
  async function handleAddReference() {
    if (referenceName && referenceEmail) {
  
      try {
        const selectedCV = CVlist.find(cv => cv.name === SelectedName);
  
        if (selectedCV?.reference?.some(ref => ref.email === referenceEmail)) {
          setReferenceName('');
          setReferenceEmail('');
          setShowPopup(false);
          return;
        }
  
        const newReference = { name: referenceName, phone:countryCode+referenceNumber,  email: referenceEmail };
        const updatedCVlist = CVlist.map(cv =>
          cv.name === SelectedName
            ? {
                ...cv,
                reference: Array.isArray(cv.reference)
                  ? [...cv.reference, newReference]
                  : [newReference]
              }
            : cv
        );
  
        await APICALL({
          apicall: 'PD_update',
          code: sessionStorage.currentsave,
          U_emp: sessionStorage.Grupo,
          CVData: {
            name: SelectedName,
            reference: updatedCVlist.find(cv => cv.name === SelectedName)?.reference
          }
        });
        setCVlist(updatedCVlist);
        setReferenceName('');
        setReferenceEmail('');
        setShowPopup(false);
      } catch (error) {
        console.error('Error durante la actualización:', error);
      }
    }
  };
  async function deleteReference() {
    try {
      const selectedCV = CVlist.find(cv => cv.name === SelectedName);
  
      if (!selectedCV) {
        console.error('CV no encontrado para el nombre seleccionado.');
        return;
      }
  
      const updatedReferences = selectedCV.reference?.filter(ref => ref.email !== SelectedMailR) || [];
  
      const updatedCVlist = CVlist.map(cv =>
        cv.name === SelectedName
          ? { ...cv, reference: updatedReferences }
          : cv
      );
  
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.currentsave,
        U_emp: sessionStorage.Grupo,
        CVData: {
          name: SelectedName,
          reference: updatedReferences
        }
      });
  
      setCVlist(updatedCVlist);
      alert("Referencia eliminada correctamente.");
    } catch (error) {
      console.error('Error durante la eliminación de la referencia:', error);
    }
  };

  
  // Exportar a Excel
  async function ReferentData(CVlist, SelectList2) {
    const filteredCVlist = CVlist.filter(candidate => SelectList2.includes(candidate.name));
    const candidateData = [];
    let headers = ['Nombre Candidato', 'Email Candidato', 'RUT Candidato', 'Nombre Referente', 'Email Referente'];
    let maxColumnsReference = null;
  
    try {
      for (const candidate of filteredCVlist) {
        for (const reference of candidate.reference) {
          try {
            const reply = await handleformcheck(candidate.name, reference.name);
            if (reply.response !== '') {
              maxColumnsReference = { reference, reply };
            }
          } catch (error) {
            console.warn(`Error al obtener formulario para referencia ${reference.name}:`, error);
            continue;
          }
        }
      }

      if (maxColumnsReference) {
        maxColumnsReference.reply.forEach((item, idx) => {
          headers.push(item.title || `Pregunta ${idx + 1}`);
        });
  
        for (const candidate of filteredCVlist) {
          for (const reference of candidate.reference) {
            try {
              const formData = await handleformcheck(candidate.name, reference.name);
              // if (!Array.isArray(formData)) {
              //   continue;
              // }
  
              const formattedData = {
                'Nombre Candidato': candidate.name,
                'Email Candidato': candidate.mail || ' - - - ',
                'RUT Candidato': candidate.rut || ' - - - ',
                'Nombre Referente': reference.name,
                'Email Referente': reference.email || ' - - - '
              };
  
              headers.slice(5).forEach((header, idx) => {
                const question = formData[idx];
                formattedData[header] =
                  question &&
                  (question.type === 'Alt Multiple' || question.type === 'Alt Unica') &&
                  question.alternatives
                    ? question.alternatives.filter(alt => alt.selected).map(alt => alt.label).join(', ') || ' - - - '
                    : question?.content || ' - - - ';
              });
  
              candidateData.push(formattedData);

            } catch (error) {
              
              console.warn(`Error al obtener formulario para referencia ${reference.name}:`, error);
              continue;
            }
          }
        }
      }
    } catch (error) {
      console.error('Error general en ReferentData:', error);
    }
  
    const formattedDataForExcel = [headers];
    candidateData.forEach(dataRow => {
      const row = headers.map(header => dataRow[header] || ' - - - ');
      formattedDataForExcel.push(row);
    });
  
    return formattedDataForExcel;
  }
  async function exportToExcel(CVlist) {
    try {
      const compiledData = await ReferentData(CVlist, SelectList2);
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Referencias');
  
      const headers = compiledData[0];
      const headerRow = worksheet.addRow(headers);
      headerRow.eachCell(cell => {
        cell.font = { name: 'Arial', size: 12, bold: true, color: { argb: 'FFFFFFFF' } };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4F81BD' } };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      });
  
      for (let i = 1; i < compiledData.length; i++) {
        const row = worksheet.addRow(compiledData[i]);
        row.eachCell(cell => {
          cell.alignment = { vertical: 'top' };
        });
      }
  
      headers.forEach((header, index) => {
        worksheet.getColumn(index + 1).width = 40;
      });
  
      const buffer = await workbook.xlsx.writeBuffer();
      const fileName = `Referencias_${sessionStorage.name}.xlsx`;
      saveAs(new Blob([buffer]), fileName);
    } catch (error) {
      console.error('Error general en exportToExcel:', error);
    }
  }
  

  // Seleccion de candidatos
  async function Handleselect3(name) {
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name
          ? { ...cv, select3: !cv.select3} 
          : cv
      )
    );
  
    const updatedSelectList3 = SelectList3.includes(name)
      ? SelectList3.filter(id => id !== name)
      : [...SelectList3, name];
  
    setselectlist3(updatedSelectList3);
  
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo,
        SelectList3: updatedSelectList3
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };
  async function DropCandidate( mail, name ) {
    const confirmation = window.confirm(`¿Estás seguro de que deseas Rechazar al candidato \n\n ● ${name} \n\nde este proceso?`);

    if (confirmation) {
      const reply = HandleMail( mail, name, false)
      if (reply !== ''){
        window.alert(`Se envio la notificacion a ${name}`)
        window.location.reload()
      } else {
        window.alert(`No se logro enviar la notificacion a ${name}`)
      }
    }
  };


  // Envio de correos
  const ToggleCandidateRejected = (name) => {
    setSelectedCandidates(prev =>
      prev.includes(name) ? prev.filter(item => item !== name) : [...prev, name]
    );
  };
  async function HandleMailAll( ) {

    const MailList = []
    for (const reply of CVlist) {
      if (SelectList2.includes(reply.name)){
        if (!SelectList3.includes(reply.name)){
          if (!SelectedCandidates.includes(reply.name) && !reply.Rejected) {
            try {
              MailList.push(await HandleMail(reply.mail, reply.name));   
              // MailList.push(reply.name)
            } catch (error) {
              console.error(`Error al enviar mensaje a ${reply.name}`);
            }
          }
        }
      }
    }

    MailNotiff(MailList, 'Rechazo');
    if (MailList.length > 0) {
      const formattedList = MailList.map(email => `- ${email}`).join('\n');
      MailNotiff(MailList, 'Rechazo');
      window.alert(`Notificacion enviada exitosamente a los siguientes candidatos:\n\n${formattedList}`);
    } else {
      window.alert('No Se logro enviar la notificacion a ningun candidato, por favor intente nuevamente');
    }

    window.location.reload();
    setShowPopup5(false);
  };
  async function HandleMail(mail, name) {

    await APICALL({
      apicall: 'PD_update',
      code: sessionStorage.currentsave,
      U_emp: sessionStorage.Grupo,
      CVData: { name: name, Rejected: true}
    });
    
    try {
      await LOGCALL({
        apicall: 'FM_REJ1',
        Name : name,
        Mail : mail,
        Title : FetchedData.Charge_title,
        E_name: FetchedData.CompName,
        P_name: sessionStorage.token,
        P_mail: sessionStorage.mail,
      });
      return name;
    } catch (error) {
      console.error('Error during fetch:', error);
      return '';
    }
  };
  async function handleSendReference() {
    if (referenceName && referenceEmail) {
      
      try {
        await LOGCALL({
          apicall: 'FM_REF',
          code: sessionStorage.name,
          Mail: referenceEmail,
          NameR: referenceName,
          NameC: SelectedName,
          P_name: sessionStorage.token,
          P_mail: sessionStorage.mail
        });
        alert("Mensaje enviado a " + referenceEmail);
      } catch (error) {
        console.error('Error during fetch:', error);
      }

      setReferenceName('');
      setReferenceEmail('');
      setShowPopup(false);
    }
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('URL copiada al portapapeles!');
      })
      .catch(err => {
        console.error('Error al copiar al portapapeles: ', err);
      });
  };
  async function MailNotiff(list , theme) {

    try {
      await LOGCALL({
        apicall: 'FM_NOT',
        code: sessionStorage.process,
        Mail : sessionStorage.mail,
        P_name: sessionStorage.token,
        List: list,
        Theme: theme
      });
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };


  // Check antecedentes legales
  async function RutCheck (Rut) {

    function dgv(T) {
      var M = 0;
      var S = 0;
      var multipliers = [2, 3, 4, 5, 6, 7];
      
      while (T > 0) {
        var digit = T % 10;
        S += digit * multipliers[M % 6];
        M++;
        T = Math.floor(T / 10);
      }
      
      var remainder = 11 - (S % 11);
      
      if (remainder === 11) return 0;
      if (remainder === 10) return 'K';
      return remainder;
    }
    // eslint-disable-next-line
    const cleanRut = Rut.replace(/[\.\-]/g, '');
    let verif = (cleanRut.slice(-1));
    if (verif === 'k'){ verif = 'K'};

    if (String(dgv(cleanRut.slice(0,-1))) === verif){
      WhoCall("p&l", SelectedName, Rut)
    } else {
      window.alert('RUT invalido.')
    }
  };
  async function WhoCall(type , name, rut) {
    setWait(true)
    try {
      const data = await APICALL({
        apicall: 'WH_fetch',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo,
        type: type,
        name: name,
        rut : rut
      });
      if (data.data.client) {
        if (data.data.client === 'Sandbox'){
          console.log('caso sandbox')
          setWHOreply(data.data)
          if (data.data.laboral.causas_totales || data.data.criminal.data?.length > 0){
            saveWarning( name , true, rut );
          } else {
            saveWarning( name , false, rut );
          }
        } else {
          console.log('caso produccion')
          setWHOreply(data)
          if (data.laboral.causas_totales || data.criminal.data?.length > 0){
            saveWarning( name , true, rut );
          } else {
            saveWarning( name , false, rut );
          }
        }
      } else {
        console.log('caso excepcion')
          setWHOreply(data.data)
      }

      setShowPopup2(false)
      setShowPopup3(true)
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }

    setTimeout(() => {
      setWait(false);
    }, 500);
  };
  async function saveWarning( Namedata , warning, Rut ) {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.currentsave,
        U_emp: sessionStorage.Grupo,
        CVData: { name: Namedata, Warning: warning, RUT: Rut}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }

    const data2 = await APICALL({
      apicall: 'PD_fetch',
      code: sessionStorage.process,
      U_emp: sessionStorage.Grupo
    });

    if (data2.CVData){
      setCVlist(data2.CVData);
    }
  };
  const ToggleCategorySelection = (category) => {
    setSelectedCategories((prevCategories) => {
      const categoriesArray = prevCategories ? prevCategories.split('&') : [];
  
      if (categoriesArray.includes(category)) {
        const updatedArray = categoriesArray.filter((item) => item !== category);
        return updatedArray.sort().reverse().join('&');
      } else {
        return [...categoriesArray, category].sort().reverse().join('&');
      }
    });
  };


  // Revision de candidatos
  async function LookCV( data ) {

    const apiCallType = data.includes('CVE_') ? 'CV_fetch' : 'TB_fetch';


    try {
      const reply = await APICALL({
        apicall: apiCallType,
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo,
        id_code: data
      });

      function replaceNullStrings(obj) {
        for (const key in obj) {
          if (obj[key] === 'Null' || obj[key] === null) {
            obj[key] = ' - - -';
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            replaceNullStrings(obj[key]);
          }
        }
        return obj;
      }
      
      let profile 

      if (apiCallType === 'CV_fetch'){
        profile = replaceNullStrings(reply.data)
      } else {
        profile = reply.data
      }

      const { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments } = profile;
      const cv = { personalInfo, socialNetworks, workExperience, presentation, studies, multipleDocuments };

      setCV(cv);
  
    } catch (error) {
      console.error('Error during fetch:', error);
      setCV(null)
    }

  };
  async function handleRatingChange (newRating) {
    setScore(newRating);
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === SelectedCVId
          ? { ...cv, stars4: newRating} 
          : cv
      )
    );
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo,
        CVData: { name: SelectedCVId, stars4: newRating}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }

  };
  const SortCVlist = () => {
    const sortedList = [...CVlist].sort((a, b) => {
      if (SortType === 'score') {
        const notaA = a.Nota ? JSON.parse(a.Nota) : 0;
        const notaB = b.Nota ? JSON.parse(b.Nota) : 0;
        return notaB - notaA; 
      } else if (SortType === 'stars') {
        const starsA = a.stars4 ? a.stars4 : 0;
        const starsB = b.stars4 ? b.stars4 : 0;
        return starsB - starsA; 
      } else {
        return a.name.localeCompare(b.name); 
      }
    });

    if (SortType === 'chrono') {
      return CVlist;
    } else {
      return sortedList;
    }
  };


  // Revicion y Edicion de formulario
  async function handleformcheck(NameC, NameR) {

    setWait(true)
  
    try {
      const resp = await APICALL({
        apicall: 'FM_fetch',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo,
        FormID: NameR+'_'+NameC
      });
      setForm(resp);
      return resp;

    } catch (error) {
      console.error('Error during fetch:', error);
      return [];
    }

  };
  const AddNota = () => {
    const newNotes = [
      { title: 'Observacion Adicional', content: '', type: 'TextoNotas' }
    ];
    setForm((prevForm) => [...prevForm, ...newNotes]);
  };
  const RemoveNota = (indexToRemove) => {
    const updatedForm = Form.filter((_, index) => index !== indexToRemove);
    setForm(updatedForm);
  };
  const handleNotesChange = (index, field, value) => {
    const updatedForm = [...Form];
    updatedForm[index][field] = value;
    setForm(updatedForm);
  };
  async function UpdateForm () {
    await APICALL({
      apicall: 'FM_submmit',
      FormID: SelectedNameR ,
      Name: SelectedName,
      Formreply: Form
    });
  };


  // Formato de datos
  const FormatJobData = (jobData) => {
    const title = `<h2 style='color: var(--light); background: var(--main); padding: 1vh'>EXPERIENCIA LABORAL</h2>\n\n`;
  
    if (!jobData || !Array.isArray(jobData) || jobData.length === 0) {
      return `${title}<h2 style='padding-left: 2vh'>No hay experiencia laboral disponible</h2><br/>`;
    }
  
    const parseDate = (dateStr) => {
      if (!dateStr) return new Date(0);
  
      const parts = dateStr.split('/').map(Number);
  
      if (parts.length === 3) {
        const [day, month, year] = parts;
        return new Date(year, month - 1, day);
      } else if (parts.length === 2) {
        const [month, year] = parts;
        return new Date(year, month - 1, 1);
      } else if (parts.length === 1 && parts[0] >= 1000) {
        const year = parts[0];
        return new Date(year, 0, 1);
      }
  
      return new Date(0); 
    };
  
    const sortedJobs = jobData.slice().sort((a, b) => {
      return parseDate(b.fromDate) - parseDate(a.fromDate);
    });
  
    const formattedJobs = sortedJobs.map(job => {
      const achievements = job.achievements
        ? (job.achievements.includes('\n?') 
          ? job.achievements.split('\n?').filter(line => line.trim() !== '') 
          : job.achievements.split('\n').filter(line => line.trim() !== ''))
        : [];
  
      const cleanedAchievements = achievements.map(achievement => 
        achievement.replace(/^[*•-]\s*/, '').trim()
      );
  
      const formattedAchievements = cleanedAchievements.length > 0 
        ? cleanedAchievements.map(achievement => `● ${achievement}`).join('</br>')
        : 'Sin logros especificados';
  
      return `
          <div>
            <strong style='color: #333;'>${job.jobPosition ? job.jobPosition.toUpperCase() : 'Sin puesto especificado'}</strong> </br>
            <span style='color: #666;'>${job.companyName || 'Empresa desconocida'} || ${job.companyActivity ? job.companyActivity.description : 'Actividad no especificada'}</span></br>
            <span style='color: #666;'>${job.fromDate || 'Fecha desconocida'} - ${job.toDate || 'Presente'}</span></br>
            <p style='color: #666;'>${formattedAchievements}</p>
            <hr style='border: 0.2vh solid var(--mid-main);'/>
          </div>
      `.trim();
    }).join('</br>');
    
    return `${title}${formattedJobs}`;
  };
  const FormatStudyData = (studyData) => {
    const title = `<h2 style='color: var(--light); background: var(--main); padding: 1vh'>FORMACIÓN ACADÉMICA</h2>\n\n`;
  
    if (
      !studyData || 
      !studyData.higherEducation || // Verifica si higherEducation existe
      !Array.isArray(studyData.higherEducation) // Verifica si es un arreglo
    ) {
      return `${title}<h2 style='padding-left: 2vh'>No hay formación académica disponible</h2><br/>`;
    }
    
  
    const sortedStudies = studyData.higherEducation.slice().sort((a, b) => new Date(b.entryYear) - new Date(a.entryYear));
  
    const formattedStudies = sortedStudies.map(study => {
      return `
          <div>
            <strong style='color: #333;'>${study.otherCareer ? study.otherCareer.toUpperCase() : ''}</strong>
            ${study.minor ? `<span style='color: #666;'> - ${study.minor.toUpperCase()}</span>` : ''}</br>
            <span style='color: #666;'>${study.otherInstitution || ''}</span></br>
            <span style='color: #666;'>${study.statusName || ''}</span></br>
            <span style='color: #666;'>${study.entryYear} ~ ${study.graduationyear || 'Presente'}</span></br>
            <hr style='border: 0.2vh solid var(--mid-main);'/>
          </div>
      `.trim();
    }).join('</br>');
  
    return `${title}${formattedStudies}`;
  };
  const FormatPersonalData = ( PD ) => {

    const isValidURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch (_) {
        return false;
      }
    };

    const [day, month, year] = PD.personalInfo.birthDate  ? PD.personalInfo.birthDate.split('/').map(Number) 
                                                          : [null, null, null];

    const birthdate = new Date(year, month - 1, day);
    const today = new Date();
  
    let age = PD.personalInfo.birthDate   ? today.getFullYear() - birthdate.getFullYear()
                                          : null;
    const monthDiff = PD.personalInfo.birthDate ? today.getMonth() - birthdate.getMonth()
                                                : null;
  
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }
  
    return `
      <hr style='border: 0.2vh solid var(--mid-main);'/>
      <strong>${PD.personalInfo.firstName} ${PD.personalInfo.lastName} ${age ? ` |  ${age} años` : '' }</strong> 
      <hr style='border: 0.2vh solid var(--mid-main);'/>
      ${PD.presentation || 'Sin Descripción'}
      <hr style='border: 0.2vh solid var(--mid-main);'/>
      Facebook: 
      ${PD.socialNetworks.facebookLink || ' - - -'} 
      Twitter: 
      ${PD.socialNetworks.twitterLink || ' - - -'} 
      LinkedIn: 
      ${isValidURL(PD.socialNetworks.linkedinLink)  
        ? `<a href='${PD.socialNetworks.linkedinLink.trim()}' target='_blank' rel='noopener noreferrer'>${PD.personalInfo.firstName} ${PD.personalInfo.lastName}</a>` 
        : ' - - -'}
      <hr style='border: 0.2vh solid var(--mid-main);'/>
      🏠 
      ${PD.personalInfo.communeName} 
      ${PD.personalInfo.regionName 
        ? (PD.personalInfo.regionName.includes('Región') 
            ? PD.personalInfo.regionName 
            : 'Region ' + PD.personalInfo.regionName) 
        : ''} 
      ${PD.personalInfo.residenceCountry ? `${PD.personalInfo.residenceCountry.description}` : ''}
    
      ☎️ 
      ${PD.personalInfo.phoneNumbers[0].number || 'N/A'}

      ✉️ 
      ${PD.personalInfo.emails.primaryEmail || 'N/A'}
      <hr style='border: 0.2vh solid var(--mid-main);'/>
    `.trim();
  };

  // Exporte CVs
  async function HandleCVpropio() {
    const curriculum = CV.multipleDocuments.dataDocument.find(
      doc => doc.documentTypeName === 'Currículum adicional'
    );

    if (curriculum) {
      window.open(curriculum.documentPath);
    } else {
      console.error('Document not found.');
    }
  };
  async function ExportToPDFCV(ciego) {
    setWait(true);
    const doc = new jsPDF({
      format: 'a4',
      compress: true,
    });
  
    // Paleta de colores de la página
    const colors = {
      light: "#F5F6FF",
      midLight: "#DDE1FE",
      midMain: "#b0b8f3",
      main: "#8993f8",
      darkMain: "#7A84D4",
      action: "#6A7AE2",
      darkAction: "#4F5CA8",
      dark: "#50577A"
    };
    const hexToRgb = (hex) => {
      const r = parseInt(hex.substring(1, 3), 16) / 255;
      const g = parseInt(hex.substring(3, 5), 16) / 255;
      const b = parseInt(hex.substring(5, 7), 16) / 255;
      return { r, g, b };
    };
  
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10; // Aumentado ligeramente para un aspecto más limpio
  
    const logoRenee = new Image();
    logoRenee.src = '/favicon.png';
    const logoWidth = 25;
    const logoHeight = 25;
  
    // Ajuste de proporciones para un aspecto más moderno
    const leftColumnWidth = (pageWidth - 3 * margin) * 0.33; // Ligeramente más estrecha
    const rightColumnWidth = (pageWidth - 3 * margin) * 0.67;
    const fontsize = 10;
    const titleFontSize = 14; // Título más grande para mejor jerarquía visual
    const subtitleFontSize = 12; // Para subtítulos
    const contentMarginTop = 15; // Más espacio arriba
    let yLeft = contentMarginTop;
    let yRight = contentMarginTop;
    
    // Track current page for each column
    let leftColumnPage = 1;
    let rightColumnPage = 1;
    let currentPage = 1;
  
    // Estilos para elementos visuales
    const iconSize = 3;
    const sectionSpacing = 5; // Espaciado entre secciones
  
    // Función para dibujar el fondo de la columna izquierda con un diseño más moderno
    const drawLeftColumnBackground = () => {
      const bgColor = hexToRgb(colors.midLight);
      doc.setFillColor(bgColor.r * 255, bgColor.g * 255, bgColor.b * 255);
      doc.rect(0, 0, leftColumnWidth + 1.5 * margin, pageHeight, 'F');
      
      // Añadir un elemento decorativo superior
      const headerColor = hexToRgb(colors.main);
      doc.setFillColor(headerColor.r * 255, headerColor.g * 255, headerColor.b * 255);
      doc.rect(0, 0, leftColumnWidth + 1.5 * margin, 12, 'F');
      
      // Añadir línea separadora sutil entre columnas
      const separatorColor = hexToRgb(colors.midMain);
      doc.setDrawColor(separatorColor.r * 255, separatorColor.g * 255, separatorColor.b * 255);
      doc.setLineWidth(0.5);
      doc.line(leftColumnWidth + 1.5 * margin, 0, leftColumnWidth + 1.5 * margin, pageHeight);
    };
    drawLeftColumnBackground();
  
    // Función para dibujar iconos
    const drawIcon = (x, y, type) => {
      // Make sure we're on the correct page before drawing the icon
      if (doc.internal.getCurrentPageInfo().pageNumber !== leftColumnPage) {
        doc.setPage(leftColumnPage);
      }
      
      const iconColor = hexToRgb(colors.darkAction);
      doc.setFillColor(iconColor.r * 255, iconColor.g * 255, iconColor.b * 255);
      
      switch(type) {
        case 'punto':
          doc.circle(x, y-1, iconSize / 2, 'F');
          break;
        case 'phone':
          doc.rect(x - iconSize / 2, y - iconSize / 2, iconSize, iconSize, 'F');
          break;
        case 'location':
          // Triángulo simple para ubicación
          doc.triangle(
            x, y - iconSize / 2,
            x - iconSize / 2, y + iconSize / 2,
            x + iconSize / 2, y + iconSize / 2,
            'F'
          );
          break;
        case 'linkedin':
          doc.circle(x, y, iconSize / 2, 'F');
          break;
        case 'facebook':
          doc.rect(x - iconSize / 2, y - iconSize / 2, iconSize, iconSize, 'F');
          break;
        default:
          doc.circle(x, y, iconSize / 2, 'F');
      }
    };
  
    // Function to switch to a specific page
    const switchToPage = (pageNum) => {
      if (doc.internal.getCurrentPageInfo().pageNumber !== pageNum) {
        doc.setPage(pageNum);
      }
      currentPage = pageNum;
    };
  
    // Function to add a new page and set up backgrounds
    const addNewPage = () => {
      doc.addPage();
      currentPage++;
      drawLeftColumnBackground();
      return currentPage;
    };
    
  
    // Cargar la imagen del perfil con un marco moderno
    var img = new Image();
    img.src = CV.personalInfo.picture ? CV.personalInfo.picture + '?not-from-cache-please' : '/default.png';
    const profileSize = 45;
    const profileX = (leftColumnWidth - profileSize) / 2 + 5;
    const profileY = contentMarginTop;
    doc.addImage(img, 'JPEG', profileX, profileY, profileSize, profileSize);
    yLeft = profileY + profileSize + 10; // Más espacio debajo de la foto
  
    // Modified printText function to handle column overflow correctly
    const printText = (text, x, y, style = 'normal', columnWidth, addMargin = true, isLeftColumn = false) => {
      // First switch to the appropriate page
      if (isLeftColumn) {
        switchToPage(leftColumnPage);
      } else {
        switchToPage(rightColumnPage);
      }
      
      let fontSize = fontsize;
      let fontStyle = 'normal';
      let textColor = { r: 0, g: 0, b: 0 }; // Negro por defecto
      
      // Configurar estilos basados en el tipo de texto
      switch(style) {
        case 'title':
          fontSize = titleFontSize;
          fontStyle = 'bold';
          const titleColor = hexToRgb(colors.dark);
          textColor = titleColor;
          break;
        case 'subtitle':
          fontSize = subtitleFontSize;
          fontStyle = 'bold';
          const subtitleColor = hexToRgb(colors.darkAction);
          textColor = subtitleColor;
          break;
        case 'company':
          fontSize = fontsize + 1;
          fontStyle = 'bold';
          const companyColor = hexToRgb(colors.darkAction);
          textColor = companyColor;
          break;
        case 'date':
          fontSize = fontsize - 1;
          fontStyle = 'italic';
          const dateColor = hexToRgb(colors.dark);
          textColor = dateColor;
          break;
        case 'highlight':
          fontStyle = 'bold';
          const highlightColor = hexToRgb(colors.action);
          textColor = highlightColor;
          break;
        default:
          const defaultColor = hexToRgb(colors.dark);
          textColor = defaultColor;
      }
  
      doc.setFont('helvetica', fontStyle);
      doc.setFontSize(fontSize);
      doc.setTextColor(textColor.r * 255, textColor.g * 255, textColor.b * 255);
  
      const lineHeight = fontSize * 0.5;
      const textLines = doc.splitTextToSize(text, columnWidth);
      
      // Estimación de altura para verificar si excederá la página
      const estimatedHeight = textLines.length * lineHeight + (addMargin ? 4 : 0);
      
      // If text will exceed the page height
      if (y + estimatedHeight > pageHeight - margin - 20 && style !== 'title') {
        if (isLeftColumn) {
          // For left column, if right column has content on current page, start from that page
          // otherwise create a new page
          if (rightColumnPage === leftColumnPage && yRight <= y) {
            // Right column has content on this page and hasn't reached where left is
            // We'll allow the right column to continue on this page
            leftColumnPage = addNewPage();
            y = contentMarginTop;
            yLeft = y;
          } else if (rightColumnPage > leftColumnPage) {
            // Right column is already on a later page, move left to that page
            switchToPage(rightColumnPage);
            leftColumnPage = rightColumnPage;
            y = contentMarginTop;
            yLeft = y;
          } else {
            // Add a new page for both columns
            leftColumnPage = addNewPage();
            rightColumnPage = leftColumnPage;
            yLeft = contentMarginTop;
            yRight = contentMarginTop;
            y = contentMarginTop;
          }
        } else {
          // For right column
          if (leftColumnPage === rightColumnPage && yLeft <= y) {
            // Left column has content on this page but hasn't reached where right is
            rightColumnPage = addNewPage();
            y = contentMarginTop;
            yRight = y;
          } else if (leftColumnPage > rightColumnPage) {
            // Left column is already on a later page, move right to that page
            switchToPage(leftColumnPage);
            rightColumnPage = leftColumnPage;
            y = contentMarginTop;
            yRight = y;
          } else {
            // Add a new page for both columns
            rightColumnPage = addNewPage();
            leftColumnPage = rightColumnPage;
            yLeft = contentMarginTop;
            yRight = contentMarginTop;
            y = contentMarginTop;
          }
        }
      }
      
      // Imprimir cada línea
      textLines.forEach((subLine) => {
        doc.text(x, y, subLine);
        y += lineHeight;
      });
      
      // Añadir línea decorativa después de títulos
      if (style === 'title') {
        const lineColor = hexToRgb(colors.main);
        doc.setDrawColor(lineColor.r * 255, lineColor.g * 255, lineColor.b * 255);
        doc.setLineWidth(1);
        doc.line(x, y, x + columnWidth / 2, y);
        y += 4;
      }
      
      if (addMargin) y += 4;
      
      // Actualizar las posiciones globales
      if (isLeftColumn) {
        yLeft = y;
      } else {
        yRight = y;
      }
      
      return y;
    };
  
    // Calcular la edad
    let age;
    let ageText = "";
    const [day, month, year] = CV.personalInfo.birthDate.split('/').map(Number);
    if (!isNaN(day) && !isNaN(month) && !isNaN(year) && year > 1900) {
      const birthdate = new Date(year, month - 1, day);
      const today = new Date();
  
      age = today.getFullYear() - birthdate.getFullYear();
      const monthDiff = today.getMonth() - birthdate.getMonth();
  
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
        age--;
      }
      
      if (!isNaN(age) && age > 0 && age < 120) {
        ageText = `${age} Años`;
      }
    }
  
    // Ensure we're on the first page for the left column content
    switchToPage(leftColumnPage);
    
    // Imprimir nombre y edad con estilo destacado, ajustando si es necesario
    const nameText = `${CV.personalInfo.firstName} ${CV.personalInfo.lastName}`;
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(titleFontSize);
    const nameColor = hexToRgb(colors.dark);
    doc.setTextColor(nameColor.r * 255, nameColor.g * 255, nameColor.b * 255);
    const maxNameWidth = leftColumnWidth - margin;
    const nameWidth = doc.getStringUnitWidth(nameText) * titleFontSize / doc.internal.scaleFactor;
    if (nameWidth > maxNameWidth) {
      // Si el nombre es demasiado largo, dividirlo en líneas
      const nameLines = doc.splitTextToSize(nameText, maxNameWidth);
      
      // Centrar cada línea
      nameLines.forEach((line, index) => {
        const lineWidth = doc.getStringUnitWidth(line) * titleFontSize / doc.internal.scaleFactor;
        const lineX = ((leftColumnWidth + margin) - lineWidth) / 2;
        doc.text(line, lineX, yLeft + index * titleFontSize * 0.6);
      });
      
      // Actualizar la posición Y para la edad
      yLeft += nameLines.length * titleFontSize * 0.6;
    } else {
      // Si el nombre cabe en una línea, centrarlo como antes
      const nameX = ((leftColumnWidth + margin) - nameWidth) / 2;
      doc.text(nameText, nameX, yLeft);
      yLeft += titleFontSize * 0.5;
    }
    
    // Edad centrada
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(fontsize);
    if (ageText !== ''){
      const ageWidth = doc.getStringUnitWidth(ageText) * fontsize / doc.internal.scaleFactor;
      const ageX = ((leftColumnWidth + margin) - ageWidth) / 2;
      doc.text(ageText, ageX, yLeft);
    }
  
    // Sección de presentación
    yLeft = printText('', margin, yLeft - 2 , 'title', leftColumnWidth, true, true);
    if (CV.presentation){
      yLeft = printText(CV.presentation, margin, yLeft, 'normal', leftColumnWidth, true, true);
      yLeft += sectionSpacing;
    }
  
    // Start the right column now - if it's independent from left column
    switchToPage(rightColumnPage);
    // Columna derecha - Experiencia laboral
    yRight = printText('EXPERIENCIA LABORAL', leftColumnWidth + 2 * margin, yRight, 'title', rightColumnWidth, true, false);
  
    // Back to left column to continue
    switchToPage(leftColumnPage);
    // Información de contacto con iconos
    if (!ciego) {
      yLeft = printText('CONTACTO', margin, yLeft, 'subtitle', leftColumnWidth, true, true);
    
      // Email con icono
      drawIcon(margin + iconSize, yLeft, 'punto');
      yLeft = printText(CV.personalInfo.emails.primaryEmail, margin + iconSize * 2 + 2, yLeft, 'normal', leftColumnWidth - iconSize * 2 - 2, true, true);
      
      // Teléfono con icono
      drawIcon(margin + iconSize, yLeft, 'punto');
      yLeft = printText(CV.personalInfo.phoneNumbers[0].number, margin + iconSize * 2 + 2, yLeft, 'normal', leftColumnWidth - iconSize * 2 - 2, true, true);
      
      // Dirección con icono
      drawIcon(margin + iconSize, yLeft, 'punto');
      const addressText = ` ${CV.personalInfo.address ? CV.personalInfo.address + '\n' : ''}${CV.personalInfo.communeName + '\n' || ''}${CV.personalInfo.regionName !== ' - - -'? 'Región de ' + CV.personalInfo.regionName :  ''} `;
      yLeft = printText(addressText, margin + iconSize * 2 + 2, yLeft, 'normal', leftColumnWidth - iconSize * 2 - 2, true, true);
      
      yLeft += sectionSpacing/2;
      
      // Redes sociales
      yLeft = printText('REDES SOCIALES', margin, yLeft, 'subtitle', leftColumnWidth, true, true);
      
      // LinkedIn con icono
      if (CV.socialNetworks.linkedinLink && CV.socialNetworks.linkedinLink !== ' - - - ') {
        drawIcon(margin + iconSize, yLeft, 'punto');
        yLeft = printText(CV.socialNetworks.linkedinLink, margin + iconSize * 2 + 2, yLeft, 'normal', leftColumnWidth - iconSize * 2 - 2, true, true);
      }
      
      // Facebook con icono
      if (CV.socialNetworks.facebookLink && CV.socialNetworks.facebookLink !== ' - - - ') {
        drawIcon(margin + iconSize, yLeft, 'punto');
        yLeft = printText(CV.socialNetworks.facebookLink, margin + iconSize * 2 + 2, yLeft, 'normal', leftColumnWidth - iconSize * 2 - 2, true, true);
      }
    }
    
    // Switch back to right column to continue with experience
    switchToPage(rightColumnPage);
    
    // Procesar experiencia laboral con mejor formato
    if (CV.workExperience && CV.workExperience !== ' - - -') {
      // En la sección donde procesas la experiencia laboral, antes de imprimir cada trabajo
      CV.workExperience
        .slice()
        .sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate))
        .forEach(job => {
          // Calcular la altura aproximada que ocupará todo el bloque
          const jobTitleHeight = titleFontSize * 0.5 + 4;
          const dateHeight = fontsize * 0.5 + 4;
          
          // Calcular cuántas líneas ocuparán los logros
          let achievementsHeight = 0;
          const achievementLines = job.achievements.split('\n')
            .filter(achievement => achievement.trim() !== '');
          
          achievementLines.forEach(achievement => {
            const lines = doc.splitTextToSize(achievement, rightColumnWidth - 6).length;
            achievementsHeight += lines * fontsize * 0.5 + 4;
          });
          
          const totalBlockHeight = jobTitleHeight + dateHeight + achievementsHeight + 4;
          
          // Verificar si el bloque completo cabe en la página actual
          if (yRight + totalBlockHeight > pageHeight - margin - 20) {
            // If we're on the same page as the left column
            if (rightColumnPage === leftColumnPage) {
              // Check if left column still has space
              if (yLeft + 50 < pageHeight - margin - 20) {
                // Continue with left column content first
                switchToPage(leftColumnPage);
                // Right column will continue in next loop iteration
                return;
              }
            }
            
            // Need a new page for right column
            rightColumnPage = addNewPage();
            yRight = contentMarginTop;
            
            // If left column is behind, bring it to the same page
            if (leftColumnPage < rightColumnPage) {
              leftColumnPage = rightColumnPage;
              yLeft = contentMarginTop;
            }
          }
          
          // Ahora imprimir el bloque completo sabiendo que cabe en la página
          switchToPage(rightColumnPage);
          yRight = printText(`${job.companyName} | ${job.jobPosition}`, leftColumnWidth + 2 * margin, yRight, 'company', rightColumnWidth, false, false);
          
          const dateText = `${job.fromDate} - ${job.toDate || 'PRESENTE'}`;
          yRight = printText(dateText, leftColumnWidth + 2 * margin, yRight, 'date', rightColumnWidth, true, false);
          
          achievementLines.forEach(achievement => {
            switchToPage(rightColumnPage);
            const bulletColor = hexToRgb(colors.action);
            doc.setFillColor(bulletColor.r * 255, bulletColor.g * 255, bulletColor.b * 255);
            doc.circle(leftColumnWidth + 2 * margin + 2, yRight - 1, 1.5, 'F');
            yRight = printText(achievement, leftColumnWidth + 2 * margin + 6, yRight, 'normal', rightColumnWidth - 6, true, false);
          });
          
          yRight += 4;
        });
    } else {
      yRight = printText('No hay experiencia laboral disponible.', leftColumnWidth + 2 * margin, yRight, 'normal', rightColumnWidth, true, false);
    }
  
    // Espacio entre secciones de la columna derecha
    yRight += sectionSpacing;
    
    // Inteligently determine where to put education section
    // If left column has more space than right, continue with left first
    if (pageHeight - yLeft > pageHeight - yRight + 50 && leftColumnPage === rightColumnPage) {
      // There's more space in the left column, let's fill that before continuing with education
      // Additional left column content would go here if needed
      // For now we'll continue with education
    }
    
    // Verificar si necesitamos una nueva página para la sección de educación
    if (yRight + 60 > pageHeight - margin - 20) {
      // Check if we can fit education section after the left column on current page
      if (leftColumnPage === rightColumnPage && yLeft + 100 < pageHeight - margin - 20) {
        // Left column still has space, let's use that page
        // Right column will automatically continue on next page
      } else {
        // Need a new page for both columns
        rightColumnPage = addNewPage();
        if (leftColumnPage < rightColumnPage) {
          leftColumnPage = rightColumnPage;
        }
        yLeft = contentMarginTop;
        yRight = contentMarginTop;
      }
    }
  
    // Educación
    switchToPage(rightColumnPage);
    yRight = printText('EDUCACIÓN', leftColumnWidth + 2 * margin, yRight, 'title', rightColumnWidth, true, false);
    
    // Procesar educación con mejor formato
    if (CV.studies.higherEducation && CV.studies.higherEducation !== ' - - -') {
      CV.studies.higherEducation
        .slice()
        .sort((a, b) => new Date(b.entryYear) - new Date(a.entryYear))
        .forEach(edu => {
          // Verificar si necesitamos una nueva página para este ítem de educación
          if (yRight + 30 > pageHeight - margin - 20) {
            // If left column still has space on current page
            if (leftColumnPage === rightColumnPage && yLeft + 50 < pageHeight - margin - 20) {
              // Let left column content continue here
              // Right column will move to next page
              rightColumnPage = addNewPage();
              yRight = contentMarginTop;
            } else {
              // Add a new page for both columns
              rightColumnPage = addNewPage();
              if (leftColumnPage < rightColumnPage) {
                leftColumnPage = rightColumnPage;
                yLeft = contentMarginTop;
              }
              yRight = contentMarginTop;
            }
          }
          
          switchToPage(rightColumnPage);
          // Carrera con estilo destacado
          const careerText = edu.otherCareer || edu.minor || '';
          if (careerText) {
            yRight = printText(careerText, leftColumnWidth + 2 * margin, yRight, 'company', rightColumnWidth, false, false);
          }
  
          // Institución
          const institutionText = edu.institution?.name || edu.otherInstitution || '';
          if (institutionText) {
            yRight = printText(institutionText, leftColumnWidth + 2 * margin, yRight, 'normal', rightColumnWidth, false, false);
          }
  
          // Fecha con estilo sutil
          const eduDateText = `${edu.entryYear} - ${edu.graduationyear || 'PRESENTE'}`;
          yRight = printText(eduDateText, leftColumnWidth + 2 * margin, yRight, 'date', rightColumnWidth, true, false);
  
          // Espacio entre educaciones
          yRight += 4;
        });
    } else {
      yRight = printText('No hay información educativa disponible.', leftColumnWidth + 2 * margin, yRight, 'normal', rightColumnWidth, true, false);
    }
  
    // Añadir pie de página y números de página con estilo moderno
    const addFooterAndPageCount = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
  
        // Fondo de color para el pie de página
        const footerColor = hexToRgb(colors.midLight);
        doc.setFillColor(footerColor.r * 255, footerColor.g * 255, footerColor.b * 255);
        doc.rect(0, pageHeight - 15, pageWidth, 15, 'F');
  
        // Línea decorativa
        const footerLineColor = hexToRgb(colors.main);
        doc.setDrawColor(footerLineColor.r * 255, footerLineColor.g * 255, footerLineColor.b * 255);
        doc.setLineWidth(0.75);
        doc.line(0, pageHeight - 15, pageWidth, pageHeight - 15);
  
        // Número de página con estilo
        const pageText = `Página ${i} de ${pageCount}`;
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8);
        const pageNumColor = hexToRgb(colors.dark);
        doc.setTextColor(pageNumColor.r * 255, pageNumColor.g * 255, pageNumColor.b * 255);
        doc.text(margin, pageHeight - 6, pageText, { align: 'left' });
  
        // Posición para el logo
        const logoX = pageWidth - logoWidth;
        const logoY = pageHeight - logoHeight;
        
        // Añadir un borde redondeado con el color principal
        const borderColor = hexToRgb(colors.main);
        doc.setFillColor(255, 255, 255); // Fondo blanco para el logo
        doc.setDrawColor(borderColor.r * 255, borderColor.g * 255, borderColor.b * 255);
        doc.setLineWidth(0.7);
        
        // Dibujar un rectángulo redondeado con borde de color y fondo blanco
        // El radio de las esquinas es 3 para que sea ligeramente redondeado
        const borderPadding = 1.5; // Padding más pequeño para mantener el tamaño original
        doc.roundedRect(
          logoX - borderPadding, 
          logoY - borderPadding, 
          logoWidth * 0.8 + borderPadding * 2, 
          logoHeight * 0.8 + borderPadding * 2, 
          3, 3, 'FD' // 'FD' significa Fill and Draw (rellenar y dibujar borde)
        );
        
        // Agregar logo sobre el fondo
        doc.addImage(logoRenee, 'PNG', logoX, logoY, logoWidth * 0.8, logoHeight * 0.8);
      }
    };
    addFooterAndPageCount(doc);
  
    // Guardar el PDF con nombre apropiado
    if (!ciego) {
      doc.save(`CV_${CV.personalInfo.firstName}_${CV.personalInfo.lastName}.pdf`);
    } else {
      doc.save(`CV_${CV.personalInfo.firstName}_${CV.personalInfo.lastName}_Ciego.pdf`);
    }
  
    setTimeout(() => {
      setWait(false);
    }, 500);
  }
  

  // Filtros
  const [FilterQuery, setFilterQuery] = useState('');
  const HandleFilterChange = (e) => {
    const query = e.target.value.toLowerCase();
    setFilterQuery(query);
  };


  // Navegacion
  const goto10 = () => { navigate('/P_EvaluacionPL') };  
  const goto12 = () => { navigate('/P_Documentos') };


  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo
      });
      setFetchedData(data);
      if (data.CVData){
        setCVlist(data.CVData.reverse())
        setselectlist2(Array.isArray(data.SelectList2) 
        ? data.SelectList2               
        : data.SelectList2               
          ? [data.SelectList2]         
          : []);
        setselectlist3(Array.isArray(data.SelectList3) 
        ? data.SelectList3               
        : data.SelectList3               
          ? [data.SelectList3]         
          : []);
      }
    };
  
    fetchData();
    // eslint-disable-next-line
  }, []);


  //############################################################################################################
  //############################################################################################################

  return (

    <div className='BackGround'>

      {/* Popup Agregar Referencia */}
      <div className={ShowPopup ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup(false)} >
        </div>
        <div className={ShowPopup ? 'warningbox' : 'hidden'}>
          <h2>Agrega una nueva referencia para {SelectedName}</h2>
          <p style={{ alignItems: 'center', fontSize: '1.2rem' }}>
            Este se Agregara a la lista de referentes asociados al candidato {SelectedName}
          </p>
          <div 
            className='form-container'
            style={{ display: 'flex', flexDirection: 'column', gap: '1vh', padding: '1vh', width: '-webkit-fill-available' }}>
            <label>Nombre de la referencia: 
              <input
                type="text"
                style={{paddingLeft:'1vh'}}
                placeholder="Nombre"
                value={referenceName}
                onChange={(e) => setReferenceName(e.target.value)}
              />
            </label>
            <label>Teléfono de la referencia:
              <div style={{ display: "flex", alignItems: "center" }}>
                <select
                  style={{ marginRight: "0.5vh", padding: "0.5vh", width: "25%" }}
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                >
                  <option value="+56">Chile (+56)</option>
                  <option value="+51">Perú (+51)</option>
                  <option value="+54">Argentina (+54)</option>
                  <option value="+52">México (+52)</option>
                  <option value="+57">Colombia (+57)</option>
                  <option value=" "  >Otro Pais </option>
                </select>
                
                <input
                  type="number"
                  style={{ paddingLeft: "1vh", flex: 1 }}
                  placeholder="Número"
                  value={referenceNumber}
                  onChange={(e) => setReferenceNumber(e.target.value)}
                />
              </div>
            </label>
            <label>Correo de la referencia: 
              <input
                type="email"
                style={{paddingLeft:'1vh'}}
                placeholder="Correo electrónico"
                value={referenceEmail}
                onChange={(e) => setReferenceEmail(e.target.value)}
              />
            </label>
          </div>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={handleAddReference}> <i className='fa-regular fa-user'></i> &nbsp; Agregar Referencia</button>
            <button onClick={() => setShowPopup(false)}>Cancelar</button>
          </div>
        </div>
      </div> 

      {/* Popup Alertas Legales Confirmacion */}
      <div className={ShowPopup2 ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup2(false)} >
        </div>
        <div className={ShowPopup2 ? 'warningbox' : 'hidden'}>
        <h2>Confirmación de Check de Alertas Legales</h2>
        <p style={{ alignItems: 'center', fontSize: '1.2rem', overflow:'scroll' }}>
          <strong>Nombre: {SelectedName}</strong><br /><br />
          <strong>RUT: {SelectedRUT}</strong><br /><br />
          Ten en cuenta que este check de alertas legales puede realizarse <strong>únicamente una vez por candidato</strong>.<br/>
          Cualquier consulta posterior mostrará únicamente los resultados obtenidos en la primera consulta.<br/>
          Asegúrate de seleccionar las categorías que deseas consultar con cuidado.
        </p>


          <div style={{ display: 'flex', flexDirection: 'row', alignItems:'flex-end', gap: '1vh' }}>
          Seleccione la categoria a consultar:  <br /><br />

          {/* <div style={{ display: 'flex'}}>
            <button className='start-button'
                    onClick={() => toggleCategorySelection('c')}>Civil</button>
            <input
              type='checkbox'
              className='select-checkbox'
              checked={selectedCategories.includes('c')}
              onChange={() => toggleCategorySelection('c')}
            />
          </div> */}

          <div style={{ display: 'flex'}}>
            <button className='start-button'
                    onClick={() => ToggleCategorySelection('l')}>laboral</button>
            <input
              type='checkbox'
              className='select-checkbox'
              checked={SelectedCategories.includes('l')}
              onChange={() => ToggleCategorySelection('l')}
            />
          </div>

          <div style={{ display: 'flex'}}>
            <button className='start-button'
                    onClick={() => ToggleCategorySelection('p')}>Penal</button>
            <input
              type='checkbox'
              className='select-checkbox'
              checked={SelectedCategories.includes('p')}
              onChange={() => ToggleCategorySelection('p')}
            />
          </div>
    
        </div>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => {WhoCall(SelectedCategories, SelectedName, SelectedRUT)}}>Confirmar</button>
            <button onClick={() => setShowPopup2(false)}>Cancelar</button>
          </div>
        </div>
      </div> 

      {/* Popup Alertas Legales Resultados */}
      <div className={ShowPopup3 ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup3(false)} >
        </div>
        <div style={{width:'80%', height:'80%', left:'10%', overflow:'scroll'}} className={ShowPopup3 ? 'warningbox' : 'hidden'}>
          {WHOreply.error ? (
            <div style={{display:'flex', width:'-webkit-fill-available', justifyContent:'space-evenly'}}>
              {WHOreply.error}
            </div>
            ) : (
            <div>
              <h2>Detalles de la Respuesta</h2>

              <div style={{display:'flex', width:'-webkit-fill-available', justifyContent:'space-evenly'}}>
                {/* {WHOreply.civil && WHOreply.civil.data && (
                  <div>
                    <h3>Categoría Civil</h3>
                    <p><strong>Status:</strong> {WHOreply.civil.status}</p>
                    <p><strong>Causas Totales:</strong> {WHOreply.civil.defendant_records}</p>
    
                      {WHOreply.civil.data.map((record, index) => (
                        <div key={index} className='warninblock' >
                          <p><strong>Riesgo:</strong> {record.risk}</p>
                          <p><strong>Fecha:</strong> {record.date}</p>
                          <p><strong>Contraparte:</strong> {record.complainant}</p>
                          <p><strong>Nombre Contraparte:</strong> {record.complainant_name}</p>
                          <p><strong>Proceso:</strong> {record.process}</p>
                          <p><strong>RUT Contraparte:</strong> {record.complainant_dni}</p>
                          <p><strong>RIT:</strong> {record.rit}</p>
                          <p><strong>Tribunal:</strong> {record.court}</p>
                        </div>
                      ))}
                    </div>
                )} */}
    
                {WHOreply.laboral && WHOreply.laboral.data && (
                  <div>
                    <h3>Categoría Laboral</h3>
                    <p><strong>Status:</strong> {WHOreply.laboral.status}</p>
                    {/* <p><strong>Tipo Persona:</strong> {WHOreply.laboral.tipo_persona}</p> */}
                    {/* <p><strong>Causas Demandante:</strong> {WHOreply.laboral.causas_demandante}</p> */}
                    {/* <p><strong>Causas Participante:</strong> {WHOreply.laboral.causas_participante}</p> */}
                    <p><strong>Causas Totales:</strong> {WHOreply.laboral.causas_totales}</p>
    
                    {WHOreply.laboral.data.map((record, index) => (
                      <div key={index} className='warninblock' >
                        {/* <p><strong>Año:</strong> {record.ano}</p> */}
                        <p><strong>Fecha:</strong> {record.fecha_ingreso}</p>
                        <p><strong>Nombre Contraparte:</strong> {record.nombre_contraparte}</p>
                        <p><strong>Participación:</strong> {record.participacion}</p>
                        <p><strong>RIT:</strong> {record.rit}</p>
                        <p><strong>RUT Contraparte:</strong> {record.rut_contraparte}</p>
                        <p><strong>Tipo:</strong> {record.tipo}</p>
                        <p><strong>Tribunal:</strong> {record.tribunal}</p>
                      </div>
                    ))}
                  </div>
                )}
    
                {WHOreply.criminal && WHOreply.criminal.data && (
                    <div>
                      <h3>Categoría Penal</h3>
                      <p><strong>Status:</strong> {WHOreply.criminal.status}</p>
                      <p><strong>Causas Totales:</strong> {WHOreply.criminal.data.length}</p>
    
                      {WHOreply.criminal.data.map((record, index) => (
                        <div key={index} className='warninblock' >
                          <p><strong>Riesgo:</strong> {record.risk}</p>
                          <p><strong>Fecha:</strong> {record.date}</p>
                          <p><strong>Crimen:</strong> {record.crime}</p>
                          {/* <p><strong>Año:</strong> {record.year}</p> */}
                          <p><strong>RUC:</strong> {record.ruc}</p>
                          <p><strong>Tribunal:</strong> {record.court}</p>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </div>
          )}
          <div style={{ display: 'flex', position:'sticky', bottom:'0', gap: '1vh', padding: '1vh', width: '50%' }}>
            <button onClick={() => setShowPopup3(false)}>Volver</button>
          </div>
        </div>
      </div>

      {/* Popup Mensaje Referencia */}
      <div className={ShowPopup4 ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup4(false)} >
        </div>
        <div className={ShowPopup4 ? 'warningbox' : 'hidden'}>
          <h2>Confirma el envio del formulario:</h2>
            <p style={{ alignItems: 'center', fontSize: '1.2rem' }}>
              Se le enviara el formulario a: <strong>{referenceName}</strong> <br />
              Con la dirección de correo: <strong>{referenceEmail}</strong> <br /><br />
              Alternativamente, puedes copiar la URL para enviarla manualmente: <br /><br />
              <button 
                style={{ cursor: 'pointer' }} 
                onClick={() => copyToClipboard(`https://forms.renee.cl/?NR=${encodeURIComponent(referenceName)}&NC=${encodeURIComponent(SelectedName)}`)}
              >
                <strong> <i className='fa-solid fa-copy'></i> &nbsp; Copiar la URL del formulario</strong>
              </button> <br />
              O alternativamente puedes: <br /><br />
              <button 
                style={{ cursor: 'pointer' }} 
                onClick={() => window.open(`https://forms.renee.cl/?NR=${encodeURIComponent(referenceName)}&NC=${encodeURIComponent(SelectedName)}`)}
              >
                <strong> <i className="fa-solid fa-file-signature"></i> &nbsp; Rellenar los datos manualmente</strong>
              </button>
            </p>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={()=>{handleSendReference(); MailNotiff([referenceName], 'Formulario de Referencia')}}> <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Enviar Formulario de referencias </button>
            <button onClick={() => setShowPopup4(false)}>Cancelar</button>
          </div>
        </div>
      </div> 

      {/* Popup Envio Mails Rechazo Masivo */}
      <div className={ShowPopup5? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup5(false)} >
        </div>
        <div className={ShowPopup5? 'warningbox' : 'hidden'}>
          <h2>Confirma a quienes se les enviara el Mensaje de rechazo</h2>
          <div style={{border:'none', margin:'1vh'}} className='notas'>
            {CVlist.filter(reply => SelectList2.includes(reply.name))
                   .filter(reply => !SelectList3.includes(reply.name))
                   .filter(reply => !reply.Rejected)
                   .map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', width: '-webkit-fill-available', alignItems: 'center', marginBottom: '10px' }}>
                <button
                  className={!SelectedCandidates.includes(reply.name) ? 'start-button red' : 
                                                                        'start-button'}
                  style={{height:'5vh'}}
                  onClick={() => {
                    ToggleCandidateRejected(reply.name);
                  }}
                >
                  {reply.name}
                </button>
                <input  type='checkbox' 
                        className='select-checkbox'
                        style={{height:'5vh'}}
                        checked={!SelectedCandidates.includes(reply.name) && !reply.Rejected}
                        onChange={() => {
                          ToggleCandidateRejected(reply.name);
                        }}></input>
              </div>
            ))}
          </div>
          
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>           
            <button onClick={() => {
                const allCandidates = CVlist
                  .filter(reply => SelectList3.includes(reply.name))
                  .filter(reply => !reply.Rejected)
                  .map(reply => reply.name);
                
                setSelectedCandidates(allCandidates);
                console.log('Seleccionados: ' + SelectedCandidates)
              }}> 
              Marcar Todos 
            </button> 
            <button onClick={() => {
                const allCandidates = CVlist
                  .filter(reply => !SelectList3.includes(reply.name))
                  .filter(reply => !reply.Rejected)
                  .map(reply => reply.name);
                
                  const uniqueCandidates = [...new Set([...SelectedCandidates, ...allCandidates])];
    
                  setSelectedCandidates(uniqueCandidates);
                  console.log('Seleccionados: ' + SelectedCandidates)
              }}> 
              Desmarcar Todos 
            </button>
          </div>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => HandleMailAll(false)}> <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Enviar Notificacion </button>
            <button onClick={() => setShowPopup5(false)}> Cancelar</button>
          </div>

        </div>
      </div>  

      {/* Main */}
      <div className='typing-effect-container'>
        <h2 className='typing-effect'>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          🔎 Que tal si ahora Revisemos las referencias de nuestros candidatos! 🔎
        </h2>
      </div>

      <div className='MainBody'>

        {/* Lista de candidatos */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{ maxWidth: '20vw', marginRight:'1vh' }}>

          {/* Menu superior */}
          <div style={{display:'flex'}}>
          <h3 className='boxtitle'>Candidatos : { State ? CVlist.filter(cv => cv.id !== 'hidden')
                                                                .filter(cv => SelectList2.includes(cv.name)).length 
                                                        : CVlist.filter(cv => cv.id !== 'hidden')
                                                                .filter(cv => !cv.Rejected)
                                                                .filter(cv => SelectList2.includes(cv.name)).length }</h3>

            <button
              className='sortbutton On'
              onClick={() => {
                if (SortType === 'stars') {
                  setSortType('name');  
                } else if (SortType === 'name') {
                  setSortType('chrono'); 
                } else {
                  setSortType('stars');
                }
              }}
              style={{ borderRadius: '1vh 3vh 0vh 0vh' }}>
              {SortType === 'score' && (
                <>
                  <i className="fa-solid fa-percent"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'stars' && (
                <>
                  <i className="fa-regular fa-star"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'name' && (
                <>
                  <i className='fa-solid fa-arrow-down-a-z'></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'chrono' && (
                <>
                  <i className="fa-regular fa-clock"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
            </button>
          </div>

          {/* Menu botones */}
          <div style={{ position:'sticky', 
              border:'0.3vh solid var(--main)',
              borderRadius:'0vh 1vh 0vh 0vh',
              backgroundColor:'var(--light)',
              top: 0, zIndex: 4, 
              padding:'1vh'}} >
            <button 
                    onClick={() => exportToExcel(CVlist)}
                    >
              <i className='fa-solid fa-file-export'></i> &nbsp; Exporta las respuestas como XLSX
            </button>
            <button style={{marginTop:'1vh'}} 
                    className='redb'
                    onClick={()=> setShowPopup5(true)}>
              <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Notifica a los no seleccionados 
            </button>
          </div>

          {/* Filtro de candidatos */}
          <input
            style={{  position:'sticky', 
                      width:'-webkit-fill-available',
                      height:'2vh',
                      border:'0.3vh solid var(--main)',
                      borderTop:'none',
                      borderRadius:'0vh',
                      backgroundColor:'var(--light)',
                      top: 0, 
                      zIndex: 4, 
                      padding:'1vh'}}
            type='text'
            placeholder='Filtrar por nombre'
            className='filterinput'
            value={FilterQuery}
            onChange={HandleFilterChange}></input>

          {/* Lista de candidatos */}
          <div  className='notas'
                style={{justifyContent:'flex-start', borderTop:'none', borderRadius:'0vh 0vh 1vh 1vh', marginBottom:'19.5vh'}}>
          {CVlist && CVlist.length > 0 ? (
            SortCVlist()
              .filter(reply => SelectList2.includes(reply.name))
              .filter(reply => reply.Rejected === State || !reply.Rejected)
              .filter(reply => reply.name.toLowerCase().includes(FilterQuery))
              .map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', flexDirection:'column', marginBottom: '1vh' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5vh'}}>
                  <button
                    className={ reply.name ===  SelectedCVId  ? 'start-button highlighted' 
                                                              : reply.Warning === true  ? 'start-button yellow' 
                                                                                        : SelectList3.includes(reply.name)  ? 'start-button green' 
                                                                                                                            :'start-button'}
                    style={{  borderColor: reply.Rejected === true ? 'gray' : '',
                              backgroundColor: reply.Rejected ? 'var(--gray)' : ''}}
                    onClick={() => {
                      setWait(true);
                      setCV('')
                      setForm('');
                      setScore(reply.stars4);
                      LookCV(reply.id);
                      setScreentype('perfil')
                      setSelectedCVId(reply.name)
                      setSelectedName(reply.name)
                      setSelectedMail(reply.mail)
                      setselectedRUT(reply.RUT)
                      setTimeout(() => {
                        setWait(false);
                      }, 500);
                    }}>
                    {reply.name}
                  </button>

                  <button className={ !reply.stars4 ? 'middle-button' 
                                                    : JSON.parse(reply.stars4) < 3  ? 'middle-button red' 
                                                                                    : JSON.parse(reply.stars4) < 5  ? 'middle-button yellow' 
                                                                                                                    : 'middle-button green'}
                          style={{  maxWidth:'3vw',
                                    borderColor: reply.Rejected === true ? 'gray' : '',
                                    backgroundColor: reply.Rejected ? 'var(--gray)' : ''}}>
                            
                    {reply.stars4 ? JSON.parse(reply.stars4) : ' - '}{'☆'}
                  </button>
                  <input  type='checkbox' 
                          className='select-checkbox'
                          style={{  borderColor: reply.Rejected === true ? 'gray' : '',
                                    backgroundColor: reply.Rejected ? 'var(--gray)' : ''}}
                          checked={SelectList3 ? SelectList3.includes(reply.name) : false}
                          onChange={() => {
                            Handleselect3(reply.name);}}>
                  </input>
                </div>
                {reply.reference && reply.reference.length > 0 ? (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '0.5vh' }}>
                    <button 
                      onClick={() => {  setSelectedName(reply.name); 
                                        setReferenceName('');
                                        setReferenceEmail('');
                                        setShowPopup(true); }}
                      style={{ width: '85%' }}>
                      <i className='fa-regular fa-user'></i> &nbsp; Agrega una Referencia
                    </button>
                    {reply.reference.map((ref, index) => (
                      <div 
                        key={`${reply.name}-${index}`}
                        style={{display:'flex', flexDirection:'row', width:'85%'}}
                      >
                        <button 
                          style={{borderRadius:'1vh 0vh 0vh 1vh', borderRight:'none'}}
                          className={SelectedName === reply.name && SelectedMailR === ref.email ? 'sonbutton highlighted' : 'sonbutton'}
                          onClick={() => { 
                            setSelectedCVId(reply.name)
                            setSelectedName(reply.name)
                            setScreentype('preguntas')
                            setSelectedNameR(ref.name)
                            setSelectedMailR(ref.email)
                            handleformcheck(reply.name, ref.name).then( form =>{
                              setTimeout(() => {
                                setWait(false);
                              }, 500);
                            }) 
                          }}>
                          {ref.name}
                        </button>
                        <button 
                          style={{borderRadius:'0vh', width:'20%', justifyContent:'flex-end'}}
                          onClick={() => { 
                            window.open(`https://forms.renee.cl/?NR=${encodeURIComponent(ref.name)}&NC=${encodeURIComponent(reply.name)}`)
                          }}>
                          <i className="fa-solid fa-file-signature"></i>
                        </button>
                        <button 
                          style={{borderRadius:'0vh 1vh 1vh 0vh', width:'20%'}}
                          onClick={() => { 
                            setSelectedCVId(reply.name)
                            setSelectedName(reply.name)
                            setReferenceName(ref.name)
                            setReferenceEmail(ref.email)
                            setShowPopup4(true)
                          }}>
                          <i className="fa fa-paper-plane" aria-hidden="true"></i>
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '0.5vh' }}>
                    <button 
                      onClick={() => { setSelectedName(reply.name); setShowPopup(true); }}
                      style={{ width: '85%' }}>
                      <i className='fa-regular fa-user'></i> &nbsp; Agrega una Referencia
                    </button>
                  </div>
                )}
                </div>
            ))
          ) : (
            <button>No hay candidatos</button>
          )}
          </div>

        </div>

        {/* Respuestas Referentes */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{marginBottom:'-0.2vh'}}>

          {/* top bar */}
          <div style={{display:'flex'}}>
            <h3 style={{display:'flex', alignItems: 'center'}} 
                className='boxtitle'>{SelectedCVId ? SelectedCVId : ''}{SelectedNameR ? SelectedNameR : ''} 
              <div  className={SelectedCVId? '' : 'hidden'}
                    style={{marginTop:'0'}}>
              </div>
            </h3>     
          </div>

          {/* menu opciones */}
          <div  className= { Form.length > 0  ? 'notas' : 'hidden'} 
                style={{ display:'flex', 
                          height: '4vh',
                          gap: '1vh',
                          flexDirection:'row',
                          borderRadius:'0vh 1vh 0vh 0vh',
                          width:'-webkit-fill-available', 
                          overflow:'hidden'}}>
            <button className= { Form.length > 0  ? 'redb' : 'hidden'}
                    onClick={deleteReference}>
              <i className="fa-solid fa-x"></i> &nbsp; Eliminar al Referente
            </button>
           <button className= { Form.length > 0  ? ' ' : 'hidden'} onClick={AddNota}> <i className='fa-solid fa-plus'></i> &nbsp; Agrega bloque de notas</button> 
           <button className= { Form.length > 0  ? 'Save' : 'hidden'}  onClick={UpdateForm} style={{width:'200%'}}> <i className='fa-solid fa-floppy-disk'></i> &nbsp; Guardar Cambios</button>
          </div>

          {/* main block */}
          <div  className='notas' 
                style={{  overflow: 'scroll', 
                          overflowX:'hidden', 
                          marginTop:'-4vh',
                          borderRadius:'0vh 0vh 1vh 1vh',
                          borderTop: Form.length > 0  ? 'none' : '',
                          marginBottom: Form.length > 0  ? '14.4vh' : '8vh'}}>
            <div>
              {Wait === true ? (
                <div>
                  <div className='box'>
                    <img
                      src={'/favicon.png'}
                      alt='Profile'
                      style={{
                        width: '150px',
                        height: '150px',
                        objectFit: 'cover',
                        position:'stick'
                      }}/>
                    <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                    <div className='loader-container'>
                      <div  className='pulsing-dot'>
                      <div></div>
                      <div></div>
                      <div></div>
                      </div>
                    </div>
                  </div>
                </div>

              ) /* Formulario Referente */  : Form.length > 0 && ScreenType === 'preguntas' && SelectedName ?  (  
                <div>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                    {Form.map((block, index) => (
                      <div
                        key={index}
                        className='notas-content'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginBottom: '1vh',
                          marginRight: '1vh',
                          gap: '1vh',
                          overflow:'hidden',
                          borderColor: block.type === 'TextoNotas' ? 'var(--red)' : ''
                        }}>
                        <div style={{ flex: 1 }}>
                          <div style={{ display: 'flex', alignItems: 'baseline', justifyContent:'space-between' }}>
                            <span
                              className='notas-title'
                              style={{ margin:' 1vh 1vh 0vh 1vh', fontWeight: 'bold', height:'8vh' }}>
                              {block.title}
                            </span>
                            <button className={block.type === 'TextoNotas' ? 'box-button redb' : 'hidden'} 
                                    onClick={()=>RemoveNota(index)}>
                            <i className="fa-solid fa-x"></i>
                            </button>
                          </div>
    
                          {block.type === 'Texto' ? (
                            <textarea
                              value={block.content}
                              className='notas'
                              readOnly
                              placeholder='Editar contenido'
                              rows='5'
                              style={{ height: '10vh', marginBottom: '1vh' }}
                            />
                          ) : block.type === 'TextoNotas' ? (
                            <textarea
                              value={block.content}
                              className='notas'
                              onChange={(e) => handleNotesChange(index, 'content', e.target.value)}
                              placeholder='Ingrese sus notas'
                              rows='5'
                              style={{ height: '10vh', marginBottom: '1vh' }}
                            />
                          ) : block.type === 'Alt Unica' ? (
                            <div className='alternatives-container'>
                              {block.alternatives && block.alternatives.map((alternative, optIndex) => (
                                <label  key={optIndex} 
                                        className={ alternative.selected ? 'alternative-label green' : 'alternative-label' }>
                                  <input
                                    type='radio'
                                    className='alternative-checkbox'
                                    disabled 
                                    checked={alternative.selected || false}
                                  />
                                  {alternative.label}
                                </label>
                              ))}
                            </div>
                          ) : block.type === 'Alt Multiple' ? (
                            <div className='alternatives-container'>
                              {block.alternatives && block.alternatives.map((alternative, optIndex) => (
                                <label  key={optIndex} 
                                        className={ alternative.selected ? 'alternative-label green' : 'alternative-label' }>
                                  <input
                                    type='checkbox'
                                    className='alternative-checkbox'
                                    disabled 
                                    checked={alternative.selected || false}
                                  />
                                  {alternative.label}
                                </label>
                              ))}
                            </div>
                          ) : null}
                          
                        </div>      
                      </div>
                    ))}
                  </div>
                </div>
              ) /* Perfil Candidato */      : ScreenType === 'perfil' && SelectedName ? (
                <div style={{display:'flex', gap:'1vh'}}>
                  <div className='boxfit' style={{ maxWidth: '20vw' }}>
                    <div className='notas'>
                      <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <img
                          src={CV && CV.personalInfo.picture ? CV.personalInfo.picture : '/default.png'}
                          alt='Profile'
                          style={{
                            width: '150px',
                            height: '150px',
                            objectFit: 'cover',
                            borderRadius: '50%',
                            position:'stick'
                          }}
                        />
                        <div style={{marginTop:'1vh', marginBottom:'1vh'}}>
                          <StarRating initialRating={Score} onRatingChange={handleRatingChange} />
                        </div>
                        <details style={{width:'-webkit-fill-available'}}>
                          <summary className={CV ? '' : 'hidden'} style={{marginBottom:'0.5vh'}}> <i className='fa-solid fa-file-export'></i> &nbsp; Exportar CV</summary>
                          <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                            <button style={{marginBottom:'0.5vh', width:'80%'}} className={SelectedCVId ? '' : 'hidden'} onClick={() => ExportToPDFCV( false )}>
                              CV Completo Reneé</button>
                            <button style={{marginBottom:'0.5vh', width:'80%'}} className={SelectedCVId ? '' : 'hidden'} onClick={() => ExportToPDFCV( true )}>
                              CV Ciego Reneé</button>
                            {CV?.multipleDocuments?.dataDocument?.some(doc => doc.documentTypeName === 'Currículum adicional') && (
                            <button style={{marginBottom:'0.5vh', width:'80%'}} onClick={HandleCVpropio}>
                              CV propio</button>)}
                          </div>
                        </details>
                        <button className={ SelectedName  ? '' : 'hidden'}
                                style={{marginBottom:'0.5vh'}}
                                onClick={() => {
                                  if (SelectedRUT && SelectedRUT.toLocaleLowerCase()!== 'null'){
                                    setShowPopup2(true);
                                  } else {
                                    const userRut = window.prompt('Actualmente no contamos con un RUT asignado a este usuario.\nPor favor, ingrese el RUT:');
                                    
                                    if (userRut) {
                                      RutCheck(userRut);
                                    } 
                                  }
                                }}
                                >
                          <i className="fa-solid fa-passport"></i> &nbsp; Legal Check
                        </button>  
                        <button className={ SelectedName  ? 'redb' : 'hidden'}
                                style={{marginBottom:'1vh'}}
                                onClick={() => {
                                  DropCandidate(selectedMail,SelectedName)
                                }}>
                          <i className="fa-solid fa-x"></i> &nbsp; Rechazar al Candidato
                        </button>      


                        <div
                          dangerouslySetInnerHTML={{ __html: CV ? FormatPersonalData(CV) : '' }}
                          style={{
                            whiteSpace: 'pre-wrap',
                            textAlign:'start'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className= { ScreenType === 'perfil' ? 'notas' : 'hidden'}
                    dangerouslySetInnerHTML={{ __html: CV ? FormatJobData(CV.workExperience) 
                                                          + '\n\n\n\n'
                                                          + FormatStudyData(CV.studies) : '' }}
                    style={{
                      overflowY: 'auto',
                      textAlign: 'left'
                    }}
                  />
                </div>
              ) /* Sin respuesta */         : !Form.length && SelectedName ? (
                <div style={{display:'flex', flexDirection:'column',alignItems:'center', fontSize: '1.2rem'}}> 
                  <h2>Sin respuesta disponible</h2>
                  <p>Recuerde enviar el formulario utilizando el boton de reenviar</p>
                  <p>O si ya lo enviaste, puedes reenviar el formulario con el mismo boton </p>
                  <p>Alternativamente puedes </p>
                  <button onClick={()=>{window.open(`https://forms.renee.cl/?NR=${encodeURIComponent(SelectedNameR)}&NC=${encodeURIComponent(SelectedName)}`)}}
                          style={{width: '30%'}}>
                            <i className="fa-solid fa-file-signature"></i> &nbsp; Rellenar el Formulario manualmente
                  </button>
                  <p>O si lo consideras necesario </p>
                  <button className='redb'
                          onClick={deleteReference} 
                          style={{width: '30%'}}>
                            <i className="fa-solid fa-x"></i> &nbsp; Eliminar al Referente
                  </button>
                </div>
              
              ) /* Pantalla Default */      : (
                <h2
                  className={CV ? 'hidden' : ''}> 
                  Inicia seleccionando un candidato o un referente
                  {/* eslint-disable-next-line */}
                  <p style={{fontSize:'4rem'}}>👈</p>
                </h2>
              )}
            </div>
          </div>

        </div>

      </div>
        
      <div className={ Wait? 'hidden' : 'bottom'}>
        <button onClick={goto10}>volver</button>
        <button onClick={()=>setState(!State)}>ver candidatos rechazados</button>
        <button className={SelectList3.length > 0 ? 'Save last-button' : 'hidden'} onClick={goto12}>Guardar y Continuar</button>
      </div>
      
    </div>
  );
}
