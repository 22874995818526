import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    this.setState({
      error: error.toString(),
      errorInfo: errorInfo.componentStack || 'No stack available', // Use the stack if available
    });
  }

  render() {
    const currentUrl = window.location.href;

    if (this.state.hasError) {
      return (
        <div style={{ marginLeft: '2vh', fontSize: '1.3rem' }}>
          <h1>Oops, parece que encontramos un error.</h1>
          <h2>{currentUrl}</h2>
          <h2>{this.state.error}</h2>
          <div
            style={{
              marginTop: '1vh',
              marginRight: '3vh',
              whiteSpace: 'pre-wrap',
              flex: '1',
              overflowY: 'scroll',
              textAlign: 'left',
              fontSize: '1rem',
              color: 'var(--dark)',
              border: '2px solid var(--dark)',
              padding: '10px',
              backgroundColor: 'var(--light)',
            }}
          >
            {this.state.errorInfo && (
              <div>
                <p>
                  <strong>Component Stack:</strong>
                </p>
                <pre>{this.state.errorInfo}</pre>
              </div>
            )}
          </div>
          <div
            style={{
              marginTop: '1vh',
              marginRight: '3vh',
              whiteSpace: 'pre-wrap',
              flex: '1',
              overflowY: 'scroll',
              textAlign: 'left',
              fontSize: '1rem',
              color: 'var(--dark)',
              border: '2px solid var(--dark)',
              padding: '10px',
              backgroundColor: 'var(--light)',
            }}
          >
            <h3>Consejos para depuración:</h3>
            <ul>
              <li>Indique el proceso en el cual se produjo el error.</li>
              <li>Indique el paso del proceso en el cual se produjo el error</li>
              <li>Sea lo mas descriptibo posible respecto a los pasos que tomo durante el proceso.</li>
            </ul>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
