
//=============================================================================
// Pantalla Datos del Proceso
//=============================================================================

// Genericos
import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// API
import APICALL from './APICALL';

export default function Eprocess( ) {

  const navigate = useNavigate();
  const [I_name, setI_name]   = useState('');
  const [R_name, setR_name]   = useState('');
  const [I_name2, setI_name2] = useState('');
  const [R_name2, setR_name2] = useState('');

  const [selectedLanguage, setSelectedLanguage]             = useState('');
  const [selectedComp, setSelectedComp]                     = useState('');
  const [selectedKnowledgeLevel, setSelectedKnowledgeLevel] = useState('');

  const [Charge_name, setCharge_name]                 = useState('');
  const [Charge_title, setCharge_title]               = useState('');
  const [Charge_type, setCharge_type]                 = useState('');
  const [Available_slots, setAvailable_slots]         = useState('');
  const [E_name, setE_name]                           = useState('');
  const [Confidential, setConfidential]               = useState('');
  const [Work_mode, setWork_mode]                     = useState('');
  const [Work_time, setWork_time]                     = useState('');
  const [Work_country, setWork_country]               = useState('');
  const [Work_region, setWork_region]                 = useState('');
  const [Work_comuna, setWork_comuna]                 = useState('');
  const [Work_place, setWork_place]                   = useState('');
  const [E_activity, setE_activity]                   = useState('');
  const [Work_Area, setWork_Area]                     = useState('');
  const [Work_class, setWork_class]                   = useState('');
  const [Currency, setCurrency]                       = useState('');
  const [Work_pay_min, setWork_pay_min]               = useState('');
  const [Work_pay_max, setWork_pay_max]               = useState('');
  const [ContractTime, setContractTime]               = useState('');

  const [Work_Experience, setWork_Experience]         = useState('');
  const [Work_Detail, setWork_Detail]                 = useState('');
  const [Study_min, setStudy_min]                     = useState('');
  const [Study_state, setStudy_state]                 = useState(''); 
  const [Requirements, setRequirements]               = useState(['']);

  const [Timewith, setTimewith]                       = useState('');
  const [Timedetail, setTimedetail]                   = useState('');

  const [IList, setIList]                             = useState(['']);

  const code                = sessionStorage.process
  const [P_name, setP_name] = useState('');


  // Carga de opciones
  document.addEventListener('DOMContentLoaded', () => {

    const data = require('./docs/Trabajando.json')
    const regionSelect = document.getElementById('region');
    const comunaSelect = document.getElementById('comuna');
    regionSelect.innerHTML = '';
    data.regiones.forEach(regionObj => {
      const option = document.createElement('option');
      option.value = regionObj.region;
      option.textContent = regionObj.region;
      regionSelect.appendChild(option);
    });
    regionSelect.addEventListener('change', (event) => {
      const selectedRegion = event.target.value;
      const regionObj = data.regiones.find(region => region.region === selectedRegion);
      comunaSelect.innerHTML = '';

      if (regionObj) {
        regionObj.comunas.forEach(comuna => {
          const option = document.createElement('option');
          option.value = comuna;
          option.textContent = comuna;
          comunaSelect.appendChild(option);
        });
      }
    });
    regionSelect.dispatchEvent(new Event('change'));
  });
  

  // Alternativas de bloques
  const HandleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
    setSelectedKnowledgeLevel(''); 
  };
  const HandleCompChange = (e) => {
    setSelectedComp(e.target.value);
    setSelectedKnowledgeLevel(''); 
  };
  const HandleKnowledgeChange = (e) => {
    if (selectedLanguage) {
      setRequirements((prevRequirements) => [
        ...(Array.isArray(prevRequirements) ? prevRequirements : []),
        [selectedLanguage + ' - ' + e.target.value],
      ]);
      setSelectedLanguage('');
    } else if (selectedComp) {
      setRequirements((prevRequirements) => [
        ...(Array.isArray(prevRequirements) ? prevRequirements : []),
        [selectedComp + ' - ' + e.target.value],
      ]);
      setSelectedComp('');
    }
  };
  const RemoveSelection = (index) => {
    setRequirements(Requirements.filter((_, i) => i !== index));
  };


  // Navegacion
  const goto03 = () => { navigate('/P_Notas') };
  async function goto05(e) {
    e.preventDefault();
  
    const basic_data = {
      P_name,             
      Charge_name,        
      Charge_title,       
      Charge_type,        
      Available_slots,    
      E_name,             
      Confidential,       
      Work_mode,          
      Work_time,          
      Work_country,       
      Work_region,        
      Work_comuna,        
      Work_place,         
      E_activity,         
      Work_Area,          
      Work_class,         
      Currency,           
      Work_pay_min,       
      Work_pay_max,       
      ContractTime,       
      Work_Experience,    
      Work_Detail,        
      Study_min,          
      Study_state,        
      Requirements,       
      R_name,             
      R_name2,            
      I_name,             
      I_name2,            
      Timewith,           
      Timedetail          
    };
  
    try {
      await APICALL({
        apicall: 'PD_update',
        code,
        U_emp: sessionStorage.Grupo,
        ...basic_data,
        basic_data
      });
  
      navigate('/P_Perfil');
  
    } catch (error) {
      console.error('API call failed', error);
      window.alert('Error actualizando los datos, por favor intente nuevamente');
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo
      });
      
      setP_name(data.P_name);
      setE_name(data.E_name);
      setI_name(data.I_name);
      setR_name(data.R_name);
      setI_name2(data.I_name2);
      setR_name2(data.R_name2);
      setCharge_name(data.Charge_name);
      setCharge_title(data.Charge_title);
      setCharge_type(data.Charge_type);
      setAvailable_slots(data.Available_slots);
      setWork_mode(data.Work_mode);
      setWork_time(data.Work_time);
      setWork_country(data.Work_country);
      setWork_region(data.Work_region);
      setWork_comuna(data.Work_comuna);
      setWork_place(data.Work_place);
      setE_activity(data.E_activity);
      setWork_class(data.Work_class);
      setWork_pay_min(data.Work_pay_min);
      setWork_pay_max(data.Work_pay_max);
      setWork_Experience(data.Work_Experience);
      setWork_Detail(data.Work_Detail);
      setStudy_min(data.Study_min);
      setStudy_state(data.Study_state);
      setTimewith(data.Timewith);
      setTimedetail(data.Timedetail);
      setContractTime(data.ContractTime);
      setWork_Area(data.Work_Area);
      setCurrency(data.Currency);
      setConfidential(data.Confidential);
      setRequirements(['']);
      setRequirements(data.Requirements);

      const listfetch = await APICALL({
        apicall:'I_list',
        E_name: data.E_name
      });

      setIList (listfetch)

      const doc = require('./docs/Trabajando.json');

      const CargosSelect = document.getElementById('cargos');
      CargosSelect.innerHTML = '';
      doc.Cargos.forEach(CarObj => {
        const option = document.createElement('option');
        option.value = CarObj;
        option.textContent = CarObj;
        CargosSelect.appendChild(option);
      });

      const ModSelect = document.getElementById('Modalidad');
      ModSelect.innerHTML = '';
      doc.Modalidad.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj;
        option.textContent = Obj;
        ModSelect.appendChild(option);
      });

      const JornadaSelect = document.getElementById('Jornada');
      JornadaSelect.innerHTML = '';
      doc.Jornada.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj;
        option.textContent = Obj;
        JornadaSelect.appendChild(option);
      });

      const PaisSelect = document.getElementById('Pais');
      PaisSelect.innerHTML = '';
      doc.Paises.forEach(PaisObj => {
        const option = document.createElement('option');
        option.value = PaisObj;
        option.textContent = PaisObj;
        PaisSelect.appendChild(option);
      });

      const regionSelect = document.getElementById('region');
      const comunaSelect = document.getElementById('comuna');
      
  
      regionSelect.innerHTML = '';
      const regionPlaceholderOption = document.createElement('option');
      regionPlaceholderOption.value = ''; 
      regionPlaceholderOption.textContent = 'Elije una Region';
      regionPlaceholderOption.disabled = true;
      regionPlaceholderOption.selected = true;
      regionSelect.appendChild(regionPlaceholderOption);
      
      doc.regiones.forEach(regionObj => {
        const region = regionObj.region[0];
        const option = document.createElement('option');
        option.value = region.nombre;
        option.textContent = region.nombre;
        regionSelect.appendChild(option);
      });
      
      regionSelect.value = data.Work_region || '';
      
      const updateComunaOptions = (regionObj) => {
        comunaSelect.innerHTML = '';
        const comunaPlaceholderOption = document.createElement('option');
        comunaPlaceholderOption.value = '';
        comunaPlaceholderOption.textContent = 'Elije una Comuna';
        comunaPlaceholderOption.disabled = true;
        comunaPlaceholderOption.selected = true;
        comunaSelect.appendChild(comunaPlaceholderOption);
      
        if (regionObj) {
          regionObj.comunas.forEach(comuna => {
            const option = document.createElement('option');
            option.value = comuna.nombre;
            option.textContent = comuna.nombre;
            comunaSelect.appendChild(option);
          });
        }
        comunaSelect.value = data.Work_comuna || '';
      };
      
      const handleRegionChange = (event) => {
        const selectedRegionName = event.target.value;
        const regionObj = doc.regiones.find(region => region.region[0].nombre === selectedRegionName);
        updateComunaOptions(regionObj);
      };
      
      updateComunaOptions(
        doc.regiones.find(region => region.region[0].nombre === data.Work_region)
      );
      
      regionSelect.addEventListener('change', handleRegionChange);
      
      
      const ActividadSelect = document.getElementById('ActividadEmp');
      ActividadSelect.innerHTML = '';
      doc.Actividadempresa.forEach(ActObj => {
        const option = document.createElement('option');
        option.value = ActObj;
        option.textContent = ActObj;
        ActividadSelect.appendChild(option);
      });

      const AreaSelect = document.getElementById('Area');
      AreaSelect.innerHTML = '';
      doc.Area.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj;
        option.textContent = Obj;
        AreaSelect.appendChild(option);
      });

      const VigenciaSelect = document.getElementById('Vigencia');
      VigenciaSelect.innerHTML = '';
      doc.Vigencia.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj.id;
        option.textContent = Obj.Nombre;
        VigenciaSelect.appendChild(option);
      });

      const ExpSelect = document.getElementById('Experiencia');
      ExpSelect.innerHTML = '';
      doc.Experiencia.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj.id;
        option.textContent = Obj.Nombre;
        ExpSelect.appendChild(option);
      });

      const MinEstSelect = document.getElementById('MinEst');
      MinEstSelect.innerHTML = '';
      doc.MinEstudio.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj;
        option.textContent = Obj;
        MinEstSelect.appendChild(option);
      });

      const SitAcaSelect = document.getElementById('SitAca');
      SitAcaSelect.innerHTML = '';
      doc.SituacionAcademica.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj;
        option.textContent = Obj;
        SitAcaSelect.appendChild(option);
      });

      const IdiomSelect = document.getElementById('Idioma');
      IdiomSelect.innerHTML = '';
      doc.Idiomas.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj;
        option.textContent = Obj;
        IdiomSelect.appendChild(option);
      });
  
      regionSelect.addEventListener('change', handleRegionChange);
      regionSelect.dispatchEvent(new Event('change'));
    };
  
    fetchData();
  }, []);
  

  //############################################################################################################
  //############################################################################################################

  return (
    <div className='BackGround'>

      {/* Type header */}
      <div className='typing-effect-container'>
        <h2 className='typing-effect' >
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          Vamos a llenar los datos del proceso que creaste! o Modifica los formularios de la manera que necesites 😎
        </h2>
			</div>

      <div className='MainBody'>

        {/* Formulario */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <form id='myform' className='form-container' onSubmit={goto05} style={{fontSize: '1.1rem'}}>

        <h1 className='form-title' style={{marginLeft:'-2vh'}}>Parte 1: DESCRIPCIÓN GENERAL</h1>

          {/* <label><strong>NOMBRE DEL CARGO </strong>
            <input  type='text' 
                    value= {Charge_name}
                    readOnly
                    className={Charge_name ? 'highlightedItem' : ''}/>
          </label> */}
          <div className='FormRow'>
            <label><strong>NOMBRE DE LA EMPRESA</strong>
            <input  value= {E_name}
                    readOnly
                    className={E_name ? 'highlightedItem' : ''}/>
            </label>
            <label><strong>TITULO DEL CARGO *</strong>
            <input  type='text' 
                    value= {Charge_title ? Charge_title : ''}
                    placeholder={'ingrese un valor'}
                    className={Charge_title ? 'highlightedItem' : ''}
                    onChange={e => setCharge_title(e.target.value)} 
                    required/>
            </label>
          </div>


          <div className='FormRow'>
            <label><strong>CONFIDENCIALIDAD *</strong>
            <select className={Confidential ? 'highlightedItem' : ''} 
                    onChange={e => setConfidential(e.target.value)} 
                    id='Hidden'
                    required>
              <option> {Confidential ? Confidential : 'Elija un valor'} </option>
              <option value='Publico'     > Publico                </option>
              <option value='Confidencial'> Confidencial           </option>
            </select>
            </label>
            <label><strong>VIGENCIA DE LA PUBLICACION *</strong>
            <select id='Vigencia'
              value = {Timewith ? Timewith : ''}
              className={Timewith ? 'highlightedItem' : ''}
              onChange={e => setTimewith(e.target.value)}
              required>
            </select>
            </label>
          </div>


          <label><strong>TIPO DE CARGO  *</strong>
          <select id='cargos'
            value={Charge_type ? Charge_type : ''}
            placeholder={'ingrese un valor'}
            className={Charge_type ? 'highlightedItem' : ''}
            onChange={e => setCharge_type(e.target.value)}
            required>
          </select>
          </label>    
          <label><strong>AREA DE CARGO  *</strong>
          <select id='Area'
            value={Work_Area ? Work_Area : ''}
            className={Work_Area ? 'highlightedItem' : ''}
            onChange={e => setWork_Area(e.target.value)}
            required>
          </select>
          </label>
          <label><strong>ACTIVIDAD DE LA EMPRESA *</strong>
          <select id='ActividadEmp' 
            value={E_activity ? E_activity : ''}
            className={E_activity ? 'highlightedItem' : ''}
            onChange={e => setE_activity(e.target.value)}
            required>
          </select>
          </label>


          <label><strong>JERARQUIA LABORAL *</strong>
          <select className={Work_class ? 'highlightedItem' : ''} 
                  onChange={e => setWork_class(e.target.value)} 
                  required>
            <option> {Work_class ? Work_class : 'Elija un valor'} </option>
            <option value=' Gerencia '    > Gerencia/ Direccion                 </option>
            <option value=' Supervisión ' > Supervisión/ Jefatura/ Sub-Gerencia </option>
            <option value=' Responsable ' > Responsable                         </option>
            <option value=' Senior '      > Posicion Senior                     </option>
            <option value=' Junior '      > Posicion Junior                     </option>
            <option value=' Empleado '    > Empleado                            </option>
            <option value=' Operador '    > Operador                            </option>
            <option value=' Asesor '      > Asesor                              </option>
            <option value=' Practica '    > Alumno en Practica                  </option>
          </select>
          </label>
          <label><strong>CANTIDAD DE VACANTES *</strong>
          <input  type='number' 
                  value= {Available_slots ? Available_slots : ''}
                  placeholder={'ingrese un valor'}
                  className={Available_slots ? 'highlightedItem' : ''}
                  onChange={e => setAvailable_slots(e.target.value)} 
                  required/>
          </label>
          <label><strong>DURACION DEL CONTRATO *</strong>
          <input  type='text' 
                  value= {ContractTime ? ContractTime : ''}
                  placeholder={'ingrese un valor'}
                  className={ContractTime ? 'highlightedItem' : ''}
                  onChange={e => setContractTime(e.target.value)} 
                  id='Contract_Time'
                  required/>
          </label>


          <div className='FormRow'>
            <label><strong>PAIS DE TRABAJO *</strong>
            <select id='Pais'
                      value={ Work_country || setWork_country('Chile')}
                      className={Work_country ? 'highlightedItem' : ''}
                      onChange={e => setWork_country(e.target.value)}
                      required>
            </select>
            </label>
            <div className={Work_country && Work_country.trim().toLowerCase() === 'chile' ? '' : 'hidden'}>
              <label><strong>REGION DE TRABAJO </strong>
              <select id='region'
                      required={Work_country === 'Chile'}
                      value={ Work_region || ''}
                      className={Work_region ? 'highlightedItem' : ''}
                      onChange={e => setWork_region(e.target.value)}>
              </select>
              </label>
            </div>
            <div className={Work_country && Work_country.trim().toLowerCase() === 'chile' ? '' : 'hidden'}>
              <label><strong>COMUNA DE TRABAJO </strong>
              <select id='comuna' 
                      required={Work_region}
                      value={ Work_comuna || ''}
                      className={Work_comuna ? 'highlightedItem' : ''}
                      onChange={e => setWork_comuna(e.target.value)}>
              </select>
              </label>
            </div>
          </div>

          
          <label><strong>LUGAR DE TRABAJO *</strong>
          <input  type='text' 
                  value= {Work_place ? Work_place : ''}
                  placeholder={'ingrese un valor'}
                  className={Work_place ? 'highlightedItem' : ''}
                  onChange={e => setWork_place(e.target.value)} 
                  required/>
          </label>
          <label><strong>MODALIDAD DE TRABAJO *</strong>
          <select id='Modalidad'
            value={Work_mode ? Work_mode : ''}
            className={Work_mode ? 'highlightedItem' : ''}
            onChange={e => setWork_mode(e.target.value)}
            required>
          </select>
          </label>
          <label><strong>JORNADA DE TRABAJO *</strong>
          <select id='Jornada'
            value={Work_time ? Work_time : ''}
            className={Work_time ? 'highlightedItem' : ''}
            onChange={e => setWork_time(e.target.value)}
            required>
          </select>
          </label>


          <div className='FormRow'>
            <label><strong>RENTA DE LA POSICION *</strong>
            <div className={Currency}>
              <input  type='number' 
                      min={0} 
                      value={Work_pay_max ? Work_pay_max : ''}
                      placeholder={'ingrese un valor'}
                      className={Work_pay_max ? 'highlightedItem' : ''}
                      onChange={e => setWork_pay_max(e.target.value)} 
                      required/>
            </div>
            <div className={Currency}>
              <input  type='number' 
                      min={0} 
                      value={Work_pay_min ? Work_pay_min : ''}
                      placeholder={'ingrese un valor'}
                      className={Work_pay_min ? 'highlightedItem' : ''}
                      onChange={e => setWork_pay_min(e.target.value)} 
                      required/>
            </div>
            </label>
            <label><strong>MONEDA DE PAGO *</strong>
            <select className={Currency ? 'highlightedItem' : ''} 
                    onChange={e => setCurrency(e.target.value)} 
                    id='Currency'
                    required>
              <option>{Currency ? Currency : 'Elija un valor'}</option>
              <option value='Dolar'         > Dolar           </option>
              <option value='Euro'          > Euro            </option>
              <option value='Pesos-Chilenos'> Pesos-Chilenos  </option>
            </select>
            </label>
          </div>


        <h1 className='form-title' style={{marginLeft:'-2vh'}}> Parte 2 : REQUISITOS DE LA POSICION</h1>

          <div className='FormRow'>
            <label><strong>EXPERIENCIA LABORAL *</strong>
            <select className={Work_Experience ? 'highlightedItem' : ''} 
                    onChange={e => setWork_Experience(e.target.value)} 
                    required>
              <option> {Work_Experience ? Work_Experience : 'Elija un valor'} </option>
              <option value='Sin preferencia'   > Sin preferencia   </option>
              <option value='Sin experiencia'   > Sin experiencia   </option>
              <option value='Igual a'           > Igual a           </option>
              <option value='Desde'             > Desde             </option>
              <option value='Hasta'             > Hasta             </option>
            </select>
            </label>
            <div className={Work_Experience === 'Desde' || Work_Experience === 'Igual a' || Work_Experience === 'Hasta' ? '' : 'hidden'}>
              <label><strong>DETALLE EXPERIENCIA LABORAL </strong>
              <select id='Experiencia'
                value = {Work_Detail ? Work_Detail : ''}
                className={Work_Detail ? 'highlightedItem' : ''}
                onChange={e => setWork_Detail(e.target.value)}>
              </select>
              </label>
            </div>
          </div>


          <label><strong>ESTUDIOS MINIMOS *</strong>
          <select id='MinEst'
            value = {Study_min ? Study_min : ''}
            className={Study_min ? 'highlightedItem' : ''}
            onChange={e => setStudy_min(e.target.value)}
            required>
          </select>
          </label>
          <label><strong>SITUACION ACADEMICA *</strong>
          <select id='SitAca'
            value = {Study_state ? Study_state : ''}
            className={Study_state ? 'highlightedItem' : ''}
            onChange={e => setStudy_state(e.target.value)}
            required>
          </select>
          </label>


          <div className='FormRow'>
            <label><strong>COMPETENCIAS / HABILIDADES *</strong>
            <input  id='Competencia'
                    placeholder='Elija una Competencia'
                    type='text' 
                    onChange={HandleCompChange}/>
            </label>
            {selectedComp && (
              <label>
                <strong>{selectedComp} - NIVEL * </strong>
                <select
                  value={selectedKnowledgeLevel}
                  onChange={HandleKnowledgeChange}
                >
                  <option value='0'     > Seleccione una Categoría </option>
                  <option value='Bajo'  > Bajo                     </option>
                  <option value='Medio' > Medio                    </option>
                  <option value='Alto'  > Alto                     </option>
                </select>
              </label>
            )}
          </div>

          <div className='FormRow'>
            <label><strong>IDIOMAS *</strong>
            <select id='Idioma'
              value={selectedLanguage ? selectedLanguage : 'Elija un valor'}
              onChange={HandleLanguageChange}>
            </select>
            </label>
            {selectedLanguage && (
              <label>
                <strong>{selectedLanguage} - NIVEL * </strong>
                <select
                  value={selectedKnowledgeLevel}
                  onChange={HandleKnowledgeChange}
                >
                  <option value='0'     > Seleccione una Categoría </option>
                  <option value='Bajo'  > Bajo                     </option>
                  <option value='Medio' > Medio                    </option>
                  <option value='Alto'  > Alto                     </option>
                  <option value='Nativo'> Nativo                   </option>
                </select>
              </label>
            )}
          </div>

          <div className='form-lista'>
            <ul>
            {(Requirements || []).map(([language], index) => (
              <button key={index} 
                      style={{marginBottom:'0.5vh'}}
                      onClick={() => RemoveSelection(index)}>
                {language}
              </button>
            ))}
            </ul>
          </div>

        <h1 className='form-title' style={{marginLeft:'-2vh'}}>Parte 3 : DATOS ADICIONALES</h1>

          <div className='FormRow'>
            <label><strong>NOMBRE DEL INTERLOCUTOR/A </strong>
            <input  value= {I_name}
                    readOnly
                    className={I_name ? 'highlightedItem' : ''}/>
            </label>
            <label><strong>INTERLOCUTORES ADICIONALES</strong>
            <select
              value={I_name2 || ''}
              className={I_name2 ? 'highlightedItem' : ''}
              onChange={e => setI_name2(e.target.value)}>
              <option value="">Seleccione un interlocutor adicional</option>
              {IList.map((nombre, index) => (
                <option key={index} value={nombre}>{nombre.toUpperCase()}</option>
              ))}
            </select>
            </label>
          </div>

          <div className='FormRow'>
            <label><strong>HEAD HUNTER ENCARGADO/A</strong>
            <input  value= {R_name}
                    readOnly
                    className={R_name ? 'highlightedItem' : ''}/>
            </label>
            <label><strong>HEAD HUNTER DELEGADO</strong>
            <input  type='text' 
                    placeholder= {R_name2 ? R_name2 : 'Ingrese un valor'}
                    className={R_name2 ? 'highlightedItem' : ''}
                    onChange={e => setR_name2(e.target.value)}/>
            </label>
          </div>

          <div className='FormRow'></div>
          <div className='FormRow'></div>
          <div className='FormRow'></div>
          <div className='FormRow'></div>

        </form>
      </div>

      <div className='bottom'>
        <button type='button' onClick={goto03}> volver </button>
        <button className='Save last-button' 
                onClick={() => {const form = document.getElementById('myform'); if (form) {form.requestSubmit()}}}>
                Guardar y Continuar
        </button>
      </div>
      
    </div>
  );

}
