import React from 'react';

const StarRating = ({ initialRating, onRatingChange }) => {
  const totalStars = 5;

  const handleClick = (newRating) => {
    onRatingChange(newRating); 
  };

  return (
    <div style={{ fontSize: '2vh', color: 'var(--main)', cursor: 'pointer', }}>
      {Array.from({ length: totalStars }, (_, index) => {
        const starValue = index + 1;
        // console.log('start'+initialRating);
        return (
          <span
            key={index}
            onClick={() => handleClick(starValue)}
            style={{ margin: '0 2px' }} 
          >
            {starValue <= initialRating ? <i className="fa-solid fa-star"></i> : <i className="fa-regular fa-star"></i>}
          </span>
        );
      })}
    </div>
  );
};

export default StarRating;
