
//=============================================================================
// Pantalla Generacion de Perfil
//=============================================================================

// Genericos
import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// API
import APICALL from './APICALL';
import AICALL from './AICALL';
import LOGCALL from './LOGCALL';
// Especificos
import jsPDF from 'jspdf';

export default function Perfil() {

  const navigate = useNavigate();
  const [Resp, setResp]               = useState('');
  const [Estudio, setEstudio]         = useState(''); 
  const [Instruccion, setInstruccion] = useState('');
  const [FetchedData, setFetchedData] = useState('');
  const [Imail, setImail]             = useState(''); 
  const [Imail2, setImail2]             = useState(''); 
  const [Wait, setWait]               = useState(false);
  const [ShowPopup, setShowPopup]     = useState(false);
  const [ShowPopup2, setShowPopup2]     = useState(false);
  const handleChange                  = (event) => { setResp(event.target.value); };


  // Recuperado y guardado de datos
  async function FetchData () {
    const data = await APICALL({
      apicall :'PD_fetch' ,
      code :sessionStorage.process,
      U_emp: sessionStorage.Grupo
    });
    setFetchedData(data);
    setResp(data.GPTreply)
    if (!data.GPTreply){
      HandleGenerateText();
    }
  };
  async function SaveNotes(){
    setWait(true);
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.process, 
        U_emp: sessionStorage.Grupo,
        GPTreply: Resp 
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setTimeout(() => {
      setWait(false);
    }, 1000);
  };


  // Generacion Perfil
  async function HandleGenerateText( ) {
    
    setWait(true);
    try {
      const response = await AICALL({
        apicall: '01',
        data:   JSON.stringify(FetchedData.basic_data)
                + '. Notas Adicionales del cargo : '
                + localStorage.notas
                + '. Notas Adicionales de la empresa : '
                + localStorage.notasemp
                + '. Informacion anexa : '
                + localStorage.archivo
      });
      setResp(response.reply);
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setTimeout(() => {
      setWait(false);
    }, 1000);;
  };
  async function HandleIterateText( ) {
    
    setWait(true);
    try {
      const response = await AICALL({
        apicall: '01',
        data:   Resp
              + Instruccion
              + '. Notas Adicionales del cargo : '
              + localStorage.notas
              + '. Notas Adicionales de la empresa : '
              + localStorage.notasemp
              + '. Informacion anexa : '
              + localStorage.archivo
      });
      setResp(response.reply);
      setInstruccion('');
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setTimeout(() => {
      setWait(false);
    }, 1000);;
  };
  async function HandleGenerateEstudio( text ) {
    console.table(text)
    setWait(true);
    try {
      const Est = await AICALL({
        apicall: '10',
        data: JSON.stringify(text)
      });
      setEstudio(Est.reply);
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.process, 
        U_emp: sessionStorage.Grupo,
        Estudio: Est.reply 
      });

    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }

    setTimeout(() => {
      setWait(false);
    }, 1000);;
  };
  const FormatEstudios = (rawString) => {
    if (rawString){
      try {
        // Extract the JSON part from between the code fence markers
        const jsonRegex = /```(?:json)?\s*(\{[\s\S]*?\})\s*```/;
        const match = rawString.match(jsonRegex);
        
        if (!match || !match[1]) {
          throw new Error("No valid JSON found in the input string");
        }
        
        const jsonString = match[1];
        
        // Clean up citation markers and parse
        const cleanedJsonString = jsonString.replace(/【[^】]*】/g, '');
        const data = JSON.parse(cleanedJsonString);
        
        // Format the data as before
        const salarioInfo = `${data.compensaciones.rango_inferior} - ${data.compensaciones.rango_superior} (Promedio: ${data.compensaciones.media})`;
        const habilidadesTecnicas = data.habilidades.tecnicas.join(', ');
        const habilidadesBlandas = data.habilidades.blandas.join(', ');
        const beneficios = data.compensaciones.beneficios.join(', ');
        const factores = data.factores_influyentes.join(', ');
        
        return `
          <strong>${data.cargo}</strong>${data.resumen}
          
          <strong>Tendencias del Mercado:</strong>${data.tendencias.replace(/【[^】]*】/g, '')}
          
          <strong>Habilidades Técnicas: </strong>${habilidadesTecnicas}
          
          <strong>Habilidades Blandas: </strong>${habilidadesBlandas}
          
          <strong>Compensación: </strong>${salarioInfo}
          
          <strong>Beneficios: </strong>${beneficios}
          
          <strong>Factores Influyentes:</strong>${factores}
        `.trim();
      } catch (error) {

        console.error("Error processing JSON:", error);
        return "Renee Esta trabajando, Espere un momento";
      }
    } else {
      return "Renee Esta trabajando, Espere un momento";
    }
  };


  // Mail Interlocutor
  async function PrepareMail( ) {

    SaveNotes()

    try {
      const response = await APICALL({
        apicall: 'I_fetch',
        I_name: FetchedData.I_name
      });
      setImail(response.I_mail)
      if(FetchedData.I_name2){
        try {
          const response = await APICALL({
            apicall: 'I_fetch',
            I_name: FetchedData.I_name2
          });
          setImail2(response.I_mail)
        } catch (error) {
          console.error('Error during fetch:', error);
          return { token: null };
        }
      }
      setShowPopup2(true)
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };
  const ReplaceSpecialChars = (text) => {
    const replacements = {
      'á': '&aacute;',
      'é': '&eacute;',
      'í': '&iacute;',
      'ó': '&oacute;',
      'ú': '&uacute;',
      'Á': '&Aacute;',
      'É': '&Eacute;',
      'Í': '&Iacute;',
      'Ó': '&Oacute;',
      'Ú': '&Uacute;',
      'ñ': '&ntilde;',
      'Ñ': '&Ntilde;',
      'ü': '&uuml;',
      'Ü': '&Uuml;',
    };
    return text.replace(/[áéíóúÁÉÍÓÚñÑüÜ]/g, match => replacements[match]);

  };
  async function HandleInterlocMessage( ) {

    try {

      await LOGCALL({
        apicall: 'FM_INT1',
        Name : FetchedData.I_name,
        Mail : Imail,
        Title : FetchedData.Charge_title,
        P_name: sessionStorage.token,
        P_mail: sessionStorage.mail,
        text: ReplaceSpecialChars(Resp)
              .replace(/\*\*(.*?)\*\*/g, '<br/><br/><strong>$1</strong><br/><br/>')
              // eslint-disable-next-line
              .replace(/\~(.*?)\~/g, '<li>$1</li>')
              .replace(/(\d+\.\s+(.*?))\n/g, '<li>$2</li>')
              .replace(/(<li>.*?<\/li>)+/g, '<ul>$&</ul>')
      });

      await LOGCALL({
        apicall: 'FM_INT1',
        Name : FetchedData.I_name2,
        Mail : Imail2,
        Title : FetchedData.Charge_title,
        P_name: sessionStorage.token,
        P_mail: sessionStorage.mail,
        text: ReplaceSpecialChars(Resp)
              .replace(/\*\*(.*?)\*\*/g, '<br/><br/><strong>$1</strong><br/><br/>')
              // eslint-disable-next-line
              .replace(/\~(.*?)\~/g, '<li>$1<br/>'),
      });

      try {
        await LOGCALL({
          apicall: 'FM_NOT',
          code: sessionStorage.process,
          Mail : sessionStorage.mail,
          Title : FetchedData.Charge_title,
          P_name: sessionStorage.token,
          List: [FetchedData.I_name, FetchedData.I_name2],
          Theme: 'Envio Perfil Generado'
        });
      } catch (error) {
        console.error('Error during fetch:', error);
      }

      window.alert(`Perfil enviado exitosamente a :\n\n${FetchedData.I_name}\n${FetchedData.I_name2 || ''}`);
      setShowPopup2(false);
      return true
    } catch (error) {
      console.error('Error during fetch:', error);
      setShowPopup2(false);
      return('')
    }
    
  };


  // Exportar a PDF
  const ExportToPDFPerfil = () => {
    setWait(true);
    const doc = new jsPDF({
        format: 'a4',
        compress: true
    });

    const custom = 'Hola! \n Hemos generado este perfil de búsqueda que contiene toda la información que nos proporcionaste para llevar adelante este proceso de head hunting / búsqueda y selección de personas. Si tienes alguna observación por favor coméntanos \n'
    const text = custom + Resp
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const lines = text.split('\n');
    const currentDate = new Date();
    const monthNames = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio','julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
    const formattedDate = `${currentDate.getDate()} / ${monthNames[currentDate.getMonth()]} / ${currentDate.getFullYear()}`;

    const fontsize = 12
    const titleFontSize = 18
    const margin = 10; // en mm
    const maxLineWidth = pageWidth - 2 * margin;
    const headerHeight = 30; 
    const contentMarginTop = headerHeight + margin;

    const logoRenee   = new Image();
    const logoEficaz  = new Image();
    logoRenee.src   = '/favicon.png'; 
    logoEficaz.src  = '/Eficaz.png'; 
    const logoWidth = 25; 
    const logoHeight = 25;

    const addHeader = () => {
      const headerTextTop = 'Perfil de Búsqueda: \n' 
                          + FetchedData.Charge_title;
      const headerTextBot = 'Documento de Uso Interno - No sale a mercado\n' 
                          + formattedDate;

      doc.setFontSize(fontsize); 
      doc.addImage(logoRenee, 'PNG', margin / 3, margin / 3, logoWidth, logoHeight);
      doc.setFont('helvetica', 'bold'); 
      doc.text(headerTextTop, pageWidth / 2, headerHeight / 3, { align: 'center' }); 
      doc.setFont('helvetica', 'normal'); 
      doc.text(headerTextBot, pageWidth / 2, headerHeight / 3 + 15, { align: 'center' }); 
      doc.addImage(logoEficaz, 'PNG', pageWidth - margin / 3 - logoWidth, margin / 3, logoWidth, logoHeight);
    };

    addHeader();

    doc.setFontSize(fontsize);
    
    let x = margin;
    let y = contentMarginTop;

    lines.forEach(line => {
      let isTitle = false;
      let processedLine = line.trim();
      
      if (line.trim().startsWith('**') && line.trim().endsWith('**')) {
        isTitle = true;
        processedLine = line.trim().substring(2, line.trim().length - 2).trim();
      }      
      
      if (isTitle) {
        doc.setFontSize(titleFontSize);
        doc.setFont('helvetica', 'bold');
        y += titleFontSize * 0.35;
      } else {
        doc.setFontSize(fontsize);
        doc.setFont('helvetica', 'normal');
      }
      
      const textLines = doc.splitTextToSize(processedLine, maxLineWidth);
      const lineHeight = (isTitle ? titleFontSize : fontsize) * 0.35;
      
      textLines.forEach(subLine => {
        if (y + lineHeight > pageHeight - margin) {
          doc.addPage();
          addHeader()
          y = contentMarginTop;
        }
        doc.text(x, y, subLine);
        y += lineHeight;
      });
        
    });
    doc.save('Perfil_'+FetchedData.Charge_name+'.pdf');
    setTimeout(() => {
      setWait(false);
    }, 1000);
  };


  // Navegacion
  const goto04 = () => { navigate('/P_Datos') };
  async function goto06() {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.process, 
        GPTreply: Resp , 
        U_emp: sessionStorage.Grupo
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    localStorage['reply'] = Resp;
    navigate('/P_Oferta');
  };
  async function goto07() {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.process, 
        GPTreply: Resp , 
        U_emp: sessionStorage.Grupo
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    localStorage['reply'] = Resp;
    navigate('/P_Candidatos');
  };


  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall :'PD_fetch' ,
        code :sessionStorage.process,
        U_emp: sessionStorage.Grupo
      });
    
      setFetchedData(data)

      setResp(data.GPTreply);
      setEstudio(data.Estudio);
      if (!data.GPTreply){
        HandleGenerateText();}
      if (!data.Estudio){
        HandleGenerateEstudio( data.basic_data );}

    };
    fetchData();
    // eslint-disable-next-line
  }, []);
  const dataItems = [
    { label: 'NOMBRE DEL CARGO', value: FetchedData.Charge_title ? FetchedData.Charge_title.toUpperCase() : ' - - - ' }, 
    { label: 'EMPRESA',                 value: FetchedData.E_name }, 
		{ label: 'PARTNER ENCARGADO',       value: FetchedData.R_name },
    { label: 'INTERLOCUTOR',            value: FetchedData.I_name }
  ];


  //############################################################################################################
  //############################################################################################################

  return (
    <div className='BackGround'>
      
      {/* Popup Iteracion */}
      <div className={ShowPopup ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup(false)} >
        </div>
        <div className={ShowPopup ? 'warningbox' : 'hidden'}>
          <h2>Instrucciones Adicionales:</h2>
          <p style={{ alignItems: 'center', fontSize: '1.2rem', marginLeft:'2vh', marginRight:'2vh' }}>
            Ingresa cualquier instrucción adicional que quieras que Renee tenga en cuenta al generar la nueva iteracion del perfil de búsqueda.
          </p>
          <textarea
            className='notas'
            style={{marginLeft:'2vh', marginRight:'2vh'}}
            value={Instruccion}
            onChange={(e) => setInstruccion(e.target.value)}>
          </textarea>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => {HandleIterateText(Instruccion); setShowPopup(false) }}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Generar Iteracion </button>
            <button onClick={() => setShowPopup(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Popup Mail */}
      <div className={ShowPopup2 ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup2(false)} >
        </div>
        <div className={ShowPopup2 ? 'warningbox' : 'hidden'}>
          <h2>Envio al interlocutor</h2>
          <p style={{ alignItems: 'center', fontSize: '1.2rem', marginLeft:'2vh', marginRight:'2vh' }}>
            Si ya sientes que tu perfil generado se encuentra en las condiciones ideales pueden enviarselo a interlocutor para que lo revise <br/>
            <p>
              <br/> Interlocutor: {FetchedData.I_name}
              <br/> Correo: {Imail}
            </p>
            <p className={Imail2 ? '' : 'hidden'}>
              <br/> Interlocutor Adicional: {FetchedData.I_name2}
              <br/> Correo: {Imail2}
            </p>
          </p>

          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={HandleInterlocMessage}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Enviar perfil al Interlocutor </button>
            <button onClick={() => setShowPopup2(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Type header  */}
      <div className='typing-effect-container'>
				<h2 className='typing-effect' >
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          😎 En base a tus notas he creado para ti este PERFIL DE BÚSQUEDA, puedes editarlo o pedirme que lo genere nuevamente
        </h2>
			</div>

      <div className='MainBody'>
        
        {/* Perfil Generado */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{marginRight: '1vh', marginBottom: '-0.4vh'}}>
        <h3 className = 'boxtitle'>Perfil Generado</h3>
          { Wait === true ? (
                <div>
                  <div className='notas' style={{display:'flex', alignItems:'center', height:'67vh'}}>
                    <img
                      src={'/favicon.png'}
                      alt='Profile'
                      style={{
                        width: '150px',
                        height: '150px',
                        objectFit: 'cover',
                        position:'stick'
                      }}/>
                    <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                    <div className='loader-container'>
                      <div  className='pulsing-dot'>
                      <div></div>
                      <div></div>
                      <div></div>
                      </div>
                    </div>
                  </div>
                </div>

            ) : (
            <div>
              {/* bottones superior */}
              <div className='notas' style={{ display:'flex', 
                          height: '4vh',
                          gap: '1vh',
                          marginBottom: '0vh',
                          flexDirection:'row',
                          borderRadius:'0vh 1vh 0vh 0vh',
                          borderBottom: 'none',
                          width:'-webkit-fill-available', 
                          overflow:'hidden'}}>
              <button className='Save' onClick={HandleGenerateText}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Regenerar Perfil </button>
              <button className='Save' onClick={()=>setShowPopup(true)}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Iterar Perfil </button>
              <button onClick={SaveNotes}> <i className='fa-solid fa-floppy-disk'></i> &nbsp; Guardar Perfil </button>
              <button onClick={FetchData}> <i className='fa-solid fa-rotate-left'></i> &nbsp; Deshacer Cambios </button>
              <button onClick={ExportToPDFPerfil}> <i className='fa-solid fa-file-export'></i> &nbsp;  Exportar Perfil </button>
              </div>
              <textarea className='notas'
              style={{height:'67vh', borderRadius:'0vh 0vh 1vh 1vh'}}
              value={Resp}
              onChange={handleChange}
              spellCheck 
              />

            </div>
            )}
        </div>

        {/* Menu Lateral */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='data-boxes'>
          {dataItems.map((item, index) => (
            <div key={index} className='data-box'>
              <strong>{item.label}:</strong> {item.value}
            </div>
          ))}
          <h3 className = 'boxtitle'>Estudio de Remuneracion</h3>
          <div 
            className='notas'
            style={{
              marginTop: '-1vh', 
              borderRadius: '0vh 0vh 1vh 1vh', 
              marginBottom: '0vh',
              whiteSpace: 'pre-line'  // Preserva los saltos de línea
            }}
            dangerouslySetInnerHTML={{ 
              __html: FormatEstudios(Estudio) 
            }}
          />
              
        </div>

      </div>

      <div className={ Wait? 'hidden' : 'bottom'}>
        <button onClick={goto04}>Volver</button>
        <button onClick={PrepareMail}>Enviar al interlocutor</button>
        <button className= { FetchedData.Category === 'Evaluacion' ? 'Save last-button' : 'hidden' }
                onClick={() => {SaveNotes() ; goto07()}}>Guardar y Continuar</button>
        <button className= { FetchedData.Category !== 'Evaluacion' ? 'Save last-button' : 'hidden' }
                onClick={() => {SaveNotes() ; goto06()}}>Guardar y Continuar</button>
      </div>
        
    </div>
  );
}
