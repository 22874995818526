
//=============================================================================
// Pantalla Lista de Usuarios
//=============================================================================

import './css/App.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LOGCALL from './LOGCALL';
import APICALL from './APICALL';

export default function Luser() {
  const navigate = useNavigate();
  const [Lista, setLista] = useState([]);
  const [Replies, setReplies] = useState([]);
  const [Wait, setWait] = useState(false);
  const [pantalla, setpantalla] = useState('lista');
  const [partner, setpartner] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [sortConfigProcesos, setSortConfigProcesos] = useState({ key: null, direction: 'asc' });

  const handleSortProcesos = (key) => {
    let direction = 'asc';
    if (sortConfigProcesos.key === key && sortConfigProcesos.direction === 'asc') {
      direction = 'desc';
    }

    const sortedReplies = [...Replies].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setReplies(sortedReplies);
    setSortConfigProcesos({ key, direction });
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const sortedList = [...Lista].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setLista(sortedList);
    setSortConfig({ key, direction });
  };

  const goto01 = () => {
    navigate('/');
  };

  async function List(name) {
    setWait(true);
    const process = await APICALL({
      apicall: 'PD_list',
      R_name: name,
      U_emp: sessionStorage.Grupo
    });

  
    if (process && Array.isArray(process)) {
      // let filteredProcesses = process.filter(item => 
      //   item[1].toUpperCase() === sessionStorage.Grupo.toUpperCase() &&
      //   (state ? item.includes('archived: true') : !item.includes('archived: true'))
      // );
  
      // for (let i = 0; i < process.length; i++) {
      //   if (Grupos.indexOf(process[i][1]) === -1) {
      //     Grupos.push(process[i][1]);
      //   }
      // }

      const transformedReplies = process.map(item => ({
        date: (function() {
            const datePattern = /^(\d{1,2})-(\d{1,2})-(\d{4}) (\d{1,2}):(\d{1,2}):(\d{1,2})$/;
            const match = item[3]?.match(datePattern);
            if (match) {
                // eslint-disable-next-line 
                const [_, day, month, year, hour, minute, second] = match;
                return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year} ${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:${second.padStart(2, '0')}`;
            }
            return " - - -";
        })(),
        reply: item[2]?.replace(/_/g, ' | '),
        value: item[0],
        archived: item.some(element => element.includes('archived: true')) ? <i style={{display:'flex', justifyContent:'center'}} className="fas fa-archive"></i>  : ''
    })).reverse();
    
      
      setReplies(transformedReplies);
      setWait(false);
    } else {
      setReplies([]); 
      setWait(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('URL copiada al portapapeles!');
      })
      .catch(err => {
        alert('Error al copiar al portapapeles: ', err);
      });
  };

  useEffect(() => {
    const GetList = async () => {
      setWait(true);
      const list = await LOGCALL({
        apicall: 'US_LIST',
        Grupo: sessionStorage.Grupo,
      });
      setLista(list.filteredUsers); 
      setWait(false); 
    };
    GetList();
  }, []);

  return (
    <div className="BackGround">
      <div className="typing-effect-container">
        <h2>Lista de usuarios {sessionStorage.Grupo}</h2>
      </div>
      <div className="MainBody">
        {Wait && <p className="loading">Cargando datos...</p>}

        {Lista.length > 0 ? (
          pantalla === 'lista' ? (
            <div
              style={{
                width: '100%',
                marginRight: '5vh',
                marginLeft: '5vh',
                marginTop: '1vh',
                overflowY: 'scroll',
              }}>
              <table>
              <thead>
                <tr>
                  <th onClick={() => handleSort('U_name')}>
                    Nombre {sortConfig.key === 'U_name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                  </th>
                  <th onClick={() => handleSort('rol')}>
                    Rol {sortConfig.key === 'rol' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                  </th>
                  <th onClick={() => handleSort('U_phone')}>
                    Teléfono {sortConfig.key === 'U_phone' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                  </th>
                  <th onClick={() => handleSort('U_mail')}>
                    Email {sortConfig.key === 'U_mail' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                  </th>
                  <th className={sessionStorage.rol === 'Admin' ? '' : 'hidden'}
                      onClick={() => handleSort('U_pass')}>
                    Clave {sortConfig.key === 'U_pass' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                  </th>
                </tr>
              </thead>
                <tbody>
                  {Lista.map((usuario, index) => (
                    <tr key={index}>
                      <td style={{backgroundColor: 'var(--light-blue)'}}
                          onClick={() => {setpantalla('procesos');
                                        setpartner(usuario.U_name);
                                        List(usuario.U_name);}}>
                        {usuario.U_name}
                      </td>
                      <td onClick={()=>copyToClipboard(usuario.rol)}>{usuario.rol}</td>
                      <td onClick={()=>copyToClipboard(usuario.U_phone)}>{usuario.U_phone}</td>
                      <td onClick={()=>copyToClipboard(usuario.U_mail)}>{usuario.U_mail}</td>
                      <td className={sessionStorage.rol === 'Admin' ? '' : 'hidden'}
                          onClick={()=>copyToClipboard(usuario.U_pass)}>{usuario.U_pass}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : pantalla === 'procesos' ? (
              <div
                style={{
                  width: '100%',
                  marginRight: '5vh',
                  marginLeft: '5vh',
                  marginTop: '1vh',
                  overflowY: 'scroll',
                }}>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems: 'center'}}>
                  <button style={{ width: '20vh', height: '5vh', margin: '1vh' }} 
                          onClick={() => setpantalla('lista')}>
                    Volver a la lista
                  </button>
                  <div style={{ flexGrow: 1 }}></div>
                  <h2 style={{ margin: '0 auto' }}>Procesos asignados a {partner}</h2>
                  <div style={{ flexGrow: 1 }}></div>
                </div>
                {Replies.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th onClick={() => handleSortProcesos('reply')}>
                          Nombre del Proceso {sortConfigProcesos.key === 'reply' && (sortConfigProcesos.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSortProcesos('value')}>
                          ID interno {sortConfigProcesos.key === 'value' && (sortConfigProcesos.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSortProcesos('date')}>
                          Fecha Creacion {sortConfigProcesos.key === 'date' && (sortConfigProcesos.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSortProcesos('archived')}>
                          Archivado {sortConfigProcesos.key === 'archived' && (sortConfigProcesos.direction === 'asc' ? '▲' : '▼')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Replies.map((proceso, index) => (
                        <tr style={{  backgroundColor: proceso.archived !== '' ? 'var(--gray)' : '' ,
                                      color: proceso.archived !== '' ? 'var(--dark-gray)' : '' }} key={index}>
                          <td onClick={()=>copyToClipboard(proceso.reply)}>{proceso.reply}</td>
                          <td>{proceso.value}</td>
                          <td>{proceso.date}</td>
                          <td>{proceso.archived}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No hay procesos asignados a este miembro.</p>
                )}
              </div>
          ) : (
            <div>
            </div>
          )
        ) : (
          <p>No se encontraron usuarios en este grupo.</p>
        )}
      </div>
      <div className="bottom">
        <button onClick={goto01}>Volver</button>
        <div></div>
      </div>
    </div>
  );
}

