
//=============================================================================
// Pantalla Nuevo Usuario Empresa
//=============================================================================

import './css/App.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LOGCALL from './LOGCALL';

export default function NEuser() {

  const navigate = useNavigate();

  const [U_name, setU_name]   = useState('');
  const [U_mail, setU_mail]   = useState('');
  const [U_phone, setU_phone] = useState('');
  const [Error, setError]     = useState('');
  const rol                   = 'User';

  const [wait, setWait]       = useState(false);

   const handleSubmit = async e => {
		setWait(true)
    setError('')
		e.preventDefault();
    const result = await LOGCALL({
			apicall: 'US_NEW',
			U_name,
      U_mail,
      U_phone,
      U_emp : sessionStorage.Grupo,
      rol
		});

    setWait(false)
    setTimeout(() => {
    }, 500);
    if (result) {
      alert(result.reply || ' ');
    } 
    else {
      alert(result.error || ' ');
    }
	};

  const goto01 = () => { navigate('/') };

  useEffect(() => {
  }, []);

  return (
    <div className='BackGround'>
      <div className='typing-effect-container'>
				<h2 className='typing-effect' >Hey, Vamos a integrar a un nuevo miembro a renee!</h2>
			</div>
      <div className='MainBody'>

        <div style={{width:'10vw'}}>
          <div>
            <form onSubmit={handleSubmit} style={{width:'200%'}}>

              <label><strong>NOMBRE DEL USUARIO</strong>
               <input  type='text' 
                  style={{height:'4vh'}}
                  className={U_name ? 'highlighted' : ''}
                  onChange={e => setU_name(e.target.value)}/>
               </label>
               
               <label><strong>CORREO DEL USUARIO</strong>
               <input  type='mail' 
                  style={{height:'4vh'}}
                  className={U_mail ? 'highlighted' : ''}
                  onChange={e => setU_mail(e.target.value)}/>
               </label>

               <label><strong>TELEFONO DEL USUARIO</strong>
               <input  type='number' 
                  required
                  style={{height:'4vh'}}
                  className={U_phone ? 'highlighted' : ''}
                  onChange={e => setU_phone(e.target.value)}/>
               </label>

              <button type='submit' >Guardar</button>

            </form>
            {Error && <div className='error'>{Error}</div>}
            {wait === false ? (
              <div>
                <br />
              </div>
            ) 
            : (
              <div className='loader-container'>
                <div className='pulsing-dot'></div>
              </div>
            )}
          </div>
        </div>

      </div>

      <div className='bottom'>
        <button onClick={goto01}>volver</button>
        <div></div>
      </div>
    </div>
  );
}
