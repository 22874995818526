
//=============================================================================
// Pantalla Generación Oferta/ Preguntas | Publicación
//=============================================================================

// Generico
import './css/App.css';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// API
import APICALL from './APICALL';
import AICALL from './AICALL';

export default function Public() {

  const navigate = useNavigate();
  const [Oferta, setOferta] = useState('');
  const [Final1, setFinal1] = useState('');
  const [Final2, setFinal2] = useState('');
  const [Final3, setFinal3] = useState('');
  const [Final4, setFinal4] = useState('');
  const [Portal, setPortal] = useState('');
  const [FileName, setFileName] = useState(''); 
  const [Pregunta1, setPregunta1] = useState('');
  const [Pregunta2, setPregunta2] = useState('');
  const [Pregunta3, setPregunta3] = useState('');
  const [Pregunta4, setPregunta4] = useState('');
  const [SelectedJID, setSelectedJID] = useState('');
  const [instruccion, setInstruccion] = useState('');
  const [FetchedData, setFetchedData] = useState(''); 
  const [IDlist,setIDlist] = useState([]);
  const [Claves, setClaves] = useState(['']);
  const FileInputRef = useRef(null);
  const [Wait, setWait] = useState(false);
  const [Wait2, setWait2] = useState(false);
  const [Regen, setRegen] = useState(false);
  const [Aviso, setAviso] = useState(false);
  const [ShowPopup, setShowPopup] = useState(false);
  const [ShowPopup2, setShowPopup2] = useState(false);
  const [ShowPopup3, setShowPopup3] = useState(false);
  const [ShowPopup4, setShowPopup4] = useState(false);
  const [ShowPopup5, setShowPopup5] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());


  let limitePublic = 3;
  if (FetchedData.Limite){
    limitePublic = FetchedData.Limite;
  }

  const handleChange = (event) => { setOferta(event.target.value) };

  const Notas1 = 'Genera En el titulo utiliza el formato : '
                  + 'Buscamos {nombre del cargo} para compañía de {industria donde se busca al candidato}.'
                  + 'Genera El aviso como un proceso de búsqueda para la empresa' 
                  + sessionStorage.Grupo
                  + 'En el texto generado refiere a la empresa como "nuestro cliente" en lugar de su nombre';

  const Notas2 = 'Genera El aviso como un proceso de busqueda para una empresa externa' 
                  + 'Mantieniendop un lenguaje formal del estilo "la empresa"';

  const Notas3 = 'Genera El aviso como un proceso de busqueda para una empresa incognita' 
                  + 'evita cualquier mencion del nombre de la empresa';


     
  // Actualización de Procesos
  const Update = () => {

    if (String(SelectedJID).includes('YP_')) {
      UpdateYAPO();
    } else if (String(SelectedJID).includes('renee')) {
      window.alert('Este es un proceso interno, no se puede actualizar');
    } else {  
      UpdateTrabajando(SelectedJID);
    }

    setShowPopup3(false);
  }


  // Agregar Slot de publicacion
  async function PublicIncreace() {
    setWait(true);
    setWait2(true);
    let limite;
    if (FetchedData.Limite){
      limite = FetchedData.Limite + 1;
    } else {
      limite = 4;
    }
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo, 
        Limite: limite,
      });
      window.location.reload()
    } catch (error) {
      console.error('Error during fetch:', error);
    }  finally {
      setTimeout(() => {
        setWait(false);
        setWait2(false);
      }, 500); 
    }
  };
  async function PublicDecreace() {
    setWait(true);
    setWait2(true);
    let limite;
    if (FetchedData.Limite){
      limite = FetchedData.Limite - 1;
    } else {
      limite = 4;
    }
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo, 
        Limite: limite,
      });
      window.location.reload()
    } catch (error) {
      console.error('Error during fetch:', error);
    }  finally {
      setTimeout(() => {
        setWait(false);
        setWait2(false);
      }, 500); 
    }
  };
  
  
  // Publicación Trabajando
  async function ExportToTrabajando() {
    await SaveDataOferta()
    await SaveDataPreguntas()
    setWait(true);
    setWait2(true);

    try {
      const reply = await APICALL({
        apicall: 'TB_create',
        code: sessionStorage.process,
        user: sessionStorage.token,
        mail: sessionStorage.mail,
        U_emp: sessionStorage.Grupo
      });
      SaveJobId(reply.id);
      window.open(reply.url);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setTimeout(() => {
      setWait(false);
      setWait2(false);
    }, 500);

    setShowPopup4(false);
  };
  async function UpdateTrabajando( SelectedJID ) {
    await SaveDataOferta()
    await SaveDataPreguntas()
    setWait(true);
    setWait2(true);

    try {
      const reply = await APICALL({
        apicall: 'TB_update',
        code: sessionStorage.process,
        user: sessionStorage.token,
        mail: sessionStorage.mail,
        U_emp: sessionStorage.Grupo,
        JobId: SelectedJID
      });
      window.open(reply.url);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setTimeout(() => {
      setWait(false);
      setWait2(false);
    }, 500);
  };


  // Publicación YAPO
  async function ExportToYAPO() {
    await SaveDataOferta()
    await SaveDataPreguntas()
    setWait(true);
    setWait2(true);

    try {
      const reply = await APICALL({
        apicall: 'YP_create',
        code: sessionStorage.process,
        count: IDlist.filter(id => String(id).includes("YP_")).length,
        user: sessionStorage.token,
        mail: sessionStorage.mail,
        U_emp: sessionStorage.Grupo
      });
      await APICALL({
        apicall: 'YP_create',
        code: sessionStorage.process,
        count: IDlist.filter(id => String(id).includes("YP_")).length,
        user: sessionStorage.token,
        mail: sessionStorage.mail,
        U_emp: sessionStorage.Grupo
      });
      SaveJobId('YP_'+reply.id);
      window.open(reply.url);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setTimeout(() => {
      setWait(false);
      setWait2(false);
    }, 500);

    setShowPopup4(false);
  };
  async function UpdateYAPO() {
    await SaveDataOferta()
    await SaveDataPreguntas()
    setWait(true);
    setWait2(true);

    try {
      const reply = await APICALL({
        apicall: 'YP_update',
        code: sessionStorage.process,
        Pcode: SelectedJID.replace("YP_", ""),
        user: sessionStorage.token,
        mail: sessionStorage.mail,
        U_emp: sessionStorage.Grupo
      });
      window.open(reply.url);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setTimeout(() => {
      setWait(false);
      setWait2(false);
    }, 500);

    setShowPopup4(false);
  };


  // Generación de contenido
  async function GenerateOferta(notas) {
    setWait(true);
    setOferta('Espera un momento mientras Reneé Trabaja ...')
    try {
      const newText = await AICALL({
        apicall: '02',
        data: notas 
              + JSON.stringify(FetchedData.basic_data)
              + '. Perfil de busqueda: '
              + localStorage.reply
              + '. Notas Adicionales del cargo : '
              + localStorage.notas
              + '. Notas Adicionales de la empresa : '
              + localStorage.notasemp
              + '. Informacion anexa : '
              + localStorage.archivo
      });
      setOferta(FormatResponse(JSON.parse(newText.reply)));
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setTimeout(() => {
      setWait(false);
    }, 500);
  };
  async function GeneratePreguntas() {
    setWait2(true);
    if (!Final1){
      setPregunta1('Espera un momento mientras Reneé Trabaja ...')
    }
    if (!Final2){
      setPregunta2('Espera un momento mientras Reneé Trabaja ...')
    }
    if (!Final3){
      setPregunta3('Espera un momento mientras Reneé Trabaja ...')
    }
    if (!Final4){
      setPregunta4('Espera un momento mientras Reneé Trabaja ...')
    }
    
    try {
      const newText = await AICALL({
        apicall: '03',
        data: localStorage.reply
              + '. Notas Adicionales del cargo : '
              + localStorage.notas
              + '. Notas Adicionales de la empresa : '
              + localStorage.notasemp
              + '. Informacion anexa : '
              + localStorage.archivo
              + 'no repitas estas preguntas : ' + Pregunta1 + Pregunta2 + Pregunta3 + Pregunta4 
      });
    
      const parsedReply = JSON.parse(newText.reply);
    
      if (!Final1) {
        setPregunta1(parsedReply.Pregunta_1);
      }
      if (!Final2) {
        setPregunta2(parsedReply.Pregunta_2);
      }
      if (!Final3) {
        setPregunta3(parsedReply.Pregunta_3);
      }
      if (!Final4) {
        setPregunta4(parsedReply.Pregunta_4);
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    
    setTimeout(() => {
      setWait2(false);
    }, 500);
  };
  async function HandleIterateText() { 
    if (Aviso){
      setWait(true);
    } else {
      setWait2(true);
    }
    // console.log(text);
    try {
      const response = await AICALL({
        apicall: Aviso ? '02' : '03',
        data: Aviso ? `${Oferta} ${instruccion}` 
                    : `${Pregunta1} ${Pregunta2} ${Pregunta3} ${Pregunta4} ${instruccion}`
                    + 'Asegurate de mantener el mismo formato que el texto original sin agregar elementos adicionales'
                    + '. Notas Adicionales del cargo : '
                    + localStorage.notas
                    + '. Notas Adicionales de la empresa : '
                    + localStorage.notasemp
                    + '. Informacion anexa : '
                    + localStorage.archivo

      });
      if ( Aviso ) {
        setOferta(FormatResponse(JSON.parse(response.reply)));
      } else {
        const parsedReply = JSON.parse(response.reply)
        setPregunta1(parsedReply.Pregunta_1);
        setPregunta2(parsedReply.Pregunta_2);
        setPregunta3(parsedReply.Pregunta_3);
        setPregunta4(parsedReply.Pregunta_4);
      }
      setInstruccion('');
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setTimeout(() => {
      setWait(false);
      setWait2(false);
    }, 1000);;
  };


  // Procesado Imagen
  const HandleFile = () => {
    FileInputRef.current.click();
  };
  async function HandleFileChange(event) {
    const image = event.target.files[0];
    if (image) {
      setFileName(image.name); 
    } else {
      return
    }
  
    const fileMimeType = image.type; 
    console.log(fileMimeType);
  
    if (fileMimeType === 'image/png' || fileMimeType === 'image/jpeg' || fileMimeType === 'image/webp' ) {

      const data = new FormData();

      data.append('apicall', 'FX_01'); 
      data.append('P_name', sessionStorage.name.replace(/\|/g, '').replace(/ /g, '_').replace(/__/g, '_')); 
      data.append('file', image); 

      
      for (let [key, value] of data.entries()) {
        console.log(key, value);
      }



      try {
        
        await APICALL(data);

      } catch (error) {
        console.error('Error procesando la imagen', error);
      }

    } else {
      DropContent()
      window.alert('Formato no soportado. Actualmente solo se aceptan los formatos:\n * .Png\n *.Jpeg\n *.webp');
    }
  };
  async function DropContent() {
    setInputKey(Date.now());
    setFileName('');
  };


  // Recuperado y Guardado de datos
  async function SaveDataOferta() {
    setWait(true);
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo, 
        Claves: Claves,
        GPToferta: Oferta
      });
      return true;
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }  finally {
      setTimeout(() => {
        setWait(false);
      }, 500); 
    }
  };
  async function SaveDataPreguntas() {
    setWait2(true);
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo, 
        GPTpreguntas: [Pregunta1.replace(/"/g, '') , Pregunta2.replace(/"/g, '') , Pregunta3.replace(/"/g, '') , Pregunta4.replace(/"/g, '')]
      });
      return true;
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }  finally {
      setTimeout(() => {
        setWait2(false);
      }, 500); 
    }
  };
  // async function FetchData () {
  //   const data = await APICALL({
  //     apicall :'PD_fetch' ,
  //     code :sessionStorage.process,
  //     U_emp: sessionStorage.Grupo
  //   });
  //   setOferta(data.GPToferta);
  //   if (!data.GPToferta){
  //     GenerateOferta();
  //   } 
  //   if (!data.GPTpreguntas){
  //     GeneratePreguntas();
  //   }
  // };
  async function SaveJobId(ID) {
    if (!IDlist.includes(ID)) {
      IDlist.push(ID);
    }
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo,
        JobId: IDlist.filter(item => {return item !== null && item !== undefined && item !== ''})
      });
      window.location.reload()
      return true;
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };


  // Visualizacion de oferta
  const FixURL = (url) => {
    const title = FetchedData.Charge_title.toLowerCase().replace(/\s+/g, '-');
    if ( String(url).includes('YP_') ){
      const ID = String(url).replace("YP_", "")
      const reply = `https://www.yapo.cl/empleos-ofertas-de-trabajos/${title}/${ID}`;

      return reply

    } else {
      const ID = String(url)
      const reply = `https://renee.trabajando.cl/trabajo/${ID}-${title}`;

      return reply

    }
  }


  // Formateo de contenido
  const FormatResponse = (jobDetails) => {
    
    let formattedText = '';

    formattedText += jobDetails.Descripcion_de_la_empresa+ '\n\n';
  
    formattedText += `**Misión del Cargo:**\n${jobDetails.Mision_del_cargo}\n\n`;
  
    formattedText += `**Responsabilidades:**\n`;
    jobDetails.Responsabilidades.forEach((responsabilidad, index) => {
      formattedText += `~${responsabilidad}~\n`;
    });
    formattedText += `\n`;
  
    formattedText += `**Requisitos Clave:**\n`;
    jobDetails.Claves.forEach((clave, index) => {
      formattedText += `~${clave}~\n`;
    });
    formattedText += `\n`;
  
    formattedText += `**Oferta:**\n`;
    formattedText += `~Banda Salarial: ${jobDetails.Oferta.Banda_salarial}~\n`;
    formattedText += `~Horario: ${jobDetails.Oferta.Horario}~\n`;
    formattedText += `~Modalidad de Trabajo: ${jobDetails.Oferta.Modalidad_de_trabajo}~\n`;
    formattedText += `~Beneficios Adicionales: ${jobDetails.Oferta.Beneficios_adicionales}~\n`;
    formattedText += `\n`;

    formattedText += jobDetails.Captura + '\n';

    let formatedclave = ''
    jobDetails.Claves.forEach((clave, index) => {
      formatedclave += `~${clave}~\n`;
    });
    setClaves(formatedclave);
    return formattedText;
  };


  // Navegación
  const goto05 = () => { navigate('/P_Perfil') };
  const goto07 = () => { navigate('/P_Candidatos') };


  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall :'PD_fetch' ,
        code :sessionStorage.process,
        U_emp: sessionStorage.Grupo
      });
      setIDlist(data.JobId || []);
      setFetchedData(data);
      setClaves(data.Claves)
      if (data.GPToferta){
        setOferta(data.GPToferta);
      } else {
        GenerateOferta();
      }
      if (data.GPTpreguntas){
        
        setPregunta1(data.GPTpreguntas[0]);
        setPregunta2(data.GPTpreguntas[1]);
        setPregunta3(data.GPTpreguntas[2]);
        setPregunta4(data.GPTpreguntas[3]);
      } else {
        GeneratePreguntas();
      }
      
    };
    fetchData();
    // eslint-disable-next-line
  }, []);
  const dataItems = [
    { label: 'NOMBRE DEL CARGO', value: FetchedData.Charge_name ? FetchedData.Charge_name.toUpperCase() : ' - - - ' }, 
    { label: 'EMPRESA',                 value: FetchedData.E_name }, 
		{ label: 'PARTNER ENCARGADO',       value: FetchedData.R_name },
    { label: 'INTERLOCUTOR',            value: FetchedData.I_name }
  ];


  //############################################################################################################
  //############################################################################################################

  return (
    <div className='BackGround'>

      {/* Popup Iteracion */}
      <div className={ShowPopup ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup(false)} >
        </div>
        <div className={ShowPopup ? 'warningbox' : 'hidden'}>
          <h2>Instrucciones Adicionales {Aviso ? 'Aviso' : 'Preguntas'}:</h2>
          <p style={{ padding:'1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            Ingresa cualquier instrucción adicional que quieras que Renee tenga en cuenta al generar la nueva iteracion {Aviso ? 'del Aviso' : 'de las Preguntas'}.
          </p>
          <textarea
            className='notas'
            style={{marginLeft:'2vh', marginRight:'2vh'}}
            value={instruccion}
            onChange={(e) => setInstruccion(e.target.value)}>
          </textarea>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => {HandleIterateText( instruccion ); setShowPopup(false) }}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Generar Iteracion </button>
            <button onClick={() => setShowPopup(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Popup Publicacion */}
      <div className={ShowPopup2 ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup2(false)} >
        </div>
        <div className={ShowPopup2 ? 'warningbox' : 'hidden'} style={{height:`${45+(IDlist.length*5)}vh`}}>
          <h2>Publica tu Oferta:</h2>
          <p style={{ padding:'1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            {IDlist.length === 0 ? 'Vamos a publicar tu Oferta generada, puedes elegir entre los siguientes portales de empleo'
                        : 'Esta Oferta ya fue publicada previamente en estos portales : '}
          </p>
          <div className={IDlist.length === 0 ? 'hidden' : ''} style={{display:'flex', flexDirection:'column',gap:'1vh'}}>
            {IDlist.map((item, index) => (
                <div key={index}>
                  <div style={{display:'flex'}}>
                    <div className='data-box' style={{width:'30vh', borderRadius:'1vh 0vh 0vh 1vh', borderRight:'none'}}>
                       {String(item)?.includes("renee") ? 'Publicacion interna' : String(item)?.includes("YP_") ? 'Publicacion en Yapo' : 'Publicacion en Trabajando'} 
                    </div>
                      <button className={'data-box'} 
                              onClick={() => {setShowPopup2(false); setShowPopup3(true); setSelectedJID(item)}}
                              style={{width:'50vh', borderRadius:'0vh 1vh 1vh 0vh'}}> 
                        {String(item)?.includes("renee") ? item : String(item)?.includes("YP_") ? item : 'TB_' + item} 
                      </button>                  
                    </div>
                </div>
            ))}
          </div>
          <p className={IDlist.length === 0 ? 'hidden' : ''} style={{ padding:'1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            {'Aun asi puedes elegir re-publicar tu Oferta en uno los siguientes portales de empleo'} <br/><br/>
            {IDlist.length < limitePublic  ? `Aún tienes ${limitePublic - IDlist.length} publicaciones de oferta disponibles` 
                                : ' No tienes espacion de publicacion de oferta disponibles ' } <br/><br/>
            {'Recuerda que puedes elegir actualizar tu oferta'} &nbsp;
            <button onClick={() => {setShowPopup2(false); setShowPopup3(true)}} style={{width:'25%', display: 'inline'}}> Presionando aqui</button>
          </p>

          <div style={{display:'flex', gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button className = {IDlist.length < limitePublic ? '' : 'hidden'}
                        onClick={() => {setShowPopup4(true); setPortal('Trabajando')}}>Publicar en TRABAJANDO.COM</button>
            <button className = {IDlist.length < limitePublic ? '' : 'hidden'}
                        onClick={() => {setShowPopup4(true); setPortal('Yapo')}}>Publicar en YAPO.CL</button>
            <button onClick={() => setShowPopup2(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Popup Actualizacion */}
      <div className={ShowPopup3 ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => {setShowPopup3(false); setSelectedJID('')}} >
        </div>
        <div className={ShowPopup3 ? 'warningbox' : 'hidden'} style={{height:`${45+(IDlist.length*5)}vh`}}>
          <h2>Actualiza tu Oferta:</h2>
          <p style={{ padding:'1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            {IDlist.length === 0 ? 'Hey parece que no has publicado tu Oferta todavia'
                        : 'Ahora que tienes tu Oferta lista selecciona el portal en el que quieres actualizar : '}
          </p>
          <div className={IDlist.length === 0 ? 'hidden' : ''} style={{display:'flex', flexDirection:'column',gap:'1vh'}}>
            {IDlist.map((item, index) => (
                <div key={index} >
                  <div style={{display:'flex'}}>
                    <div className='data-box' style={{width:'30vh', borderRadius:'1vh 0vh 0vh 1vh', borderRight:'none'}}>
                       {String(item)?.includes("renee") ? 'Publicacion interna' : String(item)?.includes("YP_") ? 'Publicacion en Yapo' : 'Publicacion en Trabajando'} 
                    </div>
                      <button className={SelectedJID === item ? 'data-box highlighted' : 'data-box'} 
                              onClick={() => setSelectedJID(item)}
                              style={{width:'50vh', borderRadius:'0vh 1vh 1vh 0vh'}}> 
                        {String(item)?.includes("renee") ? item : String(item)?.includes("YP_") ? item : 'TB_' + item}  
                      </button>
                  </div>
                </div>
            ))}
          </div>
          <p className={IDlist.length === 0 ? 'hidden' : ''} style={{ padding:'1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            {'Ahora solo presiona confirmar para actualizar tu Oferta en el portal seleccionado'} 
          </p>
          
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button className = {SelectedJID ? '' : 'hidden'}
                        onClick={Update}>Confirmar</button>
            <button onClick={() => {setShowPopup3(false); setSelectedJID('')}}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Popup Detalles */}
      <div className={ShowPopup4 ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => {setShowPopup4(false); setSelectedJID('')}} >
        </div>
        <div className={ShowPopup4 ? 'warningbox' : 'hidden'}>
          <h2>Publica tu oferta por {Portal}</h2>
          <p className={Portal === 'Trabajando' ? '' : 'hidden'} style={{ padding:'1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            Trabajando.com es una plataforma enfocada en perfiles profesionales y técnicos, ideal para buscar candidatos con educación superior o experiencia especializada. <br/><br/>
            ✔ Postulantes con formación en áreas como administración, ingeniería, TI y más.<br/>
            ✔ Ideal para empleos con requisitos específicos y cargos profesionales.<br/>
            ✔ Procesos de selección más estructurados. <br/><br/>
            ¿Estás seguro de que este es el portal adecuado para tu Oferta?
          </p>
          <p className={Portal === 'Yapo' ? '' : 'hidden'} style={{ padding:'1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            Yapo.cl es una plataforma más rápida y directa, ideal para encontrar trabajadores operativos y temporales <br/> sin tantos filtros en el proceso de selección. <br/><br/>
            ✔ Enfocado en empleos en ventas, logística, construcción, servicios y más.<br/>
            ✔ Postulación rápida y contacto directo con candidatos. <br/>
            ✔ Menos requisitos formales, más alcance para empleos prácticos <br/><br/>
            ¿Estás seguro de que este es el portal adecuado para tu Oferta?
          </p>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button className={Portal === 'Trabajando' ? '' : 'hidden'} onClick={ExportToTrabajando}>Confirmar publicacion por TRABAJANDO</button>
            <button className={Portal === 'Yapo' ? '' : 'hidden'} onClick={ExportToYAPO}>Confirmar publicacion por YAPO</button>
            <button onClick={() => {setShowPopup4(false); setSelectedJID('')}}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Popup Ver URLs */}
      <div className={ShowPopup5 ? 'WarningBG' : 'hidden'}>
        <div className="outside" onClick={() => { setShowPopup5(false); setSelectedJID(''); }}></div>
        
        <div className={ShowPopup5 ? 'warningbox' : 'hidden'} style={{ height: `${45 + (IDlist.length * 5)}vh` }}>
          <h2>Ver tu publicación:</h2>
          
          <p style={{ padding: '1vh', alignItems: 'center', fontSize: '1.2rem' }}>
            {IDlist.filter(item => !String(item).includes("renee") && !String(item).includes("YP_")).length === 0
              ? 'Hey, parece que no tienes publicaciones en Trabajando todavía.'
              : 'Revisa tus publicaciones en internet haciendo Click en los siguientes links:'}
          </p>

          <div className={IDlist.length === 0 ? 'hidden' : ''} style={{ display: 'flex', flexDirection: 'column', gap: '1vh' }}>
            {IDlist
              .filter(item => !String(item).includes("renee") && !String(item).includes("YP_")) // Filtra solo las de Trabajando
              .map((item, index) => (
                <div key={index}>
                  <div style={{ display: 'flex' }}>
                    <div className="data-box" style={{ width: '30vh', borderRadius: '1vh 0vh 0vh 1vh', borderRight: 'none' }}>
                      Publicación en Trabajando
                    </div>
                    <button 
                      className={SelectedJID === item ? 'data-box highlighted' : 'data-box'} 
                      onClick={() => window.open(FixURL(String(item)))}
                      style={{ width: '50vh', borderRadius: '0vh 1vh 1vh 0vh' }}
                    > 
                      {FixURL(String(item))}
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <div style={{ display: 'flex', gap: '1vh', padding: '1vh', width: '-webkit-fill-available' }}>
            <button onClick={() => { setShowPopup5(false); setSelectedJID(''); }}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Type Header */}
      <div className='typing-effect-container'>
				<h2 className='typing-effect' > 
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          😍 También he creado para ti este AVISO DE EMPLEO. Puedes editarlo o pedirme que lo genere nuevamente
        </h2>
			</div>
      
      <div className='MainBody'>

        {/* Aviso Generado */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{marginRight: '1vh', marginBottom: '-0.4vh'}}>
          <h3 className = 'boxtitle'>Aviso Generado</h3>
          { Wait === true ? (
              <div>
                <div className='notas' style={{display:'flex', alignItems:'center', height:'68vh'}}>
                  <img
                    src={'/favicon.png'}
                    alt='Profile'
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                      position:'stick'
                    }}/>
                  <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                  <div className='loader-container'>
                    <div  className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                  </div>
                </div>
              </div>

            ) : (
              <div>
                {/* bottones superior */}
                <div className='notas' style={{ display:'flex', 
                            height: '4vh',
                            gap: '1vh',
                            marginBottom: '0vh',
                            flexDirection:'row',
                            borderRadius:'0vh 1vh 0vh 0vh',
                            borderBottom: 'none',
                            width:'-webkit-fill-available', 
                            overflow:'hidden'}}>
                <button className='Save' onClick={() => {setRegen(!Regen)}}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Regenerar Aviso </button>
                <button className='Save' onClick={() => {setShowPopup(true); setAviso(true)}}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Iterar Aviso </button>              
                <button onClick={SaveDataOferta}> <i className='fa-solid fa-floppy-disk'></i> &nbsp; Guardar </button>
                </div>
                <div className={Regen ? 'outside' : ''}  onClick={() => {setRegen(false)}} >
                  <div  className={Regen ? 'notas' : 'hidden'}
                        style={{ position:'absolute', 
                                      display:'flex', 
                                      flexDirection:'column', 
                                      overflow:'hidden',
                                      height:'fit-content',
                                      gap:'1vh', 
                                      width:'15%',
                                      marginLeft:'2.2vh',
                                      borderRadius:'0vh 0vh 1vh 0vh'}}>
                    <button className = {Regen ? '' : 'hidden'}
                        onClick={() => GenerateOferta()}> Como Aviso Regular</button>
                    <button className = {Regen ? '' : 'hidden'}
                            onClick={() => GenerateOferta(Notas1)}>Como Consultora</button>
                    <button className = {Regen ? '' : 'hidden'}
                            onClick={() => GenerateOferta(Notas2)}>Como Empresa Contratante</button>
                    <button className = {Regen ? '' : 'hidden'}
                            onClick={() => GenerateOferta(Notas3)}>Como Empresa Confidencial</button>
                  </div>
                </div>
                <textarea className='notas'
                style={{height:'67vh', borderRadius:'0vh 0vh 1vh 1vh'}}
                value={Oferta}
                onChange={handleChange}
                spellCheck />
              </div>
            )
          }
        </div>

        {/* Preguntas Generadas */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{marginRight: '1vh', marginBottom: '-0.4vh'}}>
          <h3 className = 'boxtitle'>Preguntas de Aviso</h3>
          { Wait2 === true ? (
              <div>
                <div className='notas' style={{display:'flex', alignItems:'center', height:'68vh'}}>
                  <img
                    src={'/favicon.png'}
                    alt='Profile'
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                      position:'stick'
                    }}/>
                  <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                  <div className='loader-container'>
                    <div  className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                  </div>
                </div>
              </div>

            ) : (
            <div>
               <div className='notas' style={{ display:'flex', 
                           height: '4vh',
                           gap: '1vh',
                           marginBottom: '0vh',
                           flexDirection:'row',
                           borderRadius:'0vh 1vh 0vh 0vh',
                           borderBottom: 'none',
                           width:'-webkit-fill-available', 
                           overflow:'hidden'}}>
                <button className='Save' onClick={GeneratePreguntas}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Regenerar Preguntas </button>
                <button className='Save' onClick={() => {setShowPopup(true); setAviso(false)}}> <i className='fa-solid fa-wand-magic-sparkles'></i> &nbsp; Iterar Preguntas </button>
                <button onClick={SaveDataPreguntas}> <i className='fa-solid fa-floppy-disk'></i> &nbsp; Guardar </button>
               </div>
               <div className='notas' 
                    style={{overflow:'hidden', 
                            paddingTop:'1vh', 
                            paddingRight:'0.5vh',
                            height:'67vh',
                            alignItems:'flex-end', 
                            borderRadius:'0vh 0vh 1vh 1vh'}}>
                <div style={{display:'flex', flexDirection:'column', width:'-webkit-fill-available', marginBottom:'-1vh'}}>
                  <textarea className={Final1 ? 'preguntas preguntasSaved' : 'preguntas'}
                    value={Pregunta1.replace(/"/g, '')}
                    style={{position:'relative', height:'13vh', paddingRight:'3vh'}}
                    placeholder='espere mientras se genera el contenido...'
                    onChange={(event) => { let newValue = event.target.value ; setPregunta1(newValue) }}/>
                  <button style={{position:'relative', width:'4vh', top:'-15vh', left:'32.3vw'}} 
                          onClick={() => {  if (Final1 === '') {
                                              setFinal1(Pregunta1)
                                            } else {
                                              setFinal1('')
                                            }}}> 
                      {Final1 === '' ? (
                        <i className="fa-solid fa-lock-open"></i>
                      ) : (
                        <i className='fa-solid fa-lock'></i> 
                      )}
                  </button>
                </div>
          
                <div style={{display:'flex', flexDirection:'column', width:'-webkit-fill-available', marginBottom:'-1vh'}}>
                  <textarea className={Final2 ? 'preguntas preguntasSaved' : 'preguntas'}
                    value={Pregunta2.replace(/"/g, '')}
                    style={{position:'relative', height:'13vh', paddingRight:'3vh', marginTop:'-1.5vh'}}
                    placeholder='espere mientras se genera el contenido...'
                    onChange={(event) => { let newValue = event.target.value ; setPregunta2(newValue) }}/>
                  <button style={{position:'relative', width:'4vh', top:'-15vh', left:'32.3vw'}} 
                          onClick={() => {  if (Final2 === '') {
                                              setFinal2(Pregunta2)
                                            } else {
                                              setFinal2('')
                                            }}}> 
                      {Final2 === '' ? (
                        <i className="fa-solid fa-lock-open"></i>
                      ) : (
                        <i className='fa-solid fa-lock'></i> 
                      )}
                  </button>
                </div> 

                <div style={{display:'flex', flexDirection:'column', width:'-webkit-fill-available', marginBottom:'-1vh'}}>
                  <textarea className={Final3 ? 'preguntas preguntasSaved' : 'preguntas'}
                    value={Pregunta3.replace(/"/g, '')}
                    style={{position:'relative', height:'13vh', paddingRight:'3vh', marginTop:'-1.5vh'}}
                    placeholder='espere mientras se genera el contenido...'
                    onChange={(event) => { let newValue = event.target.value ; setPregunta3(newValue) }}/>
                  <button style={{position:'relative', width:'4vh', top:'-15vh', left:'32.3vw'}} 
                          onClick={() => {  if (Final3 === '') {
                                              setFinal3(Pregunta3)
                                            } else {
                                              setFinal3('')
                                            }}}> 
                      {Final3 === '' ? (
                        <i className="fa-solid fa-lock-open"></i>
                      ) : (
                        <i className='fa-solid fa-lock'></i> 
                      )}
                  </button>
                </div>
          
                <div style={{display:'flex', flexDirection:'column', width:'-webkit-fill-available', marginBottom:'-1vh'}}>
                  <textarea className={Final4 ? 'preguntas preguntasSaved' : 'preguntas'}
                    value={Pregunta4.replace(/"/g, '')}
                    style={{position:'relative', height:'13vh', paddingRight:'3vh', marginTop:'-1.5vh'}}
                    placeholder='espere mientras se genera el contenido...'
                    onChange={(event) => { let newValue = event.target.value ; setPregunta4(newValue) }}/>
                  <button style={{position:'relative', width:'4vh', top:'-15vh', left:'32.3vw'}} 
                          onClick={() => {  if (Final4 === '') {
                                              setFinal4(Pregunta4)
                                            } else {
                                              setFinal4('')
                                            }}}> 
                      {Final4 === '' ? (
                        <i className="fa-solid fa-lock-open"></i>
                      ) : (
                        <i className='fa-solid fa-lock'></i> 
                      )}
                  </button>
                </div>  
               </div>
            </div>
            )
          }
        </div>

        {/* Menu Lateral */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='data-boxes'>

          {/* Data del proceso */}
          {dataItems.map((item, index) => (
            <div key={index} className='data-box'>
              <strong>{item.label}:</strong> {item.value}
            </div>
          ))}

          {/* Adjuntado de Imagen  */}
          <div style={{display: 'flex' , gap : '0.5vh', background:'var(--mid-light)', border:' 0.3vh solid var(--main)', borderRadius: '1vh', padding: '0.5vh'}}>
            <button onClick={HandleFile}>
            <i className="fa-solid fa-paperclip"></i>  &nbsp; {FileName || 'ADJUNTAR IMAGEN PARA LA PUBLICACION'}
            </button>
            <input  key = {inputKey}
                    id = 'fileContent'
                    type='file'  
                    onChange={HandleFileChange}
                    ref={FileInputRef} 
                    className='hidden'/>
            
            <button  className='redb box-button' onClick={DropContent}>
            <i className="fa-solid fa-x"></i></button>
          </div>

          {/* Botones */}
          <div style={{display:'grid', gridTemplateColumns:'1fr 1fr', columnGap:'0.5vh', rowGap:'0.5vh'}}>      

            {/* <button 
                    style={{height:'6vh'}}
                    onClick={FetchData}> <i className='fa-solid fa-rotate-left'></i> &nbsp; Deshacer Cambios </button> */}

            <button style={{height:'6vh'}}
                    onClick={() => {setShowPopup2(true)}}>
                    Publicar la Oferta</button>

            <button style={{height:'6vh'}}
                    onClick={() => {setShowPopup3(true)}}>
                    Actualizar la Oferta</button>         

            <button className={sessionStorage.mail === 'admin' ? '' : 'hidden'}
                    style={{height:'6vh'}}>
                    Slots Actuales: {FetchedData.Limite || 3}</button>               
             
            <button style={{height:'6vh'}}
                    onClick={() => {setShowPopup5(true)}}>
                    Ver la Publicacion</button>    

            <button className={sessionStorage.mail === 'admin' ? '' : 'hidden'}
                    style={{height:'4vh'}}
                    onClick={PublicIncreace}>
                    ▲ MAS Slots </button>   
            <button className={sessionStorage.mail === 'admin' ? '' : 'hidden'}
                    style={{height:'4vh'}}
                    onClick={PublicDecreace}>
                    ▼ MENOS Slots</button>  
                      
          </div>
        </div>
      </div>

      <div className={ Wait || Wait2 ? 'hidden' : 'bottom'}>
        <button onClick={goto05}>volver</button>
        <button className={IDlist.length > 0 ? 'Save last-button' : 'hidden'} onClick={() => {SaveDataOferta(); SaveDataPreguntas(); goto07()}}>Guardar y Continuar</button>
      </div>
      
    </div>
  );
}
