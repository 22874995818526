
//=============================================================================
// Pantalla Recuperación de Respuestas Pre-Screening
//=============================================================================

// Generico
import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// API
import APICALL from './APICALL';
import LOGCALL from './LOGCALL';

export default function Documentos() {
  const navigate = useNavigate();

  const [SortType, setSortType] = useState('chrono');
  const [CVlist, setCVlist] = useState([]);
  const [SelectList3, setselectlist3] = useState([]);
  const [URLlist, setURLlist] = useState([]);
  const [Wait, setWait] = useState(false);
  const [SelectedCVId, setSelectedCVId] = useState(null);
  const [Form, setForm] = useState([true, true, false, true, false, false, false, false, false, false, '']);
  const [ShowPopup, setShowPopup] = useState(false);
  const [ShowPopup2, setShowPopup2] = useState(false);
  const [SelectedCandidates, setSelectedCandidates] = useState([]);
  const [FetchedData, setFetchedData] = useState({});
  const [SelectedName, setSelectedName] = useState('');
  const [SelectedMail, setSelectedMail] = useState('');

  const [Cartalist, setCartalist] = useState([]);
  
  
  let baseUrl

  if (sessionStorage.environmentLog === 'Local') {
    baseUrl = 'http://localhost:3001';
  } else {
    baseUrl = 'https://forms.renee.cl';
  }

  // Orden de candidatos
  const SortCVlist = () => {
    const sortedList = [...CVlist].sort((a, b) => {
      if (SortType === 'score') {
        const notaA = a.Nota ? JSON.parse(a.Nota) : 0;
        const notaB = b.Nota ? JSON.parse(b.Nota) : 0;
        return notaB - notaA; 
      } else if (SortType === 'stars') {
        const starsA = a.stars3 ? a.stars3 : 0;
        const starsB = b.stars3 ? b.stars3 : 0;
        return starsB - starsA; 
      } else {
        return a.name.localeCompare(b.name); 
      }
    });

    if (SortType === 'chrono') {
      return CVlist;
    } else {
      return sortedList;
    }
  };
  const quitarAcentos = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  
  // Recuperacion y guardado de formulario
  async function SaveForm() {
    
    setWait(true);

    await APICALL({
      apicall: 'PD_update',
      code: sessionStorage.process,
      U_emp: sessionStorage.Grupo,
      FForm: Form
    });

    try {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo
      });
      if (data.FForm) {
        setForm(data.FForm)
      }
    } catch (error) {
      console.error('Error during fetch:', error);
    }

    setTimeout(() => {
      setWait(false);
    }, 500);
  };
  const ChangeForm = (index) => {

    setForm(prevForm => {
      const newForm = [...prevForm];
      newForm[index] = !newForm[index]; 
      console.log(newForm)
      return newForm;
    });
  };
  const hashStringToInt = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash = hash & hash; 
    }
    // console.log(Math.abs(hash))
    return String(Math.abs(hash)); 
  };
  async function FetchDoc( name ) {
    
    setWait(true);

   const URLs = await APICALL({
      apicall: 'FX_03',
      userName: quitarAcentos(name),
      ID: hashStringToInt(sessionStorage.process)
    });

    if (URLs) {
      setURLlist(URLs.documents);
    } else {
      setURLlist([]);
    }
    setTimeout(() => {
      setWait(false);
    }, 500);
  };
  const documentoCOF = Cartalist.find(item => 
    quitarAcentos(String(item)).includes(`${quitarAcentos(SelectedCVId)}_${hashStringToInt(sessionStorage.process)}_COF`)
  );


  // Subida Carta Oferta
  const handleFileChange = async ( name, event ) => 
    {
    const file = event.target.files[0];
    
    if (file) {

      console.log('File selected:', file.name);

      try {

          const data = new FormData();
          data.append('apicall', 'FX_02');
          data.append('case', 'final'); 
          data.append('P_name', `${quitarAcentos(name)}_${hashStringToInt(sessionStorage.process)}_CO`);
          data.append('file', file);

          for (let [key, value] of data.entries()) {
            console.log(key, value);
          }

          const response = await APICALL(data);
          console.log('API Response:', response); 
          
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
    window.location.reload();
  };


  // Envio de Correos
  const ToggleCandidateSelection = (name) => {
    setSelectedCandidates(prev =>
      prev.includes(name) ? prev.filter(item => item !== name) : [...prev, name]
    );
  };
  async function HandleMailAll( ) {
  
    if (Form.slice(0, 9).some(value => value !== false)) {
      for (const reply of CVlist) {
        if (SelectedCandidates.includes(reply.name)) {
          try {
            await HandleMail(reply.mail, reply.name);     

          } catch (error) {
            console.error(`Error al enviar mensaje a ${reply.name}`);
          }
        }
      }
      window.alert('Formularios enviados a todos los miembros seleccionados');
      
    } else {
      window.alert('No se logro encontrar ID asociado al formulario que se intenta enviar.')
    }

    window.location.reload();
    setShowPopup(false);
  };
  async function HandleMail(mail, name) {

    await APICALL({
      apicall: 'PD_update',
      code: sessionStorage.currentsave,
      U_emp: sessionStorage.Grupo,
      CVData: { name: name, Finalist: true}
    });

    try {

      const encodedForm = toUnicodeEscape(JSON.stringify(Form)); // If Form is an object/array, convert to string
      const encodedID = hashStringToInt(sessionStorage.process);

      await LOGCALL({
        apicall: 'FM_FIN',
        code: sessionStorage.process,
        Mail : mail,
        Title : FetchedData.Charge_title,
        Name : quitarAcentos(name),
        P_name: sessionStorage.token,
        P_mail: sessionStorage.mail,
        URL : `${baseUrl}/?NC=${encodeURIComponent(quitarAcentos(name))}&Form=${encodeURIComponent(encodedForm)}&ID=${encodedID}`
      });
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };
  const toUnicodeEscape = (str) => {
    return str.split('').map(char => {
        const code = char.charCodeAt(0);
        return code > 127 ? `\\u${code.toString(16).padStart(4, '0')}` : char;
    }).join('');
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('URL copiada al portapapeles!');
      })
      .catch(err => {
        console.error('Error al copiar al portapapeles: ', err);
      });
  };


  // Filtros
  const [FilterQuery, setFilterQuery] = useState('');
  const HandleFilterChange = (e) => {
    const query = e.target.value.toLowerCase();
    setFilterQuery(query);
  };


  // Navegacion
  const goto11 = () => { navigate('/P_Referencias') };  


  useEffect(() => {

    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.process,
        U_emp: sessionStorage.Grupo
      });
      setFetchedData(data);

      if (data.FForm) {
        setForm(data.FForm)
      }
      if (data.CVData){
        setCVlist(data.CVData)
        setselectlist3(Array.isArray(data.SelectList3) 
        ? data.SelectList3               
        : data.SelectList3               
          ? [data.SelectList3]         
          : []);
        setSelectedCandidates(Array.isArray(data.SelectList3) 
        ? data.SelectList3               
        : data.SelectList3               
          ? [data.SelectList3]         
          : []);
      }
      FetchCartas();
    };

    async function FetchCartas( ) {
    
      setWait(true);
  
     const URLs = await APICALL({
        apicall: 'FX_04',
        ID: hashStringToInt(sessionStorage.process)
      });
  
      if (URLs) {
        setCartalist(URLs.documents);
      } else {
        setCartalist([]);
      }
      setTimeout(() => {
        setWait(false);
      }, 500);
    };
  
    fetchData();

    // eslint-disable-next-line
  }, []);


  const docsMap = {
    CVU : "CV Actualizado",
    CAFP: "Certificado de AFP",
    CSIF: "Certificado de Salud (Isapre o Fonasa)",
    CN: "Certificado de Nacimiento",
    CI: "Copia Cédula de Identidad",
    CTE: "Certificado de Título / Estudios",
    CCL: "Certificado de Cargas Laborales",
    CR: "Certificado de Residencia / Comprobante de Residencia",
    UFL: "Ultimo Finiquito Laboral ratificado",
    COF: "Carta oferta firmada conforme",
    EX1: Form[10] || '',
    EX2: Form[12] || '',
    EX3: Form[14] || ''
  };


  //############################################################################################################
  //############################################################################################################

  return (

    <div className='BackGround'>

      {/* Main */}
      <div className='typing-effect-container'>
        <h3 className='typing-effect'>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          Seleccion final de candidatos
        </h3>
      </div>

      {/* Popup Envio Mails Masivo */}
      <div className={ShowPopup? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup(false)} >
        </div>
        <div className={ShowPopup? 'warningbox' : 'hidden'}>
          <h2>Confirma a quienes se les enviara la solicitud de documentos</h2>
          <div style={{border:'none'}} className='notas'>
            {CVlist.filter(reply => SelectList3.includes(reply.name)).map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', width: '-webkit-fill-available', alignItems: 'center', marginBottom: '10px' }}>
                <button
                  className={SelectedCandidates.includes(reply.name) ? 'start-button green' : 
                                                                        'start-button'}
                  onClick={() => {
                    ToggleCandidateSelection(reply.name);
                  }}
                >
                  {reply.name}
                </button>
                <input  type='checkbox' 
                        className='select-checkbox'
                        checked={SelectedCandidates.includes(reply.name)}
                        onChange={() => {
                          ToggleCandidateSelection(reply.name);
                        }}>
                </input>
              </div>
            ))}
          </div>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => HandleMailAll()}> <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Enviar Formularios </button>
            <button onClick={() => setShowPopup(false)}> Cancelar</button>
          </div>
        </div>
      </div>  

      {/* Popup Envio Mail Individual */}
      <div className={ShowPopup2 ? 'WarningBG' : 'hidden'}>
        <div className='outside'  onClick={() => setShowPopup2(false)} >
        </div>
        <div className={ShowPopup2 ? 'warningbox' : 'hidden'}>
          <h2>Confirma envio de formulario:</h2>
          <p style={{ alignItems: 'center', fontSize: '1.2rem' }}>
            se le enviara el formulario a: <strong>{SelectedName}</strong> <br /><br />
            con la dirección de correo: <strong>{SelectedMail}</strong> <br /><br />
            O alternativamente, puedes copiar la URL para enviarla manualmente: <br /><br />
            <button 
              style={{ cursor: 'pointer' }} 
              onClick={() => copyToClipboard(`${baseUrl}/?NC=${encodeURIComponent(SelectedName)}&Form=${Form}&ID=${hashStringToInt(sessionStorage.process)}`)}>
              <strong>Has click aqui para copiar la URL del formulario</strong>
            </button>
          </p>
          <div style={{display:'flex',gap:'1vh', padding:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => {HandleMail(SelectedMail, SelectedName); window.alert(` Formulario enviado al candidato ${SelectedName} `); setShowPopup2(false) }}><i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Enviar Formulario  </button>
            <button onClick={() => setShowPopup2(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      <div className='MainBody'>

        {/* Lista de candidatos */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit' style={{ maxWidth: '20vw', marginRight:'1vh' }}>

          {/* Menu superior */}
          <div style={{display:'flex'}}>
            <h3 className='boxtitle'>Candidatos : {SelectList3.length}</h3> 
            <button
              className='sortbutton On'
              onClick={() => {
                if (SortType === 'stars') {
                  setSortType('name');  
                } else if (SortType === 'name') {
                  setSortType('chrono'); 
                } else {
                  setSortType('stars');
                }
              }}
              style={{ borderRadius: '1vh 3vh 0vh 0vh' }}>
              {SortType === 'score' && (
                <>
                  <i className="fa-solid fa-percent"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'stars' && (
                <>
                  <i className="fa-regular fa-star"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'name' && (
                <>
                  <i className='fa-solid fa-arrow-down-a-z'></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
              {SortType === 'chrono' && (
                <>
                  <i className="fa-regular fa-clock"></i> &nbsp; <i className='fa-solid fa-arrow-down-short-wide'></i>
                </>
              )}
            </button>
          </div>

          {/* Menu botones */}
          <div style={{ position:'sticky', 
              border:'0.3vh solid var(--main)',
              borderRadius:'0vh 1vh 0vh 0vh',
              backgroundColor:'var(--light)',
              top: 0, zIndex: 4, 
              padding:'1vh'}} >
            <button
              onClick={()=> {SaveForm();
                            setShowPopup(true)}}>
              <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; Enviar Formularios de Documentos
            </button>
          </div>

          {/* Filtro de candidatos */}
          <input
            style={{  position:'sticky', 
                      width:'-webkit-fill-available',
                      height:'2vh',
                      border:'0.3vh solid var(--main)',
                      borderTop:'none',
                      borderRadius:'0vh',
                      backgroundColor:'var(--light)',
                      top: 0, 
                      zIndex: 4, 
                      padding:'1vh'}}
            type='text'
            placeholder='Filtrar por nombre'
            className='filterinput'
            value={FilterQuery}
            onChange={HandleFilterChange}></input>

          {/* Lista de candidatos */}
          <div  className='notas'
                style={{justifyContent:'flex-start', borderTop:'none', borderRadius:'0vh 0vh 1vh 1vh', marginBottom:'14.5vh'}}>
          {CVlist && CVlist.length > 0 ? (
            SortCVlist()
              .filter(reply => SelectList3.includes(reply.name))
              .filter(reply => reply.name.toLowerCase().includes(FilterQuery))
              .map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', flexDirection:'column', marginBottom: '1vh' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5vh'}}>
                  <button
                    className={ reply.name ===  SelectedCVId  ? 'start-button highlighted' 
                                                              : reply.Warning === true  ? 'start-button yellow' 
                                                                                        : 'start-button'}
                  onClick={() => {
                    setWait(true);
                    setSelectedCVId(reply.name);
                    FetchDoc(reply.name);
                    setTimeout(() => {
                      setWait(false);
                    }, 500);
                  }}>
                    {reply.name}
                  </button>

                  <button className={ !reply.stars3 ? 'middle-button' : 
                                                    JSON.parse(reply.stars3) < 3 ? 'middle-button red' : 
                                                                                  JSON.parse(reply.stars3) < 5 ? 'middle-button yellow' : 
                                                                                                                'middle-button green'}
                          style={{maxWidth:'3vw'}}>
                    {reply.stars3 ? JSON.parse(reply.stars3) : ' - '}{'☆'}
                  </button>
                  <button 
                          key={index} 
                          style={{borderRadius:'0vh', width:'20%', height:'7vh', justifyContent:'flex-end'}}
                          onClick={() => { 
                            SaveForm() ;
                            window.open(`${baseUrl}/?NC=${encodeURIComponent(reply.name)}&Form=${Form}&ID=${hashStringToInt(sessionStorage.process)}`);
                          }}>
                          <i className="fa-solid fa-file-signature"></i>
                  </button>
                  <button 
                    onClick={() => {
                      SaveForm() ;
                      setSelectedName(reply.name);
                      setSelectedMail(reply.mail);
                      setShowPopup2(true);}}
                    style={{width: '20%'}}
                    className='end-button'>
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                  </button>
                </div>
                <div style={{ display:'flex', justifyContent: 'flex-end'}}>
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    id={`fileInput-${reply.name}`}
                    onChange={(e) => handleFileChange(reply.name, e)}
                  />
                  <button style={{width:'85%'}}
                          onClick={() => document.getElementById(`fileInput-${reply.name}`).click()}> 
                    <i className={ Cartalist && Cartalist.some(item => quitarAcentos(String(item)).includes(quitarAcentos(reply.name))) 
                    ? "fa-solid fa-file-signature"
                    : "fa fa-upload"}
                    ></i> &nbsp; 

                    { Cartalist && Cartalist.some(item => quitarAcentos(String(item)).includes(quitarAcentos(reply.name))) 
                    ? 'CO_'+reply.name 
                    : 'Subir carta de oferta '}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <button>No hay candidatos</button>
          )}
          </div>

        </div>

        {/* Pantalla de documentos */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit'>

          {/* Nombre */}
          <div style={{display:'flex'}}>
            <h3 style={{display:'flex', alignItems: 'center'}} 
                className='boxtitle'>{SelectedCVId ? SelectedCVId+' | ' : ''} 
                Documentos a solicitar
            </h3>   
          </div>

          {/* Menu Superior */}
          <div  className= { !Wait  ? 'notas' : 'hidden'} 
                      style={{ display:'flex', 
                                height: '4vh',
                                gap: '1vh',
                                flexDirection:'row',
                                borderRadius:'0vh 1vh 0vh 0vh',
                                width:'-webkit-fill-available', 
                                overflow:'hidden'}}>
                <button onClick={() => setSelectedCVId('')} 
                        style={{width:'30%'}}
                        className= { !Wait && SelectedCVId ? '' : 'hidden'}> Volver </button>
                <button onClick={() => setForm([true, true, true, true, true, true, true, true, true, true, true, Form[11] , true, Form[13] , true, Form[15]])} 
                        className= { !Wait && !SelectedCVId ? '' : 'hidden'}> Marcar Todo Todo </button>
                <button onClick={() => setForm([false, true, true, false, true, false, false, false, false, false, false, Form[11] , false, Form[13], false, Form[15]])} 
                        className= { !Wait && !SelectedCVId ? '' : 'hidden'}> Seleccion Predeterminada </button>
                <button onClick={() => setForm([false, false, false, false, false, false, false, false, false, false, false, Form[11] , false, Form[13], false, Form[15]])} 
                        className= { !Wait && !SelectedCVId ? '' : 'hidden'}> Desmarcar Todo </button>
          </div>

          {/* Pantalla de contenido */}
          <div  className='notas' 
                style={{  overflow: 'scroll', 
                          overflowX:'hidden', 
                          marginTop:'-4vh',
                          borderRadius:'0vh 0vh 1vh 1vh',
                          borderTop: Form?.length > 0  ? 'none' : '',
                          marginBottom: Form?.length > 0  ? '14.4vh' : '8vh'}}>
            <div>
              {Wait === true ? (
                <div>
                  <div className='box'>
                    <img
                      src={'/favicon.png'}
                      alt='Profile'
                      style={{
                        width: '150px',
                        height: '150px',
                        objectFit: 'cover',
                        position:'stick'
                      }}/>
                    <h3 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h3>
                    <div className='loader-container'>
                      <div  className='pulsing-dot'>
                      <div></div>
                      <div></div>
                      <div></div>
                      </div>
                    </div>
                  </div>
                </div>

              ) : SelectedCVId ? /* Visualizacion de documentos */ (
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <h2 style={{marginLeft:'3vh'}}>Documentos Disponibles {SelectedCVId}</h2>

                  <button 
                    onClick={() => documentoCOF && window.open(documentoCOF, '_blank')}
                    style={{  
                      display: 'flex', 
                      justifyContent: 'flex-start', 
                      margin: '2vh', 
                      padding: '2vh', 
                      cursor: documentoCOF ? 'pointer' : 'default',
                      backgroundColor: documentoCOF ? '' : 'var(--gray)',
                      color: documentoCOF ? '' : 'gray'
                    }}
                    disabled={!documentoCOF}>
                    <i className={documentoCOF ? "fa fa-upload" : ""}></i> &nbsp;
                    {documentoCOF ? `Abrir Carta Oferta ${SelectedCVId}` : `Carta Oferta Firmada ${SelectedCVId} (No disponible)`}
                  </button>

                  {URLlist.length > 0 ? (
                    Object.entries(docsMap).map(([typeCode, title]) => {

                        if ((typeCode === 'EX1' && !Form[9]) || 
                            (typeCode === 'EX2' && !Form[11]) || 
                            (typeCode === 'EX3' && !Form[13])) {
                            return null;
                        }

                        const fileUrl = URLlist.find(url => {
                            const fileName = url.split('/').pop();
                            const fileType = fileName.split('_')[2];
                            const FinalFile = fileType.split('.')[0];
                            console.log(FinalFile)
                            return FinalFile === typeCode;
                        });
                        
                        const isReceived = !!fileUrl; 
                        
                        return (
                            <button 
                                key={typeCode} 
                                onClick={() => isReceived && window.open(fileUrl, '_blank')}
                                style={{  
                                    display: 'flex', 
                                    justifyContent: 'flex-start', 
                                    margin: '2vh', 
                                    padding: '2vh', 
                                    cursor: isReceived ? 'pointer' : 'default',
                                    backgroundColor: isReceived ? '' : 'var(--gray)',
                                    color: isReceived ? '' : 'gray'
                                }}
                                disabled={!isReceived}>
                                <i className={ isReceived ? "fa fa-upload" : ""}></i> &nbsp; {isReceived ? `Abrir ${title}` : `${title} (No disponible)`}
                            </button>
                        );
                    }).filter(component => component !== null)
                  ) : (
                      <h3 style={{marginLeft:'3vh'}} >No hay ningun documentos disponibles para este candidato en este momento.</h3>
                  )}
                </div>

              ) : /* Seleccion de documentos */ (  
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>

                  <h3 style={{marginLeft:'2vh'}}> Indique que documentos desea solicitar a el/los Candidatos finalistas </h3>

                  <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', marginLeft:'2vh'}}>

                    <label className='alternative-label'>
                      <input
                        type='radio'
                        className='alternative-checkbox'
                        checked={Form[0]}
                        onClick={() => ChangeForm(0)}
                      />
                      CV Actualizado
                    </label> {/* Certificado de AFP */}

                    <label className='alternative-label'>
                      <input
                        type='radio'
                        className='alternative-checkbox'
                        checked={Form[1]}
                        onClick={() => ChangeForm(1)}
                      />
                      Certificado de AFP
                    </label> {/* Certificado de AFP */}

                    <label className='alternative-label'>
                      <input
                        type='radio'
                        className='alternative-checkbox'
                        checked={Form[2]}
                        onClick={() => ChangeForm(2)}
                        // onChange={(e) => handleRadioChange(index, optIndex, e.target.checked)}
                      />
                      Certificado de Salud (Isapre o Fonasa)
                    </label> {/* Certificado de Salud (ISapre o Fonasa) */}

                    <label className='alternative-label'>
                      <input
                        type='radio'
                        className='alternative-checkbox'
                        checked={Form[3]}
                        onClick={() => ChangeForm(3)}
                        // onChange={(e) => handleRadioChange(index, optIndex, e.target.checked)}
                      />
                      Certificado de Nacimiento
                    </label> {/* Certificado de Nacimiento */}

                    <label className='alternative-label'>
                      <input
                        type='radio'
                        className='alternative-checkbox'
                        checked={Form[4]}
                        onClick={() => ChangeForm(4)}
                        // onChange={(e) => handleRadioChange(index, optIndex, e.target.checked)}
                      />
                      Copia Cédula de Identidad
                    </label> {/* Copia Cédula de Identidad */}

                    <label className='alternative-label'>
                      <input
                        type='radio'
                        className='alternative-checkbox'
                        checked={Form[5]}
                        onClick={() => ChangeForm(5)}
                        // onChange={(e) => handleRadioChange(index, optIndex, e.target.checked)}
                      />
                      Certificado de Título / Estudios
                    </label> {/* Certificado de Título / Estudios */}

                    <label className='alternative-label'>
                      <input
                        type='radio'
                        className='alternative-checkbox'
                        checked={Form[6]}
                        onClick={() => ChangeForm(6)}
                        // onChange={(e) => handleRadioChange(index, optIndex, e.target.checked)}
                      />
                      Certificado de Cargas Laborales
                    </label> {/* Certificado de Cargas Laborales */}

                    <label className='alternative-label'>
                      <input
                        type='radio'
                        className='alternative-checkbox'
                        checked={Form[7]}
                        onClick={() => ChangeForm(7)}
                        // onChange={(e) => handleRadioChange(index, optIndex, e.target.checked)}
                      />
                      Certificado de Residencia / Comprobante de Residencia
                    </label> {/* Certificado de Residencia / Comprobante de Residencia */}

                    <label className='alternative-label'>
                      <input
                        type='radio'
                        className='alternative-checkbox'
                        checked={Form[8]}
                        onClick={() => ChangeForm(8)}
                        // onChange={(e) => handleRadioChange(index, optIndex, e.target.checked)}
                      />
                      Ultimo Finiquito Laboral ratificado
                    </label> {/* Ultimo Finiquito Laboral ratificado */}

                    <label className='alternative-label'>
                      <input
                        type='radio'
                        className='alternative-checkbox'
                        checked={Form[9]}
                        onClick={() => ChangeForm(9)}
                      />
                      Otro documento (Detallar)
                      <input
                        type='text'
                        value={Form[10]}
                        style={{ width: '30vw', marginLeft: '1vh' }}
                        className={Form[9] ? 'alternative-checkbox' : 'hidden'}
                        onChange={(e) => {
                          setForm(prevForm => {
                            const newForm = [...prevForm];
                            newForm[10] = e.target.value;
                            return newForm;
                          });
                        }}
                      />
                    </label> {/* Otro documento (Detallar) */}

                    <label className='alternative-label'>
                      <input
                        type='radio'
                        className='alternative-checkbox'
                        checked={Form[11]}
                        onClick={() => ChangeForm(11)}
                      />
                      Otro documento (Detallar)
                      <input
                        type='text'
                        value={Form[12]}
                        style={{ width: '30vw', marginLeft: '1vh' }}
                        className={Form[11] ? 'alternative-checkbox' : 'hidden'}
                        onChange={(e) => {
                          setForm(prevForm => {
                            const newForm = [...prevForm];
                            newForm[12] = e.target.value;
                            return newForm;
                          });
                        }}
                      />
                    </label> {/* Otro documento 2 (Detallar) */}

                    <label className='alternative-label'>
                      <input
                        type='radio'
                        className='alternative-checkbox'
                        checked={Form[13]}
                        onClick={() => ChangeForm(13)}
                      />
                      Otro documento (Detallar)
                      <input
                        type='text'
                        value={Form[14]}
                        style={{ width: '30vw', marginLeft: '1vh' }}
                        className={Form[13] ? 'alternative-checkbox' : 'hidden'}
                        onChange={(e) => {
                          setForm(prevForm => {
                            const newForm = [...prevForm];
                            newForm[14] = e.target.value;
                            return newForm;
                          });
                        }}
                      />
                    </label> {/* Otro documento (Detallar) */}

                  </div>
                  
                </div>
              )}
            </div>
          </div>

        </div>

      </div>
      
      <div className={ Wait? 'hidden' : 'bottom'}>
        <button onClick={goto11}>volver</button>
      </div>

    </div>
  );
}
