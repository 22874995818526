import React, { useState, useEffect, useRef } from 'react';
import AICALL from '../functions/AICALL';
import './Chatbox.css';

export default  function Chatbox({ closeChatbox }) {

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [FileData, setFileData] = useState('');
  const [FileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const MEMORY_LIMIT = 10;
  const chatboxBodyRef = useRef(null);
  const FileInputRef = useRef(null);

  const initialWelcomeMessage = [
    { sender: 'gpt', 
      text: ' ¡Hola! Soy Reneé, tu Copiloto virtual. Estoy aquí para ayudarte con cualquier duda general que tengas. 😊\n' +
            '¿En qué puedo asistirte hoy?'}
  ];

  const sendMessage = async () => {
    if (input.trim() === '') return;
  
    const userMessage = { sender: 'user', text: input };
    let updatedMessages = [...messages, userMessage];
  
    if (updatedMessages.length > MEMORY_LIMIT) {
      updatedMessages = updatedMessages.slice(-MEMORY_LIMIT);
    }
  
    setMessages(updatedMessages);
    sessionStorage.setItem('chatMessages', JSON.stringify(updatedMessages));
    sessionStorage.setItem('chatLoading', 'true');
    setInput('');
    setFileData('');
    setFileName('');
    setLoading(true);

    const memoryContext = updatedMessages
      .map(msg => `${msg.sender === 'user' ? 'User' : 'Reneé'}: ${msg.text}`)
      .join('\n');
  
    try {
      const data = await AICALL({
        apicall: '04',
        data: `La siguiente es una interacción entre un usuario y un asistente, considera el mensaje mensionado como "archivo adjunto" como si fuera un archivo. Por favor completa y responde adecuadamente considerando tu eres Reneé:\n${memoryContext}\n Usuario: ${input + FileData}\n Reneé:`
      });
  
      const gptMessage = { sender: 'gpt', text: data.reply };
      let finalMessages = [...updatedMessages, gptMessage];
  
      if (finalMessages.length > MEMORY_LIMIT) {
        finalMessages = finalMessages.slice(-MEMORY_LIMIT);
      }
  
      setMessages(finalMessages);
      sessionStorage.setItem('chatMessages', JSON.stringify(finalMessages));
      sessionStorage.setItem('chatLoading', 'false');
      setLoading(false);
  
    } catch (error) {
      console.error('Error during fetch:', error);
      sessionStorage.setItem('chatLoading', 'false');
      setLoading(false);
    }
  };
  
  const handlereset = () => {

    sessionStorage.removeItem('chatMessages');
    sessionStorage.removeItem('chatLoading');
    setMessages([]);
    setInput('');
    setMessages(initialWelcomeMessage);
    sessionStorage.setItem('chatMessages', JSON.stringify(initialWelcomeMessage));
    setLoading(false);
  };

  const HandleFile = () => {
    FileInputRef.current.click();
  };

  async function HandleFileChange(event) {

    setLoading(true);
  
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name); 
    }
  
    if (!file) {
      return;
    }
  
    const fileMimeType = file.type; 
    console.log('File received:', file);
  
    if (fileMimeType === 'application/pdf' || fileMimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ) {
      const data = new FormData();
      data.append('file', file);
      data.append('apicall', 'EX');
  
      try {
        const reply = await AICALL(data);
        setFileData(" Archivo adjunto: " + reply.reply)
      } catch (error) {
        console.error('Error during PDF processing:', error);
      }
      
  
    } else if (fileMimeType === 'text/plain') {
      const reader = new FileReader();
      reader.onload = function(e) {
        const content = e.target.result;
        document.getElementById('fileContent').textContent = content;
        setFileData(" Archivo adjunto: " + content)
      };
      
    } else {
      setFileData('');
      setFileName('');
      window.alert('Formato no soportado. Actualmente solo se aceptan los formatos:\n * .Pdf\n *.Docx\n *.Txt');
    }
  
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    const savedMessages = JSON.parse(sessionStorage.getItem('chatMessages')) || [];
    const savedLoading = sessionStorage.getItem('chatLoading') === 'true';
    
    if (savedMessages.length === 0) {
      setMessages(initialWelcomeMessage);
      sessionStorage.setItem('chatMessages', JSON.stringify(initialWelcomeMessage));
    } else {
      setMessages(savedMessages);
    }
    
    setLoading(savedLoading); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (chatboxBodyRef.current) {
      chatboxBodyRef.current.scrollTop = chatboxBodyRef.current.scrollHeight;
    }
  }, [messages]);

  const formatText = (text) => {
    return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  };

  return (
    <div className='BG'>
      <div className='outside' onClick={closeChatbox}></div>
      <div className='chatbox'>
        <div className='chatbox-header'>
          <h3>Consulta a Reneé <span role='img' aria-label='Chat'>💬</span></h3>
          <button onClick={closeChatbox}
                  className='redb'
                  style={{height:'4vh', width:'4vh'}}><i className="fa-solid fa-x"></i></button>
        </div>
        <div className='chatbox-body' ref={chatboxBodyRef}>
          {messages.map((msg, index) => (
            <div key={index} style={{display:'flex', justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start'}}>
              <img 
                src='/favicon2.png' 
                alt='favicon' 
                className={msg.sender === 'gpt' ? 'smalllogo'  : 'hidden' }
              />
              <div key={index} className={`message ${msg.sender}`}>
                <div dangerouslySetInnerHTML={{ __html: formatText(msg.text) }} />
              </div>
              <img 
                src='/user.png' 
                alt='favicon' 
                className={msg.sender === 'user' ? 'smalllogo'  : 'hidden' }
              />
            </div>
          ))}
        </div>
        <div style={{display:'flex', marginLeft:'1vh'}}>
          {FileData && (
            <div className='file'>
              <i className="fa-solid fa-paperclip"> </i> {FileName || 'Sin nombre'}
            </div>
          )}
          {loading && (
            <div className='loading'>
              <span>•</span><span>•</span><span>•</span>
            </div>
          )}
        </div>
        <div className='chatbox-footer'>
          <input
            className='cf-input'
            type='text'
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
          />
          <button 
            className='box-button'
            onClick={HandleFile}><i className="fa-solid fa-paperclip"></i>
          </button>
          <input 
              type='file' 
              className='hidden'
              ref={FileInputRef} 
              onChange={HandleFileChange} 
              multiple
            />
          <button 
            className='cf-button'
            onClick={sendMessage}>Enviar
          </button>
          <button 
            className='cf-button'
            onClick={handlereset}>Borrar Todo
          </button>
        </div>
      </div>
    </div>
  );
};

