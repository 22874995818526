
import './functions/css/index.css';
import './functions/css/App.css';

import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import Header from './components/header';
import HeaderAdmin from './components/headerAdmin.js';
import App from './App';

import Ideas from './functions/00_Ideas.js';
import Puser from './functions/00_NuevoPass.js';
import Nuser from './functions/00_NuevoUsuario.js';
import Luser from './functions/00_ListaUsuarios.js';
import NEuser from './functions/00_NuevoUsuarioEmp.js';

import Pprocess    from './functions/01_Main.js';
import Nopciones   from './functions/02_CrearProceso.js';
import TextEditor  from './functions/03_P_Notas.js';
import Eprocess    from './functions/04_P_Datos.js';
import Perfil      from './functions/05_P_Perfil.js';
import Public      from './functions/06_P_Oferta.js';
import Recuperar   from './functions/07_P_Candidatos.js';
import Formulario  from './functions/08_P_Formulario.js';
import Respuestas  from './functions/09_P_Respuestas.js';
import PsicoEv     from './functions/10_P_EvaluacionPL.js';
import Referencias from './functions/11_P_Referencias.js';
import Documentos  from './functions/12_P_Documentos.js';

import MS         from './functions/00_MS.js';
import ErrorBoundary from './functions/XX_Errorboundry.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (!sessionStorage.getItem('token')){
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Router>
          <Routes>
              <Route path='/'                   element={<App/>} />  
              <Route path='/MS'                 element={<MS/>} /> 
          </Routes>
        </Router>
      </ErrorBoundary>
    </React.StrictMode>
  )
}
else{
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Router>
        {sessionStorage.getItem('rol') === 'Admin' && (
          <HeaderAdmin/>
        )}
        {sessionStorage.getItem('rol') !== 'Admin' && (
          <Header/>
        )}
          <Routes>

            <Route path='/MS'             element={<MS/>} /> 
            <Route path='/Ideas'          element={<Ideas/>} /> 
            <Route path='/MiPerfil'       element={<Puser/>} /> 
            
            <Route path='/'               element={<Pprocess/>} />  
            <Route path='/CrearProceso'   element={<Nopciones/>} />
            <Route path='/P_Notas'        element={<TextEditor/>} />
            <Route path='/P_Datos'        element={<Eprocess/>} />
            <Route path='/P_Perfil'       element={<Perfil/>} />
            <Route path='/P_Oferta'       element={<Public/>} />
            <Route path='/P_Candidatos'   element={<Recuperar/>} />
            <Route path='/P_Formulario'   element={<Formulario/>} />
            <Route path='/P_Respuestas'   element={<Respuestas/>} />
            <Route path='/P_EvaluacionPL' element={<PsicoEv/>} />
            <Route path='/P_Referencias'  element={<Referencias/>} />
            <Route path='/P_Documentos'   element={<Documentos/>} />

            {sessionStorage.getItem('rol') === 'Admin' && (
              <>
                <Route path='/NuevoUser' element={<Nuser />} />
                <Route path='/ListaUser' element={<Luser />} />
              </>
            )}
            {sessionStorage.rol === 'AdminGroup' && (
              <>
                <Route path='/NuevoUser' element={<NEuser />} />
                <Route path='/ListaUser' element={<Luser />} />
              </>
            )}
            
          </Routes>
        </Router>
      </ErrorBoundary>
    </React.StrictMode>
  );
}

reportWebVitals();
